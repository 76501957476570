import React, { useEffect, useMemo, useState } from "react";
import GHsymbol from "../components/GHsymbol";
import Button from 'react-bootstrap/Button'
// import { FaBox } from "react-icons/fa6";
// import { MdOutlineHistory } from "react-icons/md";
// import { FaUser } from "react-icons/fa6";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useLoaderData, useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from '../utils/Axios';
import useWindowDimensions from "../utils/useWindowDimension";
import { Helmet } from "react-helmet";
import '../css/orders.css';
import LemMenu from "../components/LemMenu";
import AlertMessage from "../utils/AlertMessage";
import { Link } from "react-router-dom";


const array = ['shipped', 'delivered', 'not returnable'];

const OrderDetails = () => {
  // const [orders, setOrders] = useState([]);
  const { width } = useWindowDimensions();
  const params = useParams();
  const orders = useLoaderData();
  const navigate = useNavigate();
  const { basket, dispatch } = useOutletContext();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  // useEffect(() => {
  //   axios.get(`${params.id}/orders`)
  //   .then(res => setOrders(res.data))
  //   .catch(err => console.log(err)) 
  // },[])

  const handleCancelOrder = () => {
    axios.patch('orders/'+orders.id)
    .then(res => {
      setMessage(res.data.message);
      setSeverity(res.data.severity);
      setShowSnackbar(true);
    })
    .catch(err => {})
  }

  const handleCancelOrderItem = (product_id) => {
    axios.patch(`orders/item/cancel/${orders.id}/${product_id}`)
    .then(res => {
      setMessage(res.data.message);
      setSeverity(res.data.severity);
      setShowSnackbar(true);
    })
    .catch(err => {})
  }

  const handleClick = (order) => {
    navigate('/customer/review-product/'+order.title.replace(/ /g, '-'), 
    {state: {name: order.title, img: order.imageUrl, id: order.id}}
    )
  }


  const createReturnRequest = (order) => {
    navigate('/customer/return-request/'+order.title.replace(/ /g, '-'), 
      {state: {
        name: order.title, img: order.imageUrl, price: order.price, 
        quantity: order.quantity, orderId: order.orderId, id: order.id
      }}
    )
  } 

  const buyAgain = async(order) => {
    try{
      const response = await axios.get(`/products/${order.title.replace(/ /g, '-')}`)
      const data = await response.data;
      if (data){
        dispatch({
          type: 'ADD_TO_BASKET',
          item: {
            id: data._id,
            title: data.productName,
            imageUrl: data.imageUrl,
            discount: data.discount*1,
            quantity: 1,
            weight: data.itemsize,
            basePrice: data.regularPrice.$numberDecimal*1,
            price: data.regularPrice.$numberDecimal*1
          }
        });
        navigate('/cart');
      }
    }catch(err){
      console.log(err)
    }
  }

  // useEffect(() => {
  //   let foundItem = orders.orderItems.findIndex(product => basket.find(item => item.id === product.id))
  //   if (foundItem !== -1) {
  //     orders.orderItems[foundItem]['cart'] = true;
  //   }
  // }, [basket, orders])


  const viewCart = () => navigate('/cart');
  console.log(orders)

  

  return (
    <div>
      <AlertMessage message={message} setShowSnackbar={setShowSnackbar} showSnackbar={showSnackbar} severity={severity}/>
      <h1 className="fs-2">Order details</h1>
      <div className="border p-3 bg-white mb-3">
        <h6 className="fw-bold">Order No: {orders['id']}</h6>
        <p style={{fontSize: '14px', color: 'gray'}}>{orders.orderItems.length} items</p>
        <p style={{fontSize: '14px', color: 'gray'}}>Ordered on {orders['date']}</p>
        <p style={{fontSize: '14px', color: 'gray'}}>Total: <GHsymbol /> {parseFloat(orders.total_amount).toFixed(2)}</p>
        {(orders.paymentchanel === 'Cash on delivery' && !array.includes(orders.orderItems[0].status)) && <Button size="sm" variant={'outline-danger'} onClick={()=>handleCancelOrder()} >Cancel this order</Button>}
      </div>
      <div>
        <h4 className="fw-bold mb-3" style={{fontSize: '18px'}}>Products in your order</h4>
        {orders.orderItems.map((order, idx) => 
          <div key={idx} className="mb-2 bg-white border border-bottom-0">
            <div className="status-wrapper" >
              <>
                {order.status==='cancelled' &&<span className="cancelled">order cancelled</span>}
                {order.status==='received'&&<span className="received">confirmed</span>}
                {order.status==='shipped'&&<span className="shipped">shipped</span>}  
                {order.status==='processing'&&<span className="shipped">processing</span>}
                {order.status === 'delivered'&&<span className="delivered">delivered</span>}
                {orders.isReturnable === false &&<span className="not-returnable">not returnable</span>}
              </> 
            </div>
            <div key={order.id} className="border-bottom row m-0 p-0 pb-1" >
              <div className="col-4 col-sm-2 col-md-3 col-lg-2 p-1 order-img"><img src={order.imageUrl} className="order-img" alt="product" /></div>
              <div className="col-7 col-sm-10 col-md-6 col-lg-7 p-1">
                <h2 className="m-0 order-item-name">{order.title}</h2>
                <p className="m-0 text-secondary"><small>Order No: {order.orderId}</small></p>
                <p className="m-0">Qty: {order.quantity}</p>
                <span className="me-2"><GHsymbol /> {parseFloat(order.price).toFixed(2)}</span>
                {/* <span className="text-decoration-line-through text-secondary text-danger" style={{fontSize:'12px'}}><GHsymbol /> {order.discount}</span> */}
              </div>
              <LemMenu direction="vertical">
                {/* {order.status === 'delivered'&& <MenuItem>
                  {basket.findIndex(item => item.id === order.id) === -1 ?
                    <span style={{height: '20px'}} onClick={()=>buyAgain(order)} >Buy Again</span>:
                    <span style={{height: '20px'}} onClick={viewCart}>View Cart</span>
                  }
                </MenuItem>} */}
                {(order.status === 'delivered' && order.reviewed === false) &&  <MenuItem style={{height: '20px'}} onClick={()=>handleClick(order)}>Rate Product</MenuItem>}
                {(order.status === 'delivered'&& orders.isReturnable === true) && <MenuItem style={{height: '20px'}} onClick={()=>createReturnRequest(order)}>Request Return</MenuItem>}
                <Divider sx={{ marginTop: '8px', marginBottom: '8px'}}/>
                {((order.status === 'received' || order.status === 'processing') && orders.paymentchanel.includes('Cash on delivery')) && <MenuItem onClick={()=>handleCancelOrderItem(order.id)} style={{height: '20px'}}>Cancel Order</MenuItem>}
              </LemMenu>
              {width >= 768 && 
                <div className="col-md-3 d-flex flex-column">
                  {/* {order.status === 'delivered'&& <>
                    {order.cart === false ?
                      <Button size="sm" variant={'primary'} onClick={()=>buyAgain(order)} className="my-2">Buy Again</Button>:
                      <Button size="sm" className='cat_view__cat mb-2' onClick={viewCart}>View Cart</Button>
                    }
                  </>} */}
                  {(order.status === 'delivered' && order.reviewed === false) &&  <Button size="sm" variant={'info'} className="mb-2" onClick={()=>handleClick(order)}>Rate Product</Button>}
                  {(order.status === 'delivered'&& orders.isReturnable === true) && <Button size="sm" variant={'warning'} onClick={()=>createReturnRequest(order)}>Request Return</Button>}
                  <Divider sx={{ marginTop: '8px', marginBottom: '8px'}}/>
                  {((order.status === 'received' || order.status === 'processing')&&orders.paymentchanel === 'Cash on delivery') && <Button size="sm" variant={'outline-danger'} onClick={()=>handleCancelOrderItem(order.id)} >Cancel order item</Button>}
                </div>
              }
            </div>
            <p className="ms-2" style={{fontSize: '13px'}}>Return period has ended.<Link to={'/return-policy'}><small> Read our Return Policy</small></Link></p>
          </div>  
        )}
      </div>
    </div>
  )
}


export default OrderDetails;