import React, { useEffect, useState } from 'react'
import '../css/AllCategoriesComponent.css'
import { CategoryProductCard } from './CategoryProductsComponent'
// import CategoriesGroup from "../components/CategoriesGroup";
import Slider from '@mui/material/Slider';
import OffCavasFilter from '../unused_components/OffCanvasFilter'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import { useLocation, useOutletContext } from 'react-router-dom'
// import { useStateValue } from '../utils/StateProvider'
import Loader from '../components/Loader'
import axios from '../utils/Axios'
import { Helmet } from 'react-helmet'
import CustomBottomSheet from '../components/CustomBottomSheet'

function valuetext(value) {
  return `${value}°C`;
}


const AllCategories = () => {
  const location = useLocation()
  // const { searchCate } = location.state.category;
  const [products, setProducts] = useState({
    catitem: [],
    totalPages: 0,
    currentPage: 1,
    total: 0
  })
  const [filter, setFilter] = useState(false)
  const [subCategory, setSubCategory] = useState([])
  const [brands, setBrands] = useState([])
  const { basket, dispatch } = useOutletContext();
  const [subCategoryProducts, setSubCategoryProducts] = useState([])
  const [subCategoryCheckBox, setSubCategoryCheckBox] = useState()
  const [brandsCheckbox, setBrandsCheckbox] = useState()
  const [productBrands, setProductBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [filterProducts, setFilterProducts] = useState({})
  const [page, setPage] = useState(1)
  const [categoryLookup, setCategoryLookup] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState({category: '', subcategory: ''});
  const [price, setPrice] = useState([0, 1000]);
  const [prices, setPrices] = useState({low: '', high: ''});

  const handlePriceChanges = (event, newValue) => {
    setPrice(newValue);
    fetchCategory(price);
  };

  const onMinPrice = (e) => {
    let value = e.target.value*1;
    var numberRegex = /^\d+$/;
    if (numberRegex.test(value)){
      setPrices({...prices, low: value});
    }
  }

  const onMaxPrice = (e) => {
    let value = e.target.value*1;
    var numberRegex = /^\d+$/;
    if (numberRegex.test(value)){
      setPrices({...prices, high: value});
    }
  }

  const handleGo = () => {
    setPrice([prices.low, prices.high]);
    if (!Object.values(prices).includes('')){
      axios.get(`categories?category=${categoryTypes.category}&subcategory=${categoryTypes.subcategory}&page=${page}&limit=50&price=${[prices.low, prices.high]}`)
      .then((res) => { console.log(res.data)
          setProducts(res.data);
          setCategories(res.data.categories.map(category => category.name.replace(/&/g, '-')));
          setSubCategory(res.data.categories[0].subCategory.map(subcate => subcate.replace(/&/g, '-')));
      })
    .catch(err=> console.log('network error!'))
    }
  }

  var categoryOne = '';
  useEffect(() => {
    let isMounted = true
    axios.get(`categories?category=${categoryTypes.category}&subcategory=${categoryTypes.subcategory}&page=${page}&limit=50&price=${price}`)
      .then((res) => {
        if (isMounted) {
          setProducts(res.data);
          setCategories(res.data.categories.map(category => category.name.replace(/&/g, '-')));
          setSubCategory(res.data.categories[0].subCategory.map(subcate => subcate.replace(/&/g, '-')));
        }
      })
    .catch(err=> console.log('network error!'))
    return () => { isMounted = false }  
  }, [categoryTypes, page])

  const fetchAllCategories = () => {
    axios.get('categoriesLookups').then(res=>{
      setCategories(res.data);
    })
    .catch(err=>console.log(err))
  }

  const fetchCategory = (price) => {
    axios
    .get(`categories?category=${categoryTypes.category}&subcategory=${categoryTypes.subcategory}&page=${page}&limit=50&price=${price}`)
    .then((res) => {
      setProducts(res.data);
      setSubCategory(res.data.categories[0].subCategory);
    })
    .catch((err) => console.log(err))
  }

  const allProducts = () => {
    axios
      .get(`categories?page=${page}&limit=50`)
      .then((res) => {
        setProducts(res.data);
        setCategories(res.data.categories.map(category => category.name));
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    let isMounted = true
    axios
      .get('categoriesLookups')
      .then((res) => { 
        if (isMounted) {
          let data_ = res.data.map(cate => cate.name)
          setCategoryLookup(data_);    
        }
      })
      .catch((err) => console.log(err))
    return () => { isMounted = false }
  }, [])

  // useEffect(() => {
  //   let category = []
  //   let subCategory = []
  //   let productBrands = []
    
    // if (categories.length !== 1){
    //   category = category.concat(categoryLookup);
    //   products.catitem.forEach((product) => {
    //     category.push(product['category'])
    //     subCategory.push(product['subCategory'])
    //     productBrands.push(product['brandName'].trim())
    //   })
    //   // Remove duplicates
    //   const category_ = category.filter(
    //     (_category, index) => category.indexOf(_category) === index
    //   )
    //   const subCategory_ = subCategory.filter(
    //     (category, index) => subCategory.indexOf(category) === index
    //   )
    //   const productBrands_ = productBrands.filter(
    //     (brand, index) => productBrands.indexOf(brand) === index
    //   )
    //   setCategories(category_);
    //   setSubCategory(subCategory_)
    //   setBrands(productBrands_)

    //   setSubCategoryCheckBox(Array(subCategory_.length).fill(false))
    //   setBrandsCheckbox(Array(productBrands_.length).fill(false))
    // }
  //   if (categories.length === 1) {
  //     let subCategory = [];
  //     products.catitem.forEach((product) => {
  //       subCategory.push(product['subCategory'])
  //     });
  //     const subCategory_ = subCategory.filter(
  //       (category, index) => subCategory.indexOf(category) === index
  //     )
  //     if (subCategory_.length !== 1) {
  //       setSubCategory(subCategory_);
  //       setSubCategoryCheckBox(Array(subCategory_.length).fill(false));
  //     }
  //   }
  // }, [subCategory])

  const handleChange = (event, value) => {
    setPage(value)
  }

  const toggleFiltter = () => setFilter(!filter)
  const handleClose = () => setFilter(false)

  const handleCheckBox = (e, index) => {
    const newValues = [...subCategoryCheckBox] //copies sub category boxes array of false;
    newValues[index] = !newValues[index] //toggle the specific sub category checkbox

    let storedCateProd = []

    setSubCategoryCheckBox(newValues)
    products.catitem.forEach((product) => {
      if (
        newValues[index] === true &&
        product['subCategory'] === e.target.value
      ) {
        storedCateProd.push(product)
        setSubCategoryProducts([...subCategoryProducts, storedCateProd])
      } else if (
        newValues[index] === false &&
        product['subCategory'] === e.target.value
      ) {
        let subCategoryProducts_ = [...subCategoryProducts]
        subCategoryProducts_.forEach((product) => {
          // storedCateProd=[];
          let filter = product.filter(
            (item) => item['subCategory'] !== e.target.value
          )
          if (filter.length > 0) {
            storedCateProd = []
            storedCateProd.push(filter)
            setSubCategoryProducts(storedCateProd)
          } else {
            setSubCategoryProducts(storedCateProd)
          }
        })
      }
    })
  }
  const handleBrandsCheckBox = (brand, brandHeading, index) => {
    const newBrandValues = [...brandsCheckbox] //copies sub category boxes array of false;
    newBrandValues[index] = !newBrandValues[index] //toggle the specific sub category checkbox
    if (brandHeading.toLowerCase() === 'brands') {
      console.log('on brands: ', brand, brandHeading)
      // let storedBrandProd = [];
      setBrandsCheckbox(newBrandValues)
      products.forEach((product) => {
        if (product['brandName'] === brand) {
          setProductBrands([...productBrands, brand])
        }
      })
    }
  }
 
  const onClickCategory = (cate, sub) => {
    setCategories([cate]);
    setCategoryTypes({...categoryTypes, category: cate, subcategory: sub})
    // fetchCategory(cate);
  }

  // console.log(subCategory[0])
  return (
    <>
      <Helmet>
        <title>Caspian Store - All Products best for your needs</title>
        <meta
          name='title'
          content='Caspian Store - All Products best for your needs'
        />
        <meta
          name='description'
          content='Discover all the amazing and best products for your needs. We offer best and affordable product at a low prices.'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div className=' cat_con'>
        <div className='row m-0'>
          <div className=' col-md-2 pe-0 pe-sm-1 ps-0'>
            <div className='category_sidebar'>
              <div className='d-flex justify-content-between align-items-center bg-white mb-2 py-md-2 '>
                <p className='m-0 ps-2 fw-bold'>Refine your search</p>
                <button className='border-0 d-md-none' onClick={toggleFiltter}>
                  <FilterAltOutlinedIcon
                    style={{ height: '30px', width: '30px' }}
                  />
                </button>
              </div>
              <CustomBottomSheet show={filter} handleClose={handleClose}>
                <div className='row'>
                  <div className='col-6'>
                    <div className='sub_category_heading_con ps-md-2'>
                      <h5 className='fw-bold'>{'Categories'}</h5>
                    </div>
                      <form className='sub_cat_form'>
                        {categoryLookup.map((cate, idx) => (
                          <div key={idx} className='content_ d-flex'>
                            {/* <input type={'checkbox'} id={idx} onChange={(e=>handleCheckBox(e, idx))} checked={subCategoryCheckBox[idx]} value={cate}/> */}
                            {/* <label htmlFor={idx}>{cate}</label> */}
                            {/* {categories.length===1&&<span className="category_link" onClick={()=>fetchCategory('all')}><ArrowBackIosIcon fontSize="small"/></span>} */}
                            <p
                              className='category_link'
                              onClick={() => onClickCategory(cate)}
                            >
                              {cate}
                            </p>
                          </div>
                        ))}
                      </form>
                    </div>
                  <div className='col-6'>
                    <div className='sub_category_heading_con ps-md-2'>
                    <h5 className='fw-bold'>{'Sub Category'}</h5>
                  </div>
                  <form className='sub_cat_form'>
                    {/* {subCategory.map((cate, idx) => (
                      <div key={idx} className='content_ d-flex'>
                        <input
                          type={'checkbox'}
                          id={idx}
                          onChange={(e) => handleCheckBox(e, idx)}
                          checked={subCategoryCheckBox[idx]}
                          value={cate}
                        />
                        <label htmlFor={idx}>{cate}</label>
                      </div>
                    ))} */}
                  </form>
                  </div>
                </div>
              </CustomBottomSheet>
              <div className='d-none d-md-block'>
                <div className=''>
                  <div className='sub_category_heading_con ps-md-2'>
                    <h5 className='fw-bold'>{'Categories'}</h5>
                  </div>  
                  {categories.length > 0 ? (
                    <form className="sub_cat_form">
                        {categories.map((categ,idx)=>
                        <div key={idx} className={categories.length ===1 ? 'flex-column content_ d-flex align-items-start':"content_ d-flex"}>
                          <p className="category_link" onClick={()=>categories.length === 1 ? allProducts() : onClickCategory(categ, undefined)}>{categories.length===1&&<ArrowBackIosIcon fontSize="small"/>}{categ}</p>
                          {categories.length == 1 && <div className={categories.length===1 && 'content-overflow' + " sub_cat_form w-100"}>
                            {subCategory.map((cate,idx)=>
                              <div key={idx} className="content_ ms-2">
                                <p className="category_link" onClick={()=>onClickCategory(categ, cate)}>{cate}</p>
                              </div>
                              )}
                          </div>}
                        </div>
                        )}
                      </form>
                  ) : (
                    <Loader />
                  )}
                </div>
                <div className='w-100'>
                  <div className='sub_category_heading_con ps-md-2'>
                    <h5 className='fw-bold'>{'Price Ranges'}</h5>
                  </div>
                  <form className='sub_cat_form mx-2'>
                    <Slider
                      className='bg-white '
                      style={{borderRadius: '0'}}
                      getAriaLabel={() => 'Price Ranges'}
                      value={price}
                      step={100}
                      max={10000}
                      onChange={handlePriceChanges}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                    />
                    <div className='d-flex w-100'>
                      <div className='d-flex justify-content-between me-3' style={{width: '50%'}}>
                        <input type='text' className='w-100 me-3' placeholder='min' value={prices.low} onChange={onMinPrice}/>
                        <input type='text' className='w-100' placeholder='max' value={prices.high} onChange={onMaxPrice}/>
                      </div>
                      <div>
                        <button className='rounded border-info' type='button' onClick={handleGo}>Go</button>
                      </div>
                      {/* <div className='d-flex' style={{width: '45%'}}> */}
                        
                      {/* </div> */}
                    </div>
                  </form>
                </div>
                {/* // <SubCategoriesComponents key={products['filter']} heading={products['filter']}>
                  //   {products['data'].map((item, index)=><Content id={index} name={item} check={products['filter'].toLowerCase()==='brands'?brandsCheckbox[index]:subCategoryCheckBox[index]}  handleCheckBox={e=>handleCheckBox(item, products['filter'], index)}/>)}
                  // </SubCategoriesComponents>   */}

                {/* <SubCategoriesComponents heading={'Sub Categories'}> */}
                {/* {filterProducts['subCategory'].map((category, index)=><Content id={index} name={category} check={subCategoryCheckBox[index]}  handleCheckBox={e=>handleCheckBox(category, 'Sub Categories', index)}/>)} */}
                {/* </SubCategoriesComponents> */}
                {/* <SubCategoriesComponents heading={'Brands'}> */}
                {/* {filterProducts['brands'].map((brand, idx)=><Content id={idx} name={brand} check={brandsCheckbox}  />)} */}
                {/* <Content id={'sk'} name={'Lenovo'}/>
                  <Content id={'kjk'} name={'Adidas'}/>
                  <Content id={'sskjk'} name={'Nike'}/>
                  <Content id={'s7kjk'} name={'Nasco'}/> */}
                {/* </SubCategoriesComponents> */}
              </div>
            </div>
          </div>
          <div className='col-sm col-md-10 px-0 ps-md-3 m-0'>
            <div className='category_products_container'>
              <div className='d-flex justify-content-between align-items-center bg-white w-100 product_infos'>
                <h3 className='m-0'>
                  {categoryOne === '' ? 'All Products' : categoryOne}
                </h3>
                {/* <p className='m-0'>{products.total} products found</p> */}
              </div>
              {subCategoryProducts.length > 0 ? (
                <div className='row category_products'>
                  {subCategoryProducts.map((product) =>
                    product.map((item) => {
                      return (
                        <CategoryProductCard
                          key={item._id}
                          product={item}
                          dispatch={dispatch}
                          basket={basket}
                        />
                      )
                    })
                  )}
                  <div>
                    <Stack spacing={2}>
                      <Pagination count={10} shape='rounded' />
                    </Stack>
                  </div>
                </div>
              ) : (
                <div className='row m-0 category_products'>
                  {products.catitem.length > 0 ? (
                    <>
                      {products.catitem.map((product) => (
                        <CategoryProductCard
                          key={product._id}
                          product={product}
                          dispatch={dispatch}
                          basket={basket}
                        />
                      ))}
                    </>
                  ) : (
                    <Loader />
                  )}
                  <div className='d-flex justify-content-center my-3'>
                    <Stack spacing={2}>
                      <Pagination
                        count={products.totalPages}
                        page={products.currentPage}
                        onChange={handleChange}
                        shape='rounded'
                      />
                    </Stack>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllCategories
