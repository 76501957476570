import React, { useEffect, useState } from 'react';
import "../css/GroupComponent.css";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import axios from '../utils/Axios';
import "react-multi-carousel/lib/styles.css";
import Loader from './Loader';
import EventEmitter from '../utils/eventEmitter';
import Availability from './Availability';

const responsive = {
  superLarge: {
    breakpoint: { max: 6000, min: 3000 },
    items: 4,
    slidesToSlide: 4
  },
  desktops: {
    breakpoint: { max: 3000, min: 1025 },
    items: 4,
    slidesToSlide: 4
  },
  laptops: {
    breakpoint: { max: 1024, min: 769 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 2,
    slidesToSlide: 2
  }
}

function GroupComponent({categoryitem, title, fromHome}) {
  const [Products, setProducts] = useState([]);
  const [relatedSuggestion, setRelatedSuggestion] = useState('');

  // console.log('group: ', fromHome, categoryitem);

  useEffect(()=>{
    let isMounted = true;
    axios.get(`/api/category/${categoryitem}`).then((response) =>{
        const products = response.data;
        if (isMounted)setProducts(products);
      })
    return ()=>{ isMounted = false}
  },[])
  
  // useEffect(() => {
  //   if (fromHome!==undefined){
  //     EventEmitter.subscribe('findRelatedProducts', data => setRelatedSuggestion(data));
  //     axios.get(`relatedProducts?term=${relatedSuggestion}`)
  //     .then(res => setProducts(res.data))
  //     .catch(err => console.log(err))
  //   }
  // },[relatedSuggestion])

    return (
    <div className="group">
      <div className="group__title">
        <h2>{title}</h2> 
        {/* <Link to={`/category/${title}/${categoryitem}`}
          className="link">Show all</Link> */}
      </div>
      {Products.length > 0 ?
        <Carousel
          responsive={responsive}
          autoPlay={false}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          partialVisible={false}
          dotListClass="custom-dot-list-style">   
          {Products.map((prod) => {
            return(
              <div key={prod.productName} className="group__item">
                <Link to={{
                  pathname: `/product/${prod.productName.replace(/ /g, '-')}`
                }}>
                <img src={`${prod.imageUrl}`} alt={prod.productName}  className="cat__group__component text-lowercase" style={{fontSize: '14px'}}/>
                </Link>
                {!prod.available && <Availability />}
              </div>
          )})}
        </Carousel>:
        <Loader />
      }
    </div>
  )
}
export default GroupComponent
