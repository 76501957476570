import React, { useState, useEffect } from 'react'
import { useLocation, Link, useLoaderData } from 'react-router-dom'
import axios from '../utils/Axios'
import '../css/SearchProducts.css'
import MobileCategories from '../components/MobileCategories'
import GroupComponent from '../components/GroupComponent'
import { CategoryProductCard } from './CategoryProductsComponent'
import { useStateValue } from '../utils/StateProvider'
import { Helmet } from 'react-helmet'

function SearchProducts() {
  const [searchProducts, setSearchProducts] = useState([])
  const location = useLocation()
  const search = location.search
  const { dataObjs } = location.state
  // const dataObjs = useLoaderData();
  const [{ basket }, dispatch] = useStateValue()

  const searchParam = new URLSearchParams(search).get('searchvalue')

  //   useEffect(() => {
  //     async function SearchProducts(){
  //       await axios.get(`/searchproducts/${searchParam}`).then((response) =>{
  //         const products = response.data;
  //         setSearchProducts(products)
  //       });
  //     }
  //     SearchProducts();
  //   }, []);
  //  console.log(dataObjs)

  return (
    <>
    <Helmet>
    <title>Search Product: {searchParam}</title>
    <meta name="title" content="Searched Products" />
    <meta name="robots" content="noindex, follow" />
    </Helmet>
    <div className="search">
      <div className="search_banner rec_banner px-0 m-0"><img src= "/uploads/Banners/Shopwithus.jpg" /></div>
      <div className="row m-0 searchproducts category_products">
        {dataObjs.map(product=>
          <CategoryProductCard
            key={product._id}
            product={product}
            dispatch={dispatch}
            basket={basket}
          />
        )}
      </div>
      {dataObjs.length !== 0 &&
        <GroupComponent categoryitem={dataObjs[0].category} title={`Some related ${searchParam} products`}/>
      }
    </div>
  </>
  )
}

export default SearchProducts
