import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import axios from '../utils/Axios';
import "./PostProductComponenet.css";

 function PostProductComponenet() {
    const [productName, setProductName] = useState('');
    const [regularPrice, setRegularPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [brandName, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [discription, setDiscription] = useState('');
    const [fileName, setFilename] = useState("");
  
    const onChangeFile = e => {
      setFilename(e.target.files[0]);
    }

    const sendForm = async (e) => {
      e.preventDefault();
  
      try {
        const formData = new FormData();
  
        formData.append("productName", productName)
        formData.append("regularPrice", regularPrice)
        formData.append("salePrice", salePrice)
        formData.append("category", category)
        formData.append("subCategory", subCategory)
        formData.append("brandName", brandName)
        formData.append("profileImg", fileName)
        
      // await axios.post("/product/new", formData);
      console.log(fileName);
  
      } catch (err) {
        console.error(err);
      }
      
     
    };
    
  return (
    <div className="wrapper">
         <section className="form signup">
          <form onSubmit={sendForm} encType="multipart/form-data" className='row'>
           <div className="name-details col-6">
             <div className="field input">
                <label>product name</label>
                <input name="productName" value={productName} onChange={(e) => setProductName(e.target.value)} type="text"/>
             </div>
             <div className="field input">
               <label>regular price</label>
               <input name="regularPrice" value={regularPrice} onChange={(e) => setRegularPrice(e.target.value)} type="text"/>
             </div>
             <div className="field input">
               <label>category</label>
               <input name="category" value={category} onChange={(e) => setCategory(e.target.value)} type="text" />
             </div>
           </div>
           <div className="col-6">
             <div className="field input">
               <label>sub category</label>
               <input name="subCategory" value={subCategory} onChange={(e) => setSubCategory(e.target.value)} type="text" />
             </div>
            <div className="field input">
              <label>sale price address</label>
              <input name="salePrice" value={salePrice} onChange={(e) => setSalePrice(e.target.value)} type="text"/>
            </div>
            <div className="field input">
              <label>brand name</label>
              <input name="brandName" value={brandName} onChange={(e) => setBrand(e.target.value)} type="text"/>
            </div>
            </div>
            <div className="field input">
              <label>Product discription</label>
              <textarea name="discription" value={discription} onChange={(e) => setBrand(e.target.value)} type="text"/>
            </div>
            <div className="hello">
              <input className='filefield' type="file" filename="profileImg" onChange={onChangeFile} />
            </div>
            <button className='smtbtn m-3' type="submit">Signup</button>
          </form>
        </section>
        </div>
  )
}
export default PostProductComponenet