import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Select, InputLabel, MenuItem, FormControl, OutlinedInput} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../scenes/graphs/Header";
import axios from '../../../utils/Axios';

const AddImage = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [imagetype, setImagetype] = useState("");
  const [fileName, setFilename] = useState(null);
  
  const [message, setMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const handleFormSubmit = async (values) => {
    try {
      const formData = new FormData();

      formData.append("type", imagetype);
      formData.append("bannerads", fileName);
      await axios.post('/uploadimage/new', formData).then(res=>{
        setMessage(res.data);
        setShowSnackbar(true);
      })
    setImagetype("");
    setFilename(null);
    
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeBannerFile = e => {
    setFilename(e.target.files[0]);
  }
  const checkoutSchema = yup.object().shape({
    type: yup.string(),
  });
  const initialValues = {
    type: "",
    fileName: fileName
  };
  

  return (
    <Box m="20px">
      <Header title="Add Banner or Ads" subtitle="Add Banner or Ads" />

      <Formik className="container d-flex justify-content-center" textAlign={"center"}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box 
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                maxWidth: "800px",
              }}
            >
               <TextField
               fullWidth
               select
               variant="filled"
               type="text"
               label="image type"
               onChange={(e) => setImagetype(e.target.value)}
               value={imagetype}
               name="imagetype"
               sx={{ gridColumn: "span 4" }}
              >
            <MenuItem key="Ads" value="Ads">
              Ads
            </MenuItem>
            <MenuItem key="Banner" value="Banner">
              Banner
            </MenuItem>
        
        </TextField>
            <div className="hello">
              <input className='filefield' type="file" filename="bannerads" onChange={onChangeBannerFile} />
            </div>
            </Box>
            <Box display="flex" justifyContent="center" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Add Image
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};


export default AddImage;