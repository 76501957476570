import React, { useState, useEffect } from 'react'
import '../css/CategoryComponent.css'
import Product from './Product'
import axios from '../utils/Axios'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Loader from './Loader'
import useWindowDimensions from '../utils/useWindowDimension'

const responsive = {
  superLarge: {
    breakpoint: { max: 6000, min: 2000 },
    items: 5,
    slidesToSlide: 4
  },
  desktops: {
    breakpoint: { max: 2000, min: 1024 },
    items: 5,
    slidesToSlide: 4
  },
  laptops: {
    breakpoint: { max: 1024, min: 769 },
    items: 4,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 3,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 3,
    slidesToSlide: 2
  }
}

function CategoryComponent({
  playSpeed,
  queryone,
  querytwo,
  querythree,
  queryfour,
  title,
  clsname,
  loadFromCookie
}) {
  const { width } = useWindowDimensions()
  const [prodChain, setProdChain] = useState([])

  useEffect(() => {
    let isMounted = true

    if (loadFromCookie) {
      axios
        .get('products/view/frequently')
        .then((res) => setProdChain(res.data))
        .catch((err) => console.log(err))
    } else {
      axios
        .get(
          `/chainedproducts/${queryone}/${querytwo}/${querythree}/${queryfour}`
        )
        .then((response) => {
          const chainData = response.data
          if (chainData) {
            if (isMounted) setProdChain(chainData)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [queryone, querytwo, querythree, queryfour])

  // console.log('chained: ', prodChain)

  return (
    <div className='category_component mb-3'>
      <div className={`info ${clsname}`}>
        <h2 className='title'>{title}</h2>
        {/* <div className="show_link">show all</div> */}
      </div>
      <div className='c_home__row h-100'>
        {prodChain.length > 0 ? (
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={playSpeed}
            // transitionDuration={10000}
            customTransition={`transform 1500ms ease-in-out`}
            removeArrowOnDeviceType={['tablet', 'mobile']}
            swipeable={true}
            draggable={true}
            pauseOnHover={true}
            // showDots={width<=768?true:false}
            infinite={true}
            partialVisible={false}
            dotListClass='custom-dot-list-style'
          >
            {prodChain.map((prodchn) => {
              return (
                <div className='cproduct_item h-100' key={prodchn._id}>
                  <Product
                    id={prodchn._id}
                    title={
                      prodchn.productName.charAt(0) +
                      prodchn.productName.substring(1).toLowerCase()
                    }
                    price={prodchn.regularPrice}
                    itemweight={prodchn.itemsize}
                    discount={prodchn.discount}
                    image={`${prodchn.imageUrl}`}
                    available={prodchn.available}
                  />
                </div>
              )
            })}
          </Carousel>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}

export default CategoryComponent
