import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import './PopUp.css';


function PopUp(props) {
  return (
    <div className='popup'>
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
          <Modal.Body>
            <div className='d-flex flex-column align-items-center mb-3'>
              <span className='infor mb-3 border border-info text-info'>?</span>
              <p className='fw-bold fs-3 mb-0'>Continue order?</p>
              {/* <span className='mb-3 text-mute'>Note: You can view your orders if you sign in now...</span> */}
              {/* <h4>Do you want a discount?</h4> */}
            </div>
            <div className='d-flex justify-content-center'>
              <button type='button' className='text-white bg-danger rounded-2 p-2 border-0 me-2' onClick={props.onHide}>Cancel</button>
              <button type='button' className='text-white bg-info rounded-2 p-2 border-0 ms-2' onClick={props.order} disabled={props.processingOrder ? true : false}>{props.processingOrder ?<Spinner animation="border" variant="warning" size='sm' /> : 'Order now'}</button>
              {/* <button type='button' className='text-white bg-success rounded-2 p-2 border-0 ms-2' onClick={props.login}>Sign In</button> */}
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );
}

export default PopUp;