import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from '../utils/Axios'

const MobileCategoriesChild = ({ title, image, searchParams, alt }) => {
  const navigate = useNavigate()

  const getRelatedProducts = async () => {
    const res = await axios.get(`search-suggestions?term=${searchParams}`)
    const dataObjs = res.data
    navigate(
      {
        pathname: '/searchedproduct',
        search: `searchvalue=${searchParams}` // query string
      },
      { state: { dataObjs } }
    )
  }

  return (
    <div className='catgr__item'>
      <div className='link__image '>
        <div onClick={getRelatedProducts} role='link'>
          <img src={image} alt={alt} className='catt__img ' />
        </div>
      </div>
      <div className='title__cat '>{title}</div>
    </div>
  )
}

export default MobileCategoriesChild
