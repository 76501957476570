import React from 'react'
import Carousel from 'react-multi-carousel'

const CarouselBannerComponent = ({ responsive, children }) => {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      swipeable={true}
      draggable={true}
      autoPlaySpeed={10000}
      customTransition='transform 1500ms ease-in-out'
      showDots={false}
      infinite={true}
      partialVisible={false}
      className='banners-carousel'
      dotListClass='custom-dot-list-style'
    >
      {children}
    </Carousel>
  )
}

export default CarouselBannerComponent

export const CarouselBannerChild = ({ image, alt }) => {
  return (
    <div className='home_banner'>
      <img className='home__image' src={image} alt={alt} />
    </div>
  )
}
