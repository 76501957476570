import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";
// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../utils/theme";
import "../css/AdminRoute.css";
import Topbar from "../scenes/global/Topbar";
import Sidebar from "../scenes/global/Sidebar";
import AuthContext, { useAuthContext } from "../utils/AuthLoggedIn";



const AdminLayout = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { details, loginDetails } = useAuthContext();

  useEffect(() => {
    loginDetails();
  },[])
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="admin__app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} loginDetails={loginDetails} />
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
};


export default AdminLayout;