import React, { useContext, useEffect, useState } from 'react'
import '../css/CartPopUp.css'
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { FaBox, FaUser } from 'react-icons/fa';
import { MdOutlineHistory } from 'react-icons/md';


const AccountPopUp = ({ logout, details }) => {
  const [popUp, setPopUp] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => setPopUp(false);

  const onMouseOut = () => {
    const accountPopUp = document.querySelector('.account__pop')
    accountPopUp.style.visibility = 'hidden'
  }

  const onMouseOver = () => {
    const accountPopUp = document.querySelector('.account__pop')
    accountPopUp.style.visibility = 'visible'
  }

  return (
    <>
      <div className='cart__pop__container' onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
        <div className='account__pop'>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Nav.Item href="/home" className="p-2 my-1 cs-hover">
              <FaBox size={'1.3rem'}className="me-2"/>
              <Nav.Link className="d-inline-block p-0 cs-hover" eventKey={'link-1'} as={Link} to={'/customer/orders/'+details.user}>Orders</Nav.Link>
            </Nav.Item>
            <Nav.Item href="/home" className="p-2 my-1 cs-hover">
              <MdOutlineHistory size={'1.3rem'}className="me-2"/>
              <Nav.Link className="d-inline-block p-0 cs-hover" eventKey={'link-2'} as={Link} to={'/customer/recently-viewed'}>Recently viewed</Nav.Link>
            </Nav.Item>
            <Nav.Item href="/home" className="p-2 my-1 cs-hover">
              <FaUser size={'1.3rem'} className="me-2"/>
              <Nav.Link className="d-inline-block p-0 cs-hover" eventKey={'link-3'} as={Link} to={'/customer/account'}>Account</Nav.Link>
            </Nav.Item>
            <Divider />
              <Nav.Item href="/home" className="text-center py-2">
                <Button className="w-75" variant="outline-danger" onClick={logout}>Logout</Button>
              </Nav.Item>
          </Nav>
        </div>
      </div>
    </>
  )
}

export default AccountPopUp
