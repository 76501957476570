import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem, TextField} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from './Axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from '@mui/icons-material/Check';
import { Link } from "react-router-dom";

function Address({onChange, title, setIsPayment, setIsAddressCompleted, isOrderConfirmed, setIsOrderConfirmed, setPaymentConfirmed}) {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [isAddressComplete, setIsAddressComplete] = useState(false);
    const [formData, setFormData] = useState({});
    const [checkClassName, setCheckClassName] = useState("check");
    const [address, setAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [addresscity, setAddresscity] = useState("");
    const [region, setRegion] = useState("");
    const [addressLocation, setAddressLocation] = useState([]);
    const [email, setEmail] = useState('');
    const [locLookups, setLocLookups] = useState([])
    const [delivery, setDelivery] = useState(0);
    const [lookupsObj, setLookupsObj] = useState({});
    const [formErrors, setFormErrors] = useState({firstName:false, lastName:false, email:false, address:false, addressLocation:false, addresscity:false, region:false, phone:false});
    // const [billingData, setBillingData] = useState({firstName:'', lastName:'', email:'', address:'', addressLocation:'', addresscity:'', region:''});
    const theme = useTheme();
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [addressLocationError, setAddressLocationError] = useState(false);
    const [addressCityError, setAddressCityError] = useState(false);
    const [regionError, setRegionError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [allRequired, setAllRequired] = useState(true);
    
  // useEffect(() => {
  //     onChange(formData)    
  // }, [formData, onChange]);

    useEffect(() => {
      async function getLocationLookups(){
         await axios.get('/getlocationlookups').then((response) =>{
            const locationLookups = response.data;
            setAddressLocation(locationLookups)
          });
      }
      getLocationLookups();
    }, []);

    useEffect(() => {
      async function getTown(){
        for (let i = 0; i < addressLocation.length; i++) {
          if (addressLocation[i].region === region) {
            for(var lockey in addressLocation[i].townLookUps){
              locLookups.push(lockey)
            }
            setLookupsObj(addressLocation[i].townLookUps)
          }
        }
      }
      getTown();
    }, [region]);

    useEffect(() => {
        setDelivery(lookupsObj[city]);
    }, [lookupsObj, city])


    const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().required('required'),
    address: yup.string().required("required"),
    phone: yup.string().required("required"),
    city: yup.string().required("required"),
    addressDiscription: yup.string().required("required"),
    region: yup.string().required("required"),
  });


  const initialValues = {
    firstName: firstName,
    lastName:lastName,
    email: email,
    address:address,  
    phone:phone,
    city: city,
    addressDiscription: addresscity,
    region: region
  };


  const changeAddress = async () => {
    try {
      setPaymentConfirmed(false);
      setIsAddressComplete(false);
      setIsOrderConfirmed(false);
      setIsPayment(false);
      const addresData = {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      address: undefined,
      phone: undefined,
      city: undefined,
      addresscity: addresscity,
      region: region,
      addressdelveryfee: delivery,
    };
    // if (title.lowerCase()==='shipping address'){
      setFormData(addresData);
    // }else if (title.lowerCase()==='billing address'){
    //   setBillingData(addresData)
    // }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    if (firstName===''){setFirstNameError(true)}else{setFirstNameError(false)};
    if (lastName===''){setLastNameError(true)}else{setLastNameError(false)};
    if (email===''){setEmailError(true)}else{setEmailError(false)};
    if (address===''){setAddressError(true)}else{setAddressError(false)};
    if (phone===''){setPhoneError(true)}else{setPhoneError(false)};
    if (city===''){setAddressCityError(true)}else{setAddressCityError(false)};
    if (addresscity===''){setAddressLocationError(true)}else{setAddressLocationError(false)};
    if (region===''){setRegionError(true)}else{setRegionError(false)};
    // if (delivery===''){setDeliveryError(true)}else{setDeliveryError(false)};
  }, [firstName, lastName, email, address, phone, city, addresscity, region])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (firstName!==''&&lastName!==''&&email!==''&&address!==''&&phone!==''&&city!==''&&addresscity!==''&&region!==''){
      const uniqueId = () => "CA"+ parseInt(Date.now() * Math.random()).toString() + lastName[0] + phone[2] + email[1].toUpperCase();
      let addresData = {
        orderId: uniqueId(),
        firstName: firstName,
        lastName: lastName,
        email: email,
        address: address,
        phone: phone,
        city: city,
        addresscity: addresscity,
        region: region,
        addressdelveryfee: delivery
      }


  
      setAllRequired(false);
      try {
        setIsAddressComplete(true)
        setIsAddressCompleted(true);
        setFormData(addresData);
        setCheckClassName("checkCompleted")
  
        await axios.post("/shipping/new", addresData);     
        
        
        } catch (err) {
          alert(err.response.data.errorMessage);
        }
    }else{
      setAllRequired(true);
      if (firstName===''){setFirstNameError(true)}else{setFirstNameError(false)};
      if (lastName===''){setLastNameError(true)}else{setLastNameError(false)};
      if (email===''){setEmailError(true)}else{setEmailError(false)};
      if (address===''){setAddressError(true)}else{setAddressError(false)};
      if (phone===''){setPhoneError(true)}else{setPhoneError(false)};
      if (city===''){setAddressCityError(true)}else{setAddressCityError(false)};
      if (addresscity===''){setAddressLocationError(true)}else{setAddressLocationError(false)};
      if (region===''){setRegionError(true)}else{setRegionError(false)};
    }
    
    
  };

  return (
    <>
    {!isAddressComplete ? (
    <Box className="shipping-form ">
     
        {/* <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => ( */}
          <form onSubmit={handleSubmit} noValidate >
            <div className="address__tittle">
              <div className="icon__text">
                <CheckIcon className={checkClassName}/>
                <div className="ps-2" style={{fontSize:'14px'}}>{title}</div>
              </div>
            </div>
            {allRequired&&<p className="m-0 ms-4 text-danger" style={{fontSize:'14px'}}>All fields are required *</p>}
            <hr className="hoz__rule" />
            <Box m="20px"
              display="grid"
              // component={'form'}
              gap="12px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                fontSize: '14px'
              }}
            >

              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  type="text"
                  label="First Name"
                  // onBlur={handleBlur}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  name="firstName"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
                  error={firstNameError}
                  // helperText={firstNameError&&'required'}
                  // color={!firstNameError?'success':'primary'}
                />
                {firstNameError&&<span style={{color:firstNameError&&'red'}}>required</span>}
              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  type="text"
                  label="Last Name"
                  // onBlur={handleBlur}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  name="lastName"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
                  error={lastNameError}
                  // helperText={lastNameError&&'required'}
                  // color={!lastNameError?'success':'primary'}
                />
                {lastNameError&&<span style={{color:lastNameError&&'red'}}>required</span>}
              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  type="text"
                  label="Phone Number"
                  // onBlur={handleBlur}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  name="phone"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
                  error={ phoneError}
                  // helperText={phoneError&&'required'}
                  // color={!phoneError?'success':'primary'}
                />
                  {lastNameError&&<span style={{color:lastNameError&&'red'}}>required</span>}
              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  type="text"
                  label="Email"
                  // onBlur={handleBlur}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
                  error={emailError}
                  // helperText={emailError&&'required'}
                  // color={!emailError?'success':'primary'}
                />
                {emailError&&<span style={{color:emailError&&'red'}}>required</span>}

              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  type="text"
                  label="Address"
                  // onBlur={handleBlur}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  name="address"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
                  error={addressError}
                  // helperText={addressError&&'required'}
                  // color={!addressError?'success':'primary'}
                />
                {addressError&&<span style={{color:addressError&&'red'}}>required</span>}
              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  type="text"
                  label="Address Discription"
                  // onBlur={handleBlur}
                  onChange={(e) => setAddresscity(e.target.value)}
                  value={addresscity}
                  name="addressDiscription"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
                  error={addressLocationError}
                  // helperText={addressLocationError&&'required'}
                  // color={!addressLocationError?'success':'primary'}
                />
                {addressLocationError&&<span style={{color:addressLocationError&&'red'}}>required</span>}
              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  select
                  variant="outlined"
                  type="text"
                  label="region"
                  // onBlur={handleBlur}
                  onChange={(e) => setRegion(e.target.value)}
                  value={region}
                  name="region"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360'}}
                  error={regionError}
                  // helperText={regionError&&'required'}
                  // color={!regionError?'success':'primary'}
                >
                  {addressLocation.map((loc) => (
                    <MenuItem key={loc.region} value={loc.region}>
                      {loc.region}
                    </MenuItem>
                  ))}
                </TextField>
                {regionError&&<span style={{color:regionError&&'red'}}>required</span>}
              </Box>
              <Box sx={{gridColumn:'span 2'}}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  select
                  variant="outlined"
                  type="text"
                  label="city"
                  // onBlur={handleBlur}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  name="city"
                  sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360', fontSize:'14px'}}
                  error={addressCityError}
                  // helperText={addressCityError&&'required'}
                  // color={!addressCityError?'success':'primary'}
                >
                  {locLookups.map((lookups) => (
                    <MenuItem key={lookups} value={lookups}>
                      {lookups}
                    </MenuItem>
                  ))}
                </TextField>
                {addressCityError&&<span style={{color:addressCityError&&'red'}}>required</span>}
              </Box>
            </Box>
            <div className="d-flex justify-content-end pe-3 mb-3">
              <Button type="submit" color="secondary" variant="contained" className="h-100 rounded-pill">
                Done
              </Button>
              </div>
          </form>
        {/* )}
      </Formik>
      */}
    </Box>
    ):(
      <div className="address__compt">
        <div className="address__tittle">
          <div className="icon__text">
            <CheckIcon className={checkClassName}/>
              <div className="ps-2">{title.toLowerCase()==='shipping address'?'SHIPPING ADDRESS':'BILLING ADDRESS'}</div>
          </div>
          {!isOrderConfirmed&&<div>
            <button onClick={changeAddress} className="changebtn">Change</button>
          </div>}
          </div>
          <hr className="hoz__rule"/>
       <div className="cus__name">{firstName} {lastName}</div>
       <div className="cus__address">{address}, {addresscity} | {region} - {city} | {phone}</div>
    </div>
   )}
    </>
  )
}

export default Address