import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';
import { useStateValue } from '../utils/StateProvider';

export default function SelectMenu({id, price, basePrice, prod_quantity, setQty}) {
  const [{ basket }, dispatch] = useStateValue();
  const [quantity, setQuantity] = useState(prod_quantity);
  const [isQuantityTenPlus, setIsQuantityTenPlus] = useState(false)

  // console.log('type: ', typeof(setQty))
  useEffect(() => {
    if (prod_quantity>=10)setIsQuantityTenPlus(true);
    setQuantity(prod_quantity)
  }, [prod_quantity])
  
  const increase_quantity=()=>{
    // setQty(quantity);
    if (quantity > 0){
      dispatch({
        type: 'INCREASE_QUANTITY',
        item: {
            id: id,
            quantity:  quantity,
            price: quantity*basePrice
        }
      });
    } 
  }

  const decrease_quantity=(price)=>{
    dispatch({
      type: 'DECREASE_QUANTITY',
      item: {
          id: id,
          price:price,
          quantity: quantity
      }
    });
  }

  useEffect(()=>{
    increase_quantity();
  },[quantity]) 
  // useEffect(()=>{
  //   let foundItem = basket.findIndex(item=>item.id===id);
  //   console.log(basket[foundItem])
    
  // },[basket])
  
  const handleQuantity=e=>{
    let value = e.target.value*1;
    var numberRegex = /^\d+$/;
    if (quantity>=10 || value>=10){
      setIsQuantityTenPlus(true);
    }
    if (numberRegex.test(value)){
      if (value===0){
        setQuantity('');
        // console.log("val is 0", value)
      }else {
        setQuantity(value);
        // console.log("val is not 0", value)
      }
    }else {
      setQuantity(1);
      // console.log("else val", value)
    }
    // console.log('last: ', quantity)
  };
  

  // const increaseQuantity=()=>setQuantity(quantity+1);

  // const decreaseQuantity=()=>{
  //   if (quantity<=1){
  //     setQuantity(1);
  //     // setTimeout(()=>{
  //       decrease_quantity(basePrice, quantity);
  //     // },10);
  //   }else{
  //     setQuantity(quantity-1);
  //     // setTimeout(()=>{
  //       decrease_quantity(price/quantity);
  //     // },500);
  //   }
  // }
  return (
    <FormControl sx={{ marginTop:1, minWidth: 120 }} size="small">
      {!isQuantityTenPlus&&
        <>
          <InputLabel id="demo-select-small-label">Quantity</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            label='Quantity'
            value={quantity}
            onChange={handleQuantity}
            size='small'
            sx={{height:'40px'}}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10+</MenuItem>
          </Select>
        </>
      }
      {isQuantityTenPlus &&
        <>
          <TextField type='number' size='small' onChange={handleQuantity} value={quantity} sx={{width: '100px'}} />
          <small style={{fontSize:'13px', color:'gray'}}>Enter quantity</small>
        </>
      }
    </FormControl>
  );
}