import React, { useEffect, useState } from "react";

import payment_success from '../images/payment_success.png';
import '../css/PaymentSuccessComponent.css';
import { useNavigate, useLocation } from "react-router-dom";
import { useStateValue } from "../utils/StateProvider";
import GHsymbol from "../components/GHsymbol";
import { getBasketTotal } from "../utils/Reducer";
import axios from "../utils/Axios";
import PopUp from "../unused_components/PopUp";
import { Helmet } from "react-helmet";



const PaymentSuccessComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ basket }, dispatch] = useStateValue();
  const [show, setShow] = useState(true);
  const { data } = location.state || '';
  const { totalPrice } = location.state || '';


  if (basket.length <= 0) return window.location.href = '/';


  const goHome = () => {
    navigate('/' );
    dispatch({ type: 'CLEAR_USER_BASKET' })
  }

  const onHide = () => setShow(false);


  const login = () => navigate('/login');

  const homePage = () => navigate('/');
  const signup = () => navigate('/register');

  return (
    <>
    <Helmet>
    <title>Payment Success</title>
    <meta name="title" content="Payment Success" />
    <meta name="robots" content="noindex, nofollow" />
    </Helmet>
      {/* <PopUp login={login} homePage={homePage} onHide={onHide} signup={signup} show={show} /> */}
      <div className="paymt_suc_container flex-column">
        <div className="d-flex flex-column">
          <div className="align-self-center p-3">
            <div className='d-flex flex-column align-items-center mb-3 text-center'>
              <p className='fw-bold fs-3 mb-0'>Do you want to create an account?</p>
              {/* <span className='mb-0text-mute'>Note: You can view your orders if you sign in now...</span> */}
            </div>
            <div className='d-flex justify-content-center'>
              <button type='button' className='text-white bg-info rounded-2 p-2 border-0 ms-2' onClick={signup}>Sign Up</button>
              <button type='button' className='text-white bg-success rounded-2 p-2 border-0 ms-2' onClick={login}>Sign In</button>
            </div>
          </div>
        </div>
        <div className="paymt_suc bg-white">
          <div className="paymt_suc_top">
            <div className="mb-2"><img src={payment_success} alt="payment success icon" height={70} /></div>
            <h3>{data['firstName']} {data['lastName']}</h3>
            <h1><GHsymbol /> {totalPrice}</h1>
          </div>
          <div className="paymt_info_container border">
            <div className="d-flex flex-column align-items-center">
              <img className="logo" src="/uploads/caspian_logo.png" alt="Caspian logo" />
              <h2>Caspian Outlets</h2>
            </div>
            <div className="d-flex justify-content-between paymt_info">
              <p>Email</p>
              <span>{data['email']}</span>
            </div>
            <div className="d-flex justify-content-between paymt_info">
              <p>Address</p>
              <span>{data['addresscity']} {data['address']}</span>
            </div>
            <div className="d-flex justify-content-between paymt_info">
              <p>Phone Number</p>
              <span>{data['phone']}</span>
            </div>
          </div>
          <div className="paymt_suc_details">
            <h3 className="mt-3">Details</h3>
            <div className="d-none d-md-block">
              {basket.map(product =>
                <div key={product._id} className="d-flex justify-content-between">
                  <p className="details_info">{product['title']}</p>
                  <span style={{ fontWeight: 'normal', paddingRight: '8px' }}><GHsymbol /> {product['price']}</span>
                </div>
              )}
              <div className="d-flex justify-content-between mt-3">
                <p className="details_info">Total</p>
                <span className="pe-2"><GHsymbol /> {getBasketTotal(basket)}</span>
              </div>
            </div>
          </div>
          <div className="w-100 mt-sm-3 mt-md-0 suc_btn">
            <button type="button" className="w-100 py-2 border-1 rounded-pill bg-warning text-uppercase" onClick={goHome} >Ok</button>
          </div>
        </div>
      </div>
    </>
  )
};



export default PaymentSuccessComponent;





// export const PaymentSuccessChild=({ title, info })=>{
//   return (
//     <div className="d-flex justify-content-between">
//       <p>{title}</p>
//       <p>{ children }</p>
//     </div>
//   )
// };

{/* <p><span>GH</span>&#8373; </p> */ }