import React from 'react'
import "../css/ProductSqureComponent.css";
import { Link } from 'react-router-dom';


 function ProductSqureComponent({ children, title, searchGate }) {
    return (
        <div className='product_s mb-3 '>
            <div className='sq-title'><h5>{title}</h5></div>
            <div className='product_square'> { children  } </div>
            <div className='explore_link'><Link to={{pathname:'/category/'+searchGate}}>Explore more</Link></div>
        </div>
    )
}

export default ProductSqureComponent

