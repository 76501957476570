import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, TextField, Select, InputLabel, MenuItem, FormControl, OutlinedInput} from "@mui/material";
import { Formik } from "formik";
import Typography from '@mui/material/Typography';
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../scenes/graphs/Header";
import axios from '../../../utils/Axios';
import { useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from "react-router-dom";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import './form.css';
import { useRef } from "react";
// import mockLookups from "./mockLookups";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(cat, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(cat) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const steps = ['Product details', 'Prices', 'Specifications'];


const Form = () => {
  const theme = useTheme();
  const location = useLocation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [fileName, setFilename] = useState([]);
  const [CategoryLookups, setCategoryLookups] = useState([]);
  const [lookups, setLookups] = useState([]);
  const [departmentLookups, setDepartmentLookups] = useState([]);
  const [newCategoryLookups, setNewCategoriesLookups] = useState([]);  
  const [newSubCategoriesLookups, setNewSubCategoriesLookups] = useState([]);
  const [department, setDepartment] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newSubCategories, setNewSubCategories] = useState("");
  const [subCatgories, setSubCatgories] = useState("");
  const [curCategory, setCurCategoryLookups] = useState([]);
  const [Category, setCategory] = useState("");
  const [itemweight, setItemWeight] = useState([]);
  const {id} = location.state || '';
  // const id = "64ecd36ea6eecf6f94c22547";
  // const [categories]
  const [productName, setProductName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [salePrice, setSalePrice] = useState(""); 
  const [discription, setDiscription] = useState("");
  const [itemsize, setItemSize] = useState("");
  // const [imageUrl, setimageUrl] = useState("");
  const [prodToUpdate, setProdToUpdate] = useState({});
  const [specLists, setSpecLists] = useState([{ spec: 0, name: '', value: '', remove: false }]);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [images, setImages] = useState([]);
  const imageRef = useRef();
  let imageFiles = null;
  const [imageUrl, setImageUrl] = useState('');




  useEffect(() => {
    const found = lookups.find(data => data.department === department);
    if (found) {
      setNewCategoriesLookups(found.categories);
      return ;
    }
  },[department])


  useEffect(() => {
    const found = newCategoryLookups.find(data => data.name === newCategory);
    if (found) {
      setNewSubCategoriesLookups(found.subCategories);
      return ;
    }
  },[newCategory])


  useEffect(() => {
    async function getNewCategoryLookups(){
       await axios.get('/getcategoriesdata').then((response) =>{
          const catLookups = response.data;
          let dept_ = [];
          catLookups.forEach(dept => {
            dept_.push(dept.department);
          });
          setDepartmentLookups(dept_);
          setLookups(catLookups)
        });
    }
    getNewCategoryLookups();
  }, []);

  useEffect(() => {
    async function getCategoryLookups(){
      await axios.get('/categoryLookups').then((response) =>{
        const categoryLookups = response.data;
        setCategoryLookups(categoryLookups)
      });
    }
    getCategoryLookups();
  }, []);

  useEffect(() => {
    async function getItemWeight(){
      await axios.get('/getproductsizes').then((response) =>{
        const itemW = response.data;
        setItemWeight(itemW)
      });
    }
    getItemWeight();
  }, []);

      const updateProduct = async () => {
        try {
          const formData = new FormData();

          formData.append("productName", productName)
          formData.append("regularPrice", regularPrice) 
          formData.append("salePrice", salePrice)
          formData.append("itemsize", itemsize)
          formData.append("category", Category)
          formData.append("subCategory", subCatgories)
          formData.append("brandName", brandName);
          formData.append("discription", discription);
          formData.append("department", department);
          formData.append("newCategory", newCategory);
          formData.append("newSubCategory", newSubCategories);
          formData.append("imageUrl", imageUrl);
          formData.append("specList", specLists);

          for (let i = 0; i < fileName.length; i++) {
            formData.append('profileImg', fileName[i]);
          }
    
          await axios.put(`/api/update/products/${id}`, formData).then(res=>{
            setMessage(res.data);
            setShowSnackbar(true);
          })
          setTimeout(() => {
            
          }, 1500);
          navigate("/productlist")
  
        } catch (err) {
          console.error(err);
        }
      
      };

  const checkoutSchema = yup.object().shape({
    productName: yup.string().required("required"),
    regularPrice: yup.string().required("required"),
    salePrice: yup.string().required("required"),
    brandName: yup.string().required("required"),
    discription: yup.string().required("required"),
  });

  useEffect(() => {
    async function getProductById(){
        await axios.get(`/products/${id}`).then((response) =>{
          const chainData = response.data;
          setProdToUpdate(chainData);
          setBrandName(chainData.brandName)
          setProductName(chainData.productName)
          setRegularPrice(chainData.regularPrice)
          setSalePrice(chainData.salePrice)
          setItemSize(chainData.itemsize)
          setCategory(chainData.category)
          setSubCatgories(chainData.subCategory)
          setDiscription(chainData.discription)
          setImageUrl(chainData.imageUrl)
          // setFilename(chainData.fileName)
        });
    }
    if (id !== undefined) {
      getProductById();
    }
  }, [id]);
  console.log('chained: ', prodToUpdate  )

  const initialValues = {
    category:"",
    subcategory:"",
    productName:"",
    regularPrice:"",
    salePrice:"",
    itemsize: "",
    brandName:"",
    discription: "",
    fileName: fileName
  };


  const onChangeFile = e => {
    let files = [];
    const images = e.target.files;
    Object.entries(images).forEach(([key, image]) => {
      files.push(image);
      setFilename(prevState => [...prevState, image]) 
    });
  }

  const onChagedCategory = e => {
    setCategory(e.target.value);
  }

  useEffect(() => {
    async function getCurCategoryLookups(){
      for (let i = 0; i < CategoryLookups.length; i++) {
        if (CategoryLookups[i].name === Category) {
            setCurCategoryLookups(CategoryLookups[i].subCategory);
        }
      }
    }
    getCurCategoryLookups();
  }, [Category]);

  const handleSubcatChange = (event) => {
    const {
      target: { value },
    } = event;
    setSubCatgories(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleFormSubmit = async (values) => {
  
      try {
        const formData = new FormData();
  
        formData.append("productName", productName)
        formData.append("regularPrice", regularPrice) 
        formData.append("salePrice", salePrice)
        formData.append("itemsize", itemsize)
        formData.append("category", Category)
        formData.append("subCategory", subCatgories)
        formData.append("brandName", brandName);
        formData.append("discription", discription);
        formData.append("department", department);
        formData.append("newCategory", newCategory);
        formData.append("newSubCategory", newSubCategories);
        formData.append("specList", specLists);
        for (let i = 0; i < fileName.length; i++) {
          formData.append('profileImg', fileName[i]);
        }

        await axios.post('/product/multi/new', formData).then(res=>{
          setMessage(res.data);
          setShowSnackbar(true);
        })
       setFilename(null);
       setBrandName("");
       setCategory("");
       setDepartment("");
       setProductName("");
       setDiscription("")
       setItemSize("");

      } catch (err) {
        console.error(err);
      }
  };
  
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  function previewFile(file, callback) {
    const reader = new FileReader();
    
    reader.onloadend = () => {
      callback(reader.result);
    }
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    const images = [], fileReaders = [];
    let isCancel = false;
    if (fileName.length) {
      fileName.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result)
          }
          if (images.length === fileName.length && !isCancel) {
            setImages(images);
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [fileName]);

  const uploadFiles = (images) => {
    // const images = e.target.files;
    const uploader = document.getElementsByClassName('uploader')[0];
    
    Object.entries(images).map(([key, image_]) => {
      const upload = document.getElementById('upload');
      const fileName_ = document.createElement('p');
      const removeBtn = document.createElement('span');
      const fileListContainer = document.createElement('div');
      const image = document.createElement('img');
      removeBtn.addEventListener('click', () => removeImage(uploader, fileListContainer, image, key))
      fileName_.id = 'filename';
      fileListContainer.className = 'fileList';
      removeBtn.className = 'removeImage';
      fileName_.innerText =  image_.name;
      previewFile(image_, value => {
        image.src = value;
        image.alt = upload.value.split('\\').pop().split('.')[0];
      });
      fileListContainer.appendChild(image);
      fileListContainer.appendChild(fileName_);
      fileListContainer.appendChild(removeBtn);
      uploader.appendChild(fileListContainer);
    });
  }

  

  const removeImage = (idx) => {
    let fileName_ = [...fileName];
    let images_ = [...images];
    fileName_.splice(idx, 1);
    images_.splice(idx, 1);
    setFilename(fileName_);
    setImages(images_);
  }


  const handleSpecNameChange = (e, index) => {
    const specLists_ = [...specLists];
    specLists_[index]['spec'] = index;
    specLists_[index]['name'] = e.target.value;
    // specLists_[index]['remove'] = true;
    setSpecLists(specLists_);
  }

  const handleSpecValueChange = (e, index) => {
    const specLists_ = [...specLists];
    specLists_[index]['spec'] = index;
    specLists_[index]['value'] = e.target.value;
    // specLists_[index]['remove'] = true;
    setSpecLists(specLists_);
  }


  const addSpec = (index) => {
    specLists[index]['remove'] = true;
    setSpecLists(prevState=>[
      ...prevState,
      {
        spec: prevState.length,
        name: '',
        value: ''
      }
    ]);
  }
  const removeSpec = (spec_) => {
    let spec = specLists.filter(specObj=>specObj.spec!==spec_);
    spec.map((specObj, index)=>specObj['spec']=index);
    setSpecLists(spec);
  };

  // console.log('url: ', typeof(imageUrl))
  return (
    <>
      <Snackbar
      anchorOrigin={{vertical:'top', horizontal:'right'}}
      open={showSnackbar}
      autoHideDuration={6000}
      onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>{message}</Alert>
      </Snackbar>
    <Box m="20px">
      {id !== undefined ? (
              <Header title="Update Product" subtitle="update product" />
             ):(
              <Header title="Add Product" subtitle="Add a new product" />
             )}
      

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box display={'grid'} gap={'20px'} gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
                <Typography sx={{gridColumn: 'span 4'}}>Product details</Typography>
                
            <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Select New Department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            sx={{ gridColumn: "span 2" }}
          >
            {departmentLookups.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
         <TextField
          fullWidth
          id="outlined-select-currency"
          select
          label="Select New Category"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          sx={{ gridColumn: "span 2" }}
        >
          {newCategoryLookups.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id="outlined-select-currency"
          select
          label="Select New SubCategory"
          value={newSubCategories}
          onChange={(e) => setNewSubCategories(e.target.value)}
          sx={{ gridColumn: "span 2" }}
        >
          {newSubCategoriesLookups.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
                  fullWidth
                  select
                  variant="filled"
                  type="text"
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                  value={Category}
                  name="subcategory"
                  sx={{ gridColumn: "span 2" }}
                >
                  {CategoryLookups.map((cat) => (
                    <MenuItem key={cat.name} value={cat.name}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  select
                  variant="filled"
                  type="text"
                  label="Sub-Category"
                  onChange={(e) => setSubCatgories(e.target.value)}
                  value={subCatgories}
                  name="subcategory"
                  sx={{ gridColumn: "span 2" }}
                >
                  {curCategory.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Product Name"
                onChange={(e) => setProductName(e.target.value)}
                value={productName}
                name="productName"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                select
                variant="filled"
                type="text"
                label="Item Size"
                onChange={(e) => setItemSize(e.target.value)}
                value={itemsize}
                name="itemsize"
                sx={{ gridColumn: "span 2" }}
              >
                {itemweight.map((weight) => (
                  <MenuItem key={weight.itemsize} value={weight.itemsize}>
                    {weight.itemsize}
                  </MenuItem>
                ))}
              </TextField>
        
              
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Brand Name"
                onChange={(e) => setBrandName(e.target.value)}
                value={brandName}
                name="brandName"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Discription"
                onChange={(e) => setDiscription(e.target.value)}
                value={discription}
                name="discription"
                sx={{ gridColumn: "span 4", marginBottom: '12px'}}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Regular Price"
                onChange={(e) => setRegularPrice(e.target.value)}
                value={regularPrice}
                name="regularPrice"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Sales Price"
                onChange={(e) => setSalePrice(e.target.value)}
                value={salePrice}
                name="salePrice"
                sx={{ gridColumn: "span 4" }}
              />
              </Box>
              
              <Box display={'grid'} gap={'20px'} gridTemplateColumns="repeat(1, minmax(0, 1fr))" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
              <Typography>Specifications</Typography>
                <Box display={'grid'} gap={'20px'} gridTemplateColumns="repeat(4, minmax(0, 1fr))" gridAutoRows={'52px'} sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, height: '400px', overflowY: 'scroll'}}>
                
                  { specLists.map((spec, index) => 
                    <Box key={spec.spec} display={'flex'} flexDirection={'row'} gap={'12px'} width={'100%'} sx={{ gridColumn: "span 4", height: 'fit-Content' }} >
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Name"
                        onChange={ e => handleSpecNameChange(e, index)}
                        value={specLists[index]['name']}
                        name="productName"
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Value"
                        onChange={ e => handleSpecValueChange(e, index)}
                        value={specLists[index]['value']}
                        name="productName"
                        sx={{ gridColumn: "span 2" }}
                      />
                      {specLists[index].remove ? 
                        <Button color="inherit" onClick={()=>removeSpec(index)}>Remove</Button>:
                        <Button color="inherit" onClick={()=>addSpec(index)}>Add</Button>
                      }
                    </Box>
                  )}
                </Box>
                <div className="uploader">
                  <div className="caspian_uploader">
                    <label htmlFor="upload" className="d-flex flex-column align-items-center justify-content-center">
                    <AddPhotoAlternateIcon fontSize="large" />
                    <span>Upload Image</span>
                    </label>
                    <input className='filefield' type="file" id='upload' multiple filename="profileImg" onChange={onChangeFile} />
                  </div>
                  {imageUrl!==''&&<div className="previews">
                    <div className="fileList">
                      <img src={imageUrl} alt={prodToUpdate['fileName']} id="image" />
                      <p className="filename">{prodToUpdate['fileName']}</p>
                      <span className="removeImage" onClick={()=>setImageUrl('')}></span>
                    </div>
                  </div>}
                  {images.length > 0 && 
                    <div className="preview">
                      
                      {images.map((image, idx) => 
                        <div key={idx} className="fileList">
                          <img src={image} alt={fileName[idx].name} id="image" />
                          <p className="filename">{fileName[idx].name}</p>
                          <span className="removeImage" onClick={()=>removeImage(idx)}></span>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </Box>
              </Box>
            {id !== undefined ? (
              <Box display="flex" justifyContent="end" mt="20px">
              <Button onClick={updateProduct} color="secondary" variant="contained">
                Update 
              </Button>
             </Box>
            ):(
            
            <Box display="flex" justifyContent="end" mt="20px">
              <Button color="secondary" variant="contained" onClick={handleFormSubmit}>
                Add product
              </Button>
            </Box>
            )}
          </form>
        )}
      </Formik>
      
        <div>
        {/* {activeStep === steps.length ?
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
          </>:
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} > Back </Button>
            <Button color="inherit" onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'} </Button>
          </Box>
        } */}
        </div>
    </Box>
    </>
  );
};





export default Form;