import React from 'react'
import '../css/Footer.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../utils/Axios'
import { useState } from 'react'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { FaXTwitter, FaYoutube } from 'react-icons/fa6'
import { FaFacebookF } from 'react-icons/fa'
import { FaTiktok } from 'react-icons/fa'
import useWindowDimensions from '../utils/useWindowDimension'

function Footer() {
  const [contentSeos, setContentSeos] = useState([])
  const { width } = useWindowDimensions();


  useEffect(() => {
    axios
      .get('content-management/seos')
      .then((res) => {
        const column1Items = res.data.filter((_, index) => index % 2 === 0)
        const column2Items = res.data.filter((_, index) => index % 2 !== 0)
        let data = [column1Items, column2Items]
        setContentSeos(data)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <div className="footer">
    <footer className="pt-5">
    <div className="row m-0">
      {contentSeos.map((data, idx) =>
        <div className='col-6 col-sm-3 mb-3 mb-sm-0' key={idx}>
          <h2>{idx===0 ? 'Company': 'Legal'}</h2>
            <ul className="nav flex-column">
            {data.map((seo, rowIdx)=> 
            <li key={rowIdx} className="nav-item mb-2"><Link to={'/'+seo.seoPageName} className="nav-link p-0 " style={{color:"lightgray"}}>{seo.seoTitle}</Link></li>
            )}
            {idx===0 ? 
              <>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>Return Policy</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>Dispute Resolution Policy</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>Payment information</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>Delivery information</Link></li>
              </>:
              <>
                <li className="nav-item mb-2"><Link to="/about-us" className="nav-link p-0 " style={{color:"lightgray"}}>About us</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>Sell on caspian Outlets</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>Pricing</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>FAQs</Link></li>
{/*                 
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>(+233)0593855420</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 " style={{color:"lightgray"}}>(+233)0593855318</Link></li> */}
              </>
            }
          </ul>
        </div>
      )}
      <div className="col-6 col-sm-3">
        <h2>Categories</h2>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="/category/Electronics" className="nav-link p-0 " style={{color:"lightgray"}}>Electronics</Link></li>
          <li className="nav-item mb-2"><Link to="/category/Books" className="nav-link p-0 " style={{color:"lightgray"}}>Books</Link></li>
          <li className="nav-item mb-2"><Link to="/category/Construction" className="nav-link p-0 " style={{color:"lightgray"}}>Building Materials</Link></li>
          <li className="nav-item mb-2"><Link to="/category/Home, Furniture & Appliances" className="nav-link p-0 " style={{color:"lightgray"}}>Home Appliances</Link></li>
        </ul>
      </div>

      <div className="col-6 col-sm-3">
        <h2>Social</h2>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="https://web.facebook.com/CaspianOutletsLtd/?_rdc=1&_rdr" className="nav-link p-0 " style={{color:"lightgray"}}>Facebook</Link></li>
          <li className="nav-item mb-2"><Link to="https://www.x.com/CaspianLimited?t=Z6KraTpsQDpdUAetAHVAsQ&s=01" className="nav-link p-0 " style={{color:"lightgray"}}>X (formerly Twitter)</Link></li>
          <li className="nav-item mb-2"><Link to="https://www.youtube.com/channel/UCNTn5lSnFLgt0Il3GlIp5Kw" className="nav-link p-0 " style={{color:"lightgray"}}>YouTube</Link></li>
          <li className="nav-item mb-2"><Link to="https://www.tiktok.com/@caspian.outlets" className="nav-link p-0 " style={{color:"lightgray"}}>TikTok</Link></li>
        </ul>
      </div>
    </div>

    <div className="d-flex justify-content-around pt-4 mt-4 border-top">
      <p className='text-center'>&copy; {new Date().getFullYear()} Caspian Outlets. All rights reserved.</p>
      {width >= 512 && <div>
        <Link to={'https://web.facebook.com/CaspianOutletsLtd/?_rdc=1&_rdr'} className='text-white'><FaFacebookF color='white' fontSize='1.5rem'/></Link>
        <Link to={'https://www.x.com/CaspianLimited?t=Z6KraTpsQDpdUAetAHVAsQ&s=01'} className='text-white'><FaXTwitter color='white' fontSize='1.5rem' className='mx-3'/></Link>
        <Link to={'https://www.youtube.com/channel/UCNTn5lSnFLgt0Il3GlIp5Kw'} className='text-white me-3' ><FaYoutube color='white' fontSize='1.5rem'/></Link>
        <Link to={'https://www.tiktok.com/@caspian.outlets'} className='text-white'><FaTiktok /></Link>
      </div>}
    </div>
    {width < 512 && <div className='d-flex justify-content-center pb-3'>
        <Link to={'https://web.facebook.com/CaspianOutletsLtd/?_rdc=1&_rdr'} className='text-white'><FaFacebookF color='white' fontSize='1.5rem'/></Link>
        <Link to={'https://www.x.com/CaspianLimited?t=Z6KraTpsQDpdUAetAHVAsQ&s=01'} className='text-white'><FaXTwitter color='white' fontSize='1.5rem' className='mx-3'/></Link>
        <Link to={'https://www.youtube.com/channel/UCNTn5lSnFLgt0Il3GlIp5Kw'} className='text-white me-3' ><FaYoutube color='white' fontSize='1.5rem'/></Link>
        <Link to={'https://www.tiktok.com/@caspian.outlets'} className='text-white'><FaTiktok /></Link>
      </div>}
  </footer>
</div>
  )
}

export default Footer
