import { Box, IconButton, Menu, useTheme } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import axios from '../../utils/Axios'
import { ColorModeContext, tokens } from '../../utils/theme'
import InputBase from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import SearchIcon from '@mui/icons-material/Search'
import Logout from '@mui/icons-material/Logout'
import ListItemIcon from '@mui/material/ListItemIcon'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import AuthContext from '../../utils/AuthLoggedIn'
import { useNavigate } from 'react-router-dom'
import EventEmitter from '../../utils/eventEmitter'

const Topbar = ({ loginDetails }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  // const { loginDetails } = useContext(AuthContext);
  const colorMode = useContext(ColorModeContext)
  const [toggle, setToggle] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  async function logout() {
    await axios.get('/logout')
    loginDetails()
    // localStorage.removeItem('rl');
    // localStorage.removeItem('number');
    // localStorage.removeItem('user_id')
    // await getLoggedIn();
    // localStorage.removeItem("number")
    // localStorage.removeItem("user_id")

    window.location.href = '/'
  }

  const label = { inputProps: { 'aria-label': 'change login type' } }

  const handleChecked = (e) => {
    setToggle(e.target.checked)
    if (e.target.checked) {
      EventEmitter.dispatch('logintype', 'admin')
    } else {
      EventEmitter.dispatch('logintype', 'vendor')
    }
  }

  return (
    <Box display='flex' justifyContent='space-between' p={2}>
      {/* SEARCH BAR */}
      <Box
        display='flex'
        backgroundColor={colors.primary[400]}
        borderRadius='3px'
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder='Search' />
        <IconButton type='button' sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display='flex'>
        <Tooltip title='toggle Admin / Vendor' arrow>
          <Switch
            {...label}
            color='secondary'
            checked={toggle}
            onChange={handleChecked}
          />
        </Tooltip>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              maxHeight: 48 * 4.5,
              width: '20ch'
            }
          }}
        >
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  )
}

export default Topbar
