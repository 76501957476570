import React, { useState, useContext, useEffect } from 'react'
import axios from '../utils/Axios'
import '../css/signup.css'
import jwt_decode from 'jwt-decode'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import AuthContext from '../utils/AuthLoggedIn'
import { useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Signupform() {
  const [signUpData, setSignUpData] = useState({
    firstname: '',
    surname: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: ''
  })
  const [message, setMessage] = useState(null)
  const navigate = useNavigate()
  // const location = useLocation()
  // const { currenPath, price } = location.state
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [msg, setMsg] = useState('')
  const [data, setData] = useState(false)
  //  const [telnumber, setTelNumber] = useState("");
  //  const [password, setpass] = useState("");

  //  const { getLoggedIn, loggedIn } = useContext(AuthContext);

  const sendForm = async (e) => {
    e.preventDefault()
    let isEmpty = Object.values(signUpData).some((value) => value === '')
    if (!isEmpty) {
      setMessage(null)
      if (signUpData.password === signUpData.confirm_password) {
        setMessage(null)
        try {
          await axios.post('/signup/new', signUpData)

          // await getLoggedIn();

          const { data } = await axios.get('/getToken')
          if (data) {
            return navigate('/')
          }
          setData(data)
          // const token = data.token;
          // const decode = jwt_decode(token);
          // localStorage.setItem("user_id", decode.user);
          // localStorage.setItem("number", decode.telNumber);
        } catch (err) {
          console.error(err)
          setMsg(err.response.data.errorMessage)
          setShowSnackbar(true)
          // console.log('error: ', res.data.errorMessage)
        }
      } else {
        setMessage('missed match passwords! please try again!')
      }
    } else {
      setMessage('All fields are required!')
    }
  }

  useEffect(() => {
    if (signUpData.password !== signUpData.confirm_password) {
      setMessage('missed match passwords! please try again!')
    } else {
      setMessage(null)
    }
  }, [signUpData.confirm_password, signUpData.password])

  const onChangeFirstname = (e) =>
    setSignUpData({ ...signUpData, firstname: e.target.value })
  const onChangeSurname = (e) =>
    setSignUpData({ ...signUpData, surname: e.target.value })
  const onChangeEmail = (e) =>
    setSignUpData({ ...signUpData, email: e.target.value })
  const onChangeContact = (e) => {
    var numberRegex = /^\d+$/
    if (numberRegex.test(e.target.value * 1)) {
      setSignUpData({ ...signUpData, phone_number: e.target.value })
    }
  }
  const onChangePassword = (e) =>
    setSignUpData({ ...signUpData, password: e.target.value })
  const onChangeConfirm = (e) =>
    setSignUpData({ ...signUpData, confirm_password: e.target.value })

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
  }

  if (!data) {
    return (
      <>
        <Helmet>
          <title>Register</title>
          <meta name='title' content='Register' />
          <meta name='robots' content='noindex, nofollow' />
        </Helmet>

        <div className='wrapp'>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: '100%' }}
            >
              {msg}
            </Alert>
          </Snackbar>
          <section className='form signup'>
            <div className='d-flex justify-content-center'>
              <img
                className='logo_'
                src='uploads/caspian_logo.png'
                alt='Caspian'
                height={100}
              />
            </div>
            <form
              className='user_auth_form'
              onSubmit={sendForm}
              encType='multipart/form-data'
            >
              <div className='signup_heading_container'>
                <h1 className='signup_heading'>Welcome</h1>
                <p className='signup_text'>Sign Up</p>
              </div>
              <small style={{ alignSelf: 'flex-start', marginBottom: '12px' }}>
                All <span style={{ color: 'red' }}>*</span> are required fields!
              </small>
              <div className='field login input'>
                <label>
                  First name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name='firstname'
                  value={signUpData.firstname}
                  onChange={onChangeFirstname}
                  type='text'
                />
              </div>
              <div className='field login input'>
                <label>
                  Last name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name='surname'
                  value={signUpData.surname}
                  onChange={onChangeSurname}
                  type='text'
                />
              </div>
              <div className='field login input'>
                <label>
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name='email'
                  value={signUpData.email}
                  onChange={onChangeEmail}
                  type='email'
                />
              </div>
              <div className='field login input'>
                <label>
                  Phone number <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name='phone_number'
                  value={signUpData.phone_number}
                  onChange={onChangeContact}
                  type='tel'
                  typeof='number'
                  maxLength={10}
                />
              </div>
              <div className='field login input'>
                <label>
                  Password <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name='password'
                  value={signUpData.password}
                  onChange={onChangePassword}
                  type='password'
                />
              </div>
              <div className='field login input'>
                <label>
                  Confirm password <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name='confirm_password'
                  value={signUpData.confirm_password}
                  onChange={onChangeConfirm}
                  type='password'
                />
                {message !== '' && (
                  <small style={{ color: 'red' }}>{message}</small>
                )}
              </div>
              <button className='btn btn-secondary submitbtn' type='submit'>
                Signup
              </button>
            </form>
          </section>
        </div>
      </>
    )
  }
}

export default Signupform
