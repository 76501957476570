import { useState } from 'react'
import {
  Routes,
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import Dashboard from '../vendors/scenes/dashboard'
import Team from '../vendors/scenes/team'
import Invoices from '../vendors/scenes/invoices'
import Contacts from '../vendors/scenes/contacts'
import Bar from '../vendors/scenes/bar'
import Form from '../vendors/scenes/form'
import Line from '../vendors/scenes/line'
import Pie from '../vendors/scenes/pie'
import FAQ from '../vendors/scenes/faq'
import Geography from '../vendors/scenes/geography'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext, useMode } from '../utils/theme'
// import Calendar from "../vendors/scenes/calendar";
import '../css/AdminRoute.css'
// // import PostProductComponenet from "./PostProductComponenet";
import ProductList from '../vendors/scenes/products/productlist'
import Orders from '../vendors/scenes/Orders'
import AddImage from '../vendors/scenes/form/addproduct'
import VendorsLayout from '../layouts/VendorsLayout'
import AddSeller from '../scenes/AddSeller'
import VendorRegistrationPage from '../pages/VendorRegistrationPage'
import EventEmitter from '../utils/eventEmitter'


const vendorsRouter = (user) => {
  // const [user, setUser] = useState({});
  // EventEmitter.subscribe('details', user => setUser(user));

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
      {user.varified === false &&<Route path='/ven/add-seller' element={<AddSeller />} />}
      <Route path='/ven/seller_center' element={<VendorRegistrationPage />} />
        <Route path='/' element={<VendorsLayout />}>
          <Route index element={<Dashboard />} />
          <Route path='orders' element={<Orders />} />
          <Route path='productlist' element={<ProductList />} />
          <Route path='invoices' element={<Invoices />} />
          <Route path='form' element={<Form />} />
          <Route path='addimage' element={<AddImage />} />
          <Route path='add_seller' element={<AddSeller />} />
        </Route>
      </>
    )
  );
  return router;
}

export default vendorsRouter
