import React from "react";




const ImageBannerComponent=({ image })=>{
  return (
    <div className="rec_banner mb-3">
      <img src={image} />
    </div>
  )
};


export default ImageBannerComponent;