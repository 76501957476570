import React from "react";
import Table from 'react-bootstrap/Table';
import { FaCircleUser } from "react-icons/fa6";
import { useOutletContext } from "react-router-dom";




const Accounts = ({details}) => {
  console.log(details)
  return (
    <div className='container-fluid'>
      <div className='d-flex flex-column align-items-center  mx-lg-5'>
      <div className="mb-1">
        <FaCircleUser size={'7rem'} color="gray" className='.img-thumbnail rounded-circle border '/>
      </div>
      <h3>{details.name}</h3>
      <Table>
        <thead>
          <tr className='fw-bold fs-5'><th colSpan={2}>Details</th></tr>
        </thead>
        <tbody>
          <tr>
            <td>Email</td>
            <td>{details.email}</td>
          </tr>
          <tr>
            <td>Password</td>
            <td>*****</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>{details.number}</td>
          </tr>
          
        </tbody>
      </Table>
    </div>
    </div>
  )
};


export default Accounts;