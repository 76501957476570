import React, { useState, useEffect } from 'react';
import "../css/PaymentMethod.css";
import CheckIcon from '@mui/icons-material/Check';
import paystack_gh from '../images/paystack.png';
import BillingAddress from './BillingAddress';
import { Content } from '../unused_components/SubCategoriesComponent';


function PaymentMethod({onChange, freeDeliveryCities, enablePayment, formdata, addressdata, ispayment, isOrderConfirmed, setIsPayment, setIsOrderConfirmed, setPaymentConfirmed}) {
    const [payment, setPayment] = useState("");
    const [checkClassName, setCheckClassName] = useState("check")
    const [isBillingAddress, setIsBillingAddress] = useState(true);

    const toggleBillingAddress=()=>setIsBillingAddress(!isBillingAddress);

    const onPaymentChange = e =>{
        setPayment(e.target.value)
        setCheckClassName("checkCompleted")
        setIsPayment(true);
        setPaymentConfirmed(true);
    }


    // const PaymentChange =()=>{
    //   if (payment == "CashOnDelivery" || payment == "MobileMoneyOnDelivery" || payment == "PayStack") {
    //     setCheckClassName("checkCompleted")
    //     setIsPayment(true);
    //     setPaymentConfirmed(true);
    //   }
    // }
    const changePayment = async () => {
        setPaymentConfirmed(false);  
        setIsPayment(false);
        setIsOrderConfirmed(false);
      }


    useEffect(() => {
          onChange(payment)

      }, [payment]);
  return (
    
    <div className="payment__method">
      {/* {ispayment === false ?( */}
        <>
          <form>
            <div className="payment__tittle">
              <CheckIcon className={checkClassName}/>
              <div className='d-flex align-items-center ms-2'><h6 style={{fontSize:'14px', margin:0}}>2. PAYMENT METHOD</h6></div>
            </div>
            <div className="payment__type__select d-flex w-100">
                <div className='input_con'>
                    <input type="radio"
                    name="payment"
                    value="PayStack"
                    id="paystack"
                    checked={payment === "PayStack"}
                    onChange={onPaymentChange}
                    disabled={enablePayment?false:true}
                    />
                </div>
                <div className="payment__type__details p-1" style={{color:enablePayment?'black':'lightgray'}}>
                    <h6>Online Payment</h6>
                    <small style={{color:enablePayment?'gray':'lightgray'}}>Make payment using your card or mobile money</small>
                </div>
                <div className='payment_type_image d-none d-sm-flex'><img src={paystack_gh} alt='paystack securd icon' height={70} className='img-fluid me-sm-1 me-lg-3 me-xl-0'/></div>
            </div>
            {(formdata.city && freeDeliveryCities.includes(formdata.city.toLowerCase())) &&<div className="payment__type__select d-flex w-100 border-bottom ">
                <div className='input_con'>
                    <input type="radio"
                    name="payment" 
                    value="CashOnDelivery"
                    id="cashondelivery"
                    checked={payment === "CashOnDelivery"}
                    onChange={onPaymentChange} 
                    disabled={enablePayment?false:true}
                    />
                </div>
                <div className="payment__type__details p-1">
                    <h6 style={{color:enablePayment?'black':'lightgray'}}>Cash on delivery</h6>
                    <small style={{color:enablePayment?'gray':'lightgray'}}>Pay Cash on delivery</small>
                </div>
            </div>}
        </form> 
        {<div style={{width: 'fit-content'}}><Content name={'Same as billing'} disabled={payment!==''?false:true} check={isBillingAddress} handleCheckBox={toggleBillingAddress} id='d'/></div>}
        {(!isBillingAddress)&&<BillingAddress formdata={formdata} />}
          {/* {(ispayment===false && payment!=='' )&&<div className='d-flex justify-content-end'> <button onClick={PaymentChange} className="confirmbtn mb-2 me-2">COMFIRM PAYMENT METHOD</button></div>} */}
            </>
            {/* ):( */}
                {/* <> */}
                {/* <div className="address__tittle pt-2">
                  <div className="icon__text ps-2">
                    <CheckIcon className={checkClassName}/>
                    <div className="ps-2">PAYMENT METHOD</div>
                  </div>
                  {!isOrderConfirmed&&<div>
                     <button onClick={changePayment} className="changebtn">Change</button>
                  </div>}
                </div>
                    <hr className="hoz__rule"/>
                    {payment == "PayStack" && (
                        <div className="payment__details">
                        <div className="cus__name">
                            Online Payment with MoMo or bank card
                        </div>
                        <div className="cus__address">Make an online payment on the Caspian outlets</div>
                        </div>
                    )}{payment == "MobileMoneyOnDeleivery" &&(
                    <div className="payment__details">
                    <div className="cus__name">
                        Mobile Money on delivery
                    </div>
                    <div className="cus__address">Pay with Mobile Money on delivery</div>
                    </div>
                    )}{payment == "CashOnDelivery" &&(
                        <div className="payment__details">
                        <div className="cus__name">
                            Cash on delivery
                        </div>
                        <div className="cus__address">Pay Cash on delivery</div>
                        </div>
                        )} */}
              {/* </> */}
            {/* )} */}
      </div>
    
    
  )
}

export default PaymentMethod