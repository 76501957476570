import React, { useEffect, useState } from "react";
import './AllCategoriesComponent';
import { CategoryProductCard } from "./CategoryProductsComponent";
import CategoriesGroup from "../components/CategoriesGroup";
import CategoriesGroupComponent from "../components/CategoriesGroupComponent";
import OffCavasFilter from "../unused_components/OffCanvasFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { useLocation } from "react-router-dom";
import { useStateValue } from "../utils/StateProvider";
import Loader from "../components/Loader";
import axios from '../utils/Axios';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";



const VendorsPage = () => {
  const location = useLocation();
  // const { searchCate } = location.state.category;
  const [products, setProducts] = useState({ catitem: [], totalPages: 0, currentPage: 1, total:0});
  const [filter, setFilter] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [brands, setBrands] = useState([]);
  const [{ basket }, dispatch] = useStateValue();
  const [subCategoryProducts, setSubCategoryProducts] = useState([]);
  const [subCategoryCheckBox, setSubCategoryCheckBox] = useState();
  const [brandsCheckbox, setBrandsCheckbox] = useState();
  const [productBrands, setProductBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterProducts, setFilterProducts] = useState({});
  const [page, setPage] = useState(1);
  // const [categoryOne, setCategoryOne] = useState('');
  var categoryOne = '';
  useEffect(()=>{
    let isMounted = true;
    axios.get(`categories/${categoryOne!==''?categoryOne:'all'}?page=${page}&limit=50`)
    .then(res=>{
      if (isMounted)setProducts(res.data);
    })
    return()=>{ isMounted = false}
  },[page, categoryOne])


  // const fetchVendorsPage = () => {
  //   axios.get('categoriesLookups').then(res=>{
  //     setCategories(res.data);
  //   })
  //   .catch(err=>console.log(err))
  // }

  const fetchCategory = (categoryType) => {
    categoryOne = categoryType;
    // console.log('cat One: ', categoryOne)
    axios.get(`categories/${categoryType}?page=${page}&limit=50`) 
    .then(res=>{
      setProducts(res.data);
    })
    .catch(err=>console.log(err))
  }

  useEffect(()=>{
    let isMounted = true;
    axios.get('categoriesLookups').then(res=>{
      if (isMounted)setCategories(res.data);
    })
    .catch(err=>console.log(err))

    return()=>{ isMounted = false}
  },[])

  useEffect(()=>{
    let category = [];
    let subCategory = [];
    let productBrands = [];
    products.catitem.forEach(product=>{
      category.push(product['category']);
      subCategory.push(product['subCategory']);
      productBrands.push(product['brandName'].trim());
    });

    // Remove duplicates
    const category_ = category.filter((_category, index)=>category.indexOf(_category)===index);
    const subCategory_ = subCategory.filter((category, index)=>subCategory.indexOf(category)===index);
    const productBrands_ = productBrands.filter((brand, index)=>productBrands.indexOf(brand)===index);
    // setCategories(category_);
    setSubCategory(subCategory_);
    setBrands(productBrands_);

    setSubCategoryCheckBox(Array(subCategory_.length).fill(false));
    setBrandsCheckbox(Array(productBrands_.length).fill(false))
  },[products])


  const handleChange = (event, value) => {
    setPage(value);
  };

  const toggleFiltter=()=>setFilter(!filter);
  const handleClose=()=>setFilter(false);

  

  const handleCheckBox=(e, index)=>{
    const newValues = [...subCategoryCheckBox];  //copies sub category boxes array of false;
    newValues[index] = !newValues[index];        //toggle the specific sub category checkbox

    let storedCateProd = [];

      setSubCategoryCheckBox(newValues);           
      products.catitem.forEach(product=>{
        if (newValues[index]===true&&product['subCategory'] === e.target.value){
          storedCateProd.push(product);
          setSubCategoryProducts([...subCategoryProducts, storedCateProd]);
        }
        else if (newValues[index]===false&&product['subCategory']===e.target.value){
          let subCategoryProducts_ = [...subCategoryProducts];
          subCategoryProducts_.forEach(product=>{
            let filter = product.filter(item=>item['subCategory']!==e.target.value);
            if (filter.length>0){
              storedCateProd=[];
              storedCateProd.push(filter)
              setSubCategoryProducts(storedCateProd);
            }else {
              setSubCategoryProducts(storedCateProd);
            }
          })
        }
      })
  }
  const handleBrandsCheckBox=(brand, brandHeading, index)=>{
    const newBrandValues = [...brandsCheckbox];  //copies sub category boxes array of false;
    newBrandValues[index] = !newBrandValues[index];        //toggle the specific sub category checkbox
    if (brandHeading.toLowerCase()==='brands'){ console.log('on brands: ', brand, brandHeading)
      // let storedBrandProd = [];  
      setBrandsCheckbox(newBrandValues);  
      products.forEach(product=>{
        if (product['brandName'] === brand){
          setProductBrands([...productBrands, brand]);
        }
      })
    }
  }

  const onClickCategory = (cate) =>{
    if (categories.length===1){
      fetchCategory('all');
    }else {
      fetchCategory(cate);
    };
  };

  return (
    <>
    <Helmet>
      <title>Vendor Page - Caspian</title>
      <meta name="description" content="Browse through our store and shop for your products at an affordable price" />
      <meta name="robots" content="index, follow" />
    </Helmet>
      <div className="container-xxl cat_con">
        <div className="row">
          <div className="col-12 my-1 p-0 px-sm-3">
            <div className="d-flex justify-content-between bg-white p-2">
              <h4 className="fw-bold m-0" style={{fontSize: '19px'}}>Vendor Name</h4>
              <Link className="m-0" to={'/vendor/'+'akuse'} style={{width: '100px'}}>See Profile</Link>
            </div>
          </div>
          <div className="col-12 mb-3 p-0 px-sm-3">
            <div className="d-flex justify-content-between bg-white p-2">
              <div style={{width:'300px'}}>
                <p className="m-0 w-100"><span className="fw-bold me-2">90% </span>Vendor Score</p>
                <p className="m-0"><span className="fw-bold me-2">400</span> Followers</p>
              </div>
              <button className="border-0" style={{width:'100px', color:'', backgroundColor:'#05bcc4'}}>Follow</button>
            </div>
          </div>
          <div className=" col-md-2 pe-1 ps-0">
            <div className="category_sidebar">
              <div className="d-flex justify-content-between align-items-center bg-white mb-2 py-md-2 ">
                <p className="m-0 ps-2 fw-bold">Refine your search</p>
                <button className="border-0 d-md-none" onClick={toggleFiltter}><FilterAltOutlinedIcon style={{height:'30px', width:'30px'}}/></button>
              </div>
              <OffCavasFilter show={filter} handleClose={handleClose}/>
              <div className="d-none d-md-block">
                  <div className="">
                    <div className="sub_category_heading_con ps-md-2">
                      <h5 className="fw-bold">{'Categories'}</h5>
                    </div>
                    {categories.length > 0 ?
                      <form className="sub_cat_form">
                        {categories.map((cate,idx)=>
                        <div key={idx} className="content_ d-flex">
                          {/* <input type={'checkbox'} id={idx} onChange={(e=>handleCheckBox(e, idx))} checked={subCategoryCheckBox[idx]} value={cate}/> */}
                          {/* <label htmlFor={idx}>{cate}</label> */}
                          {/* {categories.length===1&&<span className="category_link" onClick={()=>fetchCategory('all')}><ArrowBackIosIcon fontSize="small"/></span>} */}
                          <p className="category_link" onClick={()=>onClickCategory(cate.name)}>{categories.length===1&&<ArrowBackIosIcon fontSize="small"/>}{cate.name}</p>
                        </div>
                        )}
                      </form>:
                      <Loader />
                    }
                  </div>
                  <div className="">
                    <div className="sub_category_heading_con ps-md-2">
                      <h5 className="fw-bold">{'Sub categories'}</h5>
                    </div>
                    <form className="sub_cat_form">
                      {subCategory.map((cate,idx)=>
                      <div key={idx} className="content_ d-flex">
                        <p className="category_link" onClick={()=>onClickCategory(cate)}>{cate}</p>
                      </div>
                      )}
                    </form>
                  </div>
              </div>
            </div>
          </div>
          <div className=" col-sm col-md-10 ">
            <div className="category_products_container">
              <div className="d-flex justify-content-between align-items-center bg-white product_infos">
                <h3 className="m-0">{'Vendor Name'}</h3>
                <p className="m-0">{products.total} products found</p>
              </div>
            {subCategoryProducts.length>0?
              <div className="row category_products">
                {subCategoryProducts.map(product=>
                  product.map(item=>{
                    return (
                      <CategoryProductCard
                        key={item._id}
                        product={item}
                        dispatch={dispatch}
                        basket={basket}
                      />
                    )
                  })
                )}
                <div>
                  <Stack spacing={2}>
                    <Pagination count={10} shape="rounded" />
                  </Stack>
                  </div>
              </div>:
              <div className="row category_products">
                {products.catitem.length > 0 ?
                  <>
                    {products.catitem.map(product=>
                    <CategoryProductCard
                      key={product._id}
                      product={product}
                      dispatch={dispatch}
                      basket={basket}
                    />  
                  )}
                  </>:
                  <Loader />
                }
                <div className="d-flex justify-content-center my-3">
                  <Stack spacing={2}>
                    <Pagination count={products.totalPages} page={products.currentPage} onChange={handleChange} shape="rounded" />
                  </Stack>
                  </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default VendorsPage;