import React, { useState, useEffect } from "react";
import axios from "../utils/Axios";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../utils/theme";
import './products/productlist.css';
import Loader from "../components/Loader";
import { deleteAlert } from "./alerts";




function Sellers () {
    const [allprod, setAllprod] = useState([]);
    const [products, setProducts] = useState({list:[], total:0});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page:0});
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleView = (id) => {
      console.log(id)
    };

    const gotoDetails = (id) => navigate('/seller/'+id);

    function deleteItem(itemId, swalWithBootstrapButtons) {
      axios.delete(`/delete_prod/${itemId}`)
     .then(response => {
      swalWithBootstrapButtons.fire('Deleted!', response.data.message, 'success');
     })
     .catch(error => {
        console.error('Error:', error);
     });
    }


  const actionCell = (row) => {
    return (
      <div className="w-100 d-flex justify-content-around">
        <ListItemIcon onClick={()=>gotoDetails(row._id)}><VisibilityIcon sx={{marginRight: '5px'}}/>View</ListItemIcon>
        {/* <ListItemIcon ><EditIcon sx={{marginRight: '5px'}}/>Verify</ListItemIcon> */}
        {/* <ListItemIcon ><EditIcon sx={{marginRight: '5px'}}/>View</ListItemIcon> */}
        {/* <ListItemIcon onClick={()=>deleteAlert(deleteItem, row['_id'])}><DeleteIcon sx={{marginRight: '5px'}}/>Delete</ListItemIcon> */}
      </div>
    )
  }

  const onPaginationModelChange = (model, details) => {
    if (model.page === 0){
      // setPaginationModel({...paginationModel, page:1, pageSize:model.pageSize});
      setPage(1);
      console.log('page is zero: ', model.page, details)
      setPageSize(model.pageSize);
    }else {
      // setPaginationModel({...paginationModel, page:model.page, pageSize:model.pageSize});
      setPage(model.page + 1);
      console.log('page is not zero: ', model, details)
      setPageSize(model.pageSize);
    }
  }

  // async function getChainProducts(){
  //   await axios.get(`/productslist?page=${page}&&limit=${pageSize}`)
  //   .then((response) =>{
  //     const chainData = response.data;
  //       setProducts({...products, list:chainData.products, total:chainData.total});
  //   });
  // }


  // useEffect(() => {
  //   getChainProducts();
  // }, [page, pageSize]);

  const row = [
    {_id: 1, imageUrl: 'no image', name: 'Seller A', location: 'Akapta', isVerified: 0, flowtype: 'registering'},
    {_id: 2, imageUrl: 'no image', name: 'Seller B', location: 'Oboko', isVerified: 1, flowtype: 'adding product'},
    {_id: 3, imageUrl: 'no image', name: 'Seller C', location: 'Nsuano', isVerified: 0, flowtype: 'registering'},
    {_id: 4, imageUrl: 'no image', name: 'Seller D', location: 'Emanyi', isVerified: 1, flowtype: 'adding product'},
    {_id: 5, imageUrl: 'no image', name: 'Seller E', location: 'Akuyi', isVerified: 1, flowtype: 'registering'},
    {_id: 6, imageUrl: 'no image', name: 'Seller F', location: 'Obrim', isVerified: 0, flowtype: 'registering'}
  ]

  const columns = [
    { field: '_id',
      headerName: 'ID',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Seller Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "isVerified",
      headerName: "Is Verified",
      flex: 1,
    },
    {
      field: "flowtype",
      headerName: "Work Flow Type",
      flex: 1,
    },
    {
      field: "actions",
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      // flex: 1,
      textAlign: 'center',
      // valueGetter: params => ,
      renderCell: (params) => actionCell(params.row)
    }
  ];

    // if (products.length === 0) return <Loader />
  return (
    <Box m="20px" height="82vh">
      <Box
        m="40px 0 0 0"
        height="85vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid         
          rows={row} 
          columns={columns}
          rowCount={row.length}
          pagination
          initialState={{pagination:{paginationModel:{page:0}}}}
          paginationModel={{page:page-1, pageSize:pageSize}} 
          getRowId={obj=>obj._id} 
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
        />
      </Box>
    </Box>
  )
}

export default Sellers;