import { json } from 'react-router-dom';
import axios from '../utils/Axios';


export const loadCategoryType = async(type) => {
    try {
        const response = await axios.get(`/api/categories/${type}`)
        return response.data;
    }catch(err){
        throw json({message: 'This page could not be found!', status:'Oops!'})
    }
}       

