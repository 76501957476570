import { React, useContext, useEffect, useState } from 'react'
import '../css/Product_details.css'
import axios from '../utils/Axios'
import Table from 'react-bootstrap/Table'
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useLoaderData,
  useOutletContext
} from 'react-router-dom'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import { useStateValue } from '../utils/StateProvider'
import { getBasketTotal } from '../utils/Reducer'
import Rating from '@mui/material/Rating'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import Radio from '@mui/material/Radio'
import RadioGroupComponent, {
  RadioComponent,
  useStyles
} from '../unused_components/RadioGroupComponent'
import PopUp from '../unused_components/PopUp'
import AuthContext from '../utils/AuthLoggedIn'
import CategoriesGroup from '../components/CategoriesGroup'
import CategoriesGroupComponent from '../components/CategoriesGroupComponent'
import SelectMenu from '../components/SelectMenu'
import AddQuantityComponent from '../utils/AddQuantityComponent'
import EventEmitter from '../utils/eventEmitter'
import { Helmet } from 'react-helmet'
import ProductReviews from '../components/ProductReviews'
import ProductSuggestions from '../components/ProductSuggestions'
import GroupComponent from '../components/GroupComponent'
import Availability from '../components/Availability'
import RelatedProducts from '../components/RelatedProducts'

const sizesData = [
  { label: 'XS', value: 'xs' },
  { label: 'S', value: 's' },
  { label: 'M', value: 'm' },
  { label: 'L', value: 'l' },
  { label: 'XL', value: 'xl' }
]

const coloursData = [
  { label: 'Black', value: 'black' },
  { label: 'White', value: 'white' },
  { label: 'Red', value: 'red' },
  { label: 'Green', value: 'green' },
  { label: 'Blue', value: 'blue' }
]

// var product_id = ''
// EventEmitter.subscribe('remove_product', (id) => (product_id = id))

function Product_details() {
  const navigate = useNavigate()
  const { basket, dispatch } = useOutletContext()
  const [productQty, setProductQty] = useState(1)
  const [sizesRadio, setSizesRadio] = useState('')
  const [coloursRadio, setColoursRadio] = useState('Black')
  const [showModal, setShowModal] = useState(false)
  const [productAdded, setProductAdded] = useState(false)
  // const { loggedIn } = useContext(AuthContext);
  const [addressLocation, setAddressLocation] = useState([])
  const [deliveryconstants, setDeliveryconstants] = useState([])
  const [lookupsObj, setLookupsObj] = useState([])
  const [locLookups, setLocLookups] = useState([])
  const [delivery, setDelivery] = useState('')
  const [region, setRegion] = useState('Greater Accra')
  const [city, setCity] = useState('Circle')
  const [deliveryMethod, setDeliveryMethod] = useState('standard')
  const [startDatex, setStartDate] = useState(new Date().toDateString())
  const [endDatex, setEndDate] = useState(new Date().toDateString())
  const [isSamedayDelivery, setIsSamedayDelivery] = useState(false)
  const { productSeo } = useParams()
  // const [productDetails, setProductDetails] = useState({})
  const [suggestion, setSuggestion] = useState()
  const productDetails = useLoaderData()
  const seoTitle = productSeo.replace(/-/g, ' ')
  const [color, setColor] = useState('')
  const [message, setMessage] = useState('')
  const today = new Date()
  let startDate = new Date()
  // tomorrow.setDate(today.getDate()+1);
  let endDate = new Date()
  // nextTwoDays.setDate(today.getDate()+3)
  useEffect(() => {
    async function onChangeRegion() {
      if (isCurrentTimeBefore(13, 10)) {
        // const stdate = new Date();
        startDate.setDate(today.getDate())
        setStartDate(startDate.toDateString())
        endDate.setDate(today.getDate() + 1)
        setEndDate(endDate.toDateString())
        setIsSamedayDelivery(true)
      } else {
        // const stdate = new Date();
        startDate.setDate(today.getDate() + 1)
        setStartDate(startDate.toDateString())
        endDate.setDate(today.getDate() + 2)
        setEndDate(endDate.toDateString())
        setIsSamedayDelivery(false)
      }
    }
    onChangeRegion()
  }, [])

  useEffect(() => {
    EventEmitter.dispatch('findSimilarProducts', productDetails.subCategory)
    EventEmitter.dispatch('findRelatedProducts', productDetails.category)
  }, [productDetails])

  // useEffect(() => {
  //   if (basket.length !== 0) {
  //     let found = basket.findIndex((prod) => prod.id == productDetails._id)
  //     if (found !== -1) {
  //       setProductQty(basket[found]['quantity'])
  //     }
  //   }
  // }, [basket])

  function isCurrentTimeBefore(stipulatedHour, stipulatedMinute) {
    // Get the current time
    const currentTime = new Date()

    // Create a Date object for the stipulated time on the same day
    const stipulatedTime = new Date(currentTime)
    stipulatedTime.setHours(stipulatedHour, stipulatedMinute, 0, 0) // set to stipulated hour and minute

    // Compare the two times
    return currentTime < stipulatedTime
  }
  const discountPrice =
    (
      productDetails.regularPrice.$numberDecimal -
      ((productDetails.discount * 1) / 100) *
        productDetails.regularPrice.$numberDecimal
    ).toFixed(2) * 1

  const addBasket = () => {
    if (productDetails.colors.length > 0) {
      if (color !== '') {
        dispatch({
          type: 'ADD_TO_BASKET',
          item: {
            id: productDetails._id,
            title: productDetails.productName,
            imageUrl: productDetails.imageUrl,
            discount: productDetails.discount * 1,
            quantity: 1,
            weight: productDetails.itemsize,
            basePrice: productDetails.regularPrice.$numberDecimal * 1,
            price: productDetails.regularPrice.$numberDecimal * 1,
            color: color,
            subCategory: productDetails.subCategory,
            deliveryCharges: productDetails.deliveryCharges
          }
        })
      } else {
        setMessage('Please choose color before adding to cart')
      }
    } else {
      dispatch({
        type: 'ADD_TO_BASKET',
        item: {
          id: productDetails._id,
          title: productDetails.productName,
          imageUrl: productDetails.imageUrl,
          discount: productDetails.discount * 1,
          quantity: 1,
          weight: productDetails.itemsize,
          basePrice: productDetails.regularPrice.$numberDecimal * 1,
          price: productDetails.regularPrice.$numberDecimal * 1,
          subCategory: productDetails.subCategory,
          deliveryCharges: productDetails.deliveryCharges
        }
      })
    }
  }

  const handleColorChange = (e) => {
    setColor(e.target.value)
  }

  useEffect(() => {
    let foundItem = basket.findIndex((item) => item.id === productDetails._id)
    if (foundItem !== -1) {
      dispatch({
        type: 'UPDATE_COLOR',
        item: { id: productDetails._id, color: color }
      })
    }
  }, [color])

  const buyNow = () => {
    if (productAdded) {
      shipping()
    } else {
      addBasket()
      shipping()
    }
  }

  const viewCart = () => navigate('/cart')

  const login = () => navigate('/login')

  const shipping = () => navigate('/checkout')

  // useEffect(() => {
  //   async function getLocationLookups() {
  //     await axios.get('/getlocationlookups').then((response) => {
  //       const locationLookups = response.data
  //       setAddressLocation(locationLookups)
  //     })
  //   }
  //   getLocationLookups()
  // }, [])

  // useEffect(() => {
  //   async function getProductDetails() {
  //     await axios.get(`/products/${productSeo}`).then((response) => {
  //       setProductDetails(response.data)
  //       EventEmitter.dispatch('findSimilarProducts', response.data.subCategory);
  //       EventEmitter.dispatch('findRelatedProducts', response.data.category);
  //     })
  //   }
  //   getProductDetails()
  // }, [productSeo])

  useEffect(() => {
    const data_ = localStorage.getItem('tui')
    let data = JSON.parse(data_)
    if (data === null) {
      localStorage.setItem('tui', JSON.stringify([productDetails]))
    } else {
      const findIndex = data.findIndex(
        (item) => item._id === productDetails._id
      )
      if (findIndex === -1) {
        data.push(productDetails)
        localStorage.setItem('tui', JSON.stringify(data))
      }
    }
  }, [])

  // useEffect(() => {
  //   async function getLocationLookups() {
  //     await axios.get('/deliveryconstants').then((response) => {
  //       const deliveryUtls = response.data
  //       setDeliveryconstants(deliveryUtls)
  //     })
  //   }
  //   getLocationLookups()
  // }, [])

  // useEffect(() => {
  //   function getTown() {
  //     let lookups = []
  //     for (let i = 0; i < addressLocation.length; i++) {
  //       if (addressLocation[i].region === region) {
  //         for (var lockey in addressLocation[i].townLookUps) {
  //           lookups.push(lockey)
  //         }
  //         setLocLookups(lookups)
  //         setLookupsObj(addressLocation[i].townLookUps)
  //       }
  //     }
  //   }
  //   getTown()
  // }, [region])

  // useEffect(() => {
  //   let lookups = []
  //   function getTown() {
  //     lookups = []
  //     for (let i = 0; i < addressLocation.length; i++) {
  //       if (addressLocation[i].region === region) {
  //         for (var lockey in addressLocation[i].townLookUps) {
  //           lookups.push(lockey)
  //         }
  //         setLocLookups(lookups)
  //         setLookupsObj(addressLocation[i].townLookUps)
  //       }
  //     }
  //   }
  //   getTown()
  //   setDelivery(20)
  // }, [])

  // useEffect(() => {
  //   setDelivery(lookupsObj[city])
  // }, [lookupsObj, city])

  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const onChangeSizesRadio = (e) => setSizesRadio(e.target.value)
  const onChangeColoursRadio = (e) => setColoursRadio(e.target.value)

  useEffect(() => {
    let foundItem = basket.findIndex((item) => item.id === productDetails._id)
    if (foundItem !== -1) {
      setProductQty(basket[foundItem]['quantity'])
      setColor(basket[foundItem]['color'])
      setProductAdded(true)
    } else {
      setProductAdded(false)
    }
  }, [basket])

  const onHide = () => setShowModal(false)
  const showPopUp = () => setShowModal(true)

  return (
    <>
      <Helmet>
        <title>
          Shop {seoTitle.charAt(0) + seoTitle.substring(1).toLowerCase()} |
          Caspian Outlets
        </title>
        <meta
          name='title'
          content={`Shop ${
            seoTitle.charAt(0) + seoTitle.substring(1).toLowerCase()
          } | Caspian Outlets`}
        />
        <meta
          name='description'
          content={`Shop ${
            seoTitle.charAt(0) + seoTitle.substring(1).toLowerCase()
          } - Caspian outlets provide amazing deals and the best prices - free returns - cash on delivery." `}
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div className='product__details px-sm-3 px-lg-0'>
        <div className='row mx-sm-0 product__details__all'>
          <div className='col-xl-8'>
            <div className='row'>
              <div className='col-xl-6 product__details__images'>
                <img
                  src={productDetails.imageUrl}
                  alt={seoTitle}
                  className='text-lowercase'
                />
              </div>
              <div className='col-xl-6 mt-2 product__details__specs'>
                <h2 className='prod_title'>
                  {seoTitle.charAt(0) + seoTitle.substring(1).toLowerCase()}
                </h2>
                {!productDetails.brandName === 'Other' && (
                  <div className='brand'>
                    <p className='m-0'>
                      Brand:
                      <Link to='/' className='ms-1'>
                        {productDetails.brandName}
                      </Link>{' '}
                      |
                      <Link to='/' className='ms-1'>
                        {' '}
                        Similar products from {productDetails.brandName}
                      </Link>
                    </p>
                  </div>
                )}
                <div>
                  <Rating
                    name='half-rating-read'
                    value={4.0}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className='product__details__specs__others'>
                  <div className='d-flex d-lg-block'>
                    {productDetails.discount > 0 && (
                      <strong className='d-block danger ms-3 ms-lg-0'>
                        <span>
                          <span>GH</span>&#8373;
                        </span>
                        {discountPrice}
                      </strong>
                    )}
                    {productDetails.discount*1 > 0 && <strong className='d-block text-danger text-decoration-line-through' style={{fontSize: '13px', fontStyle: 'italic' }}>
                    <span className='text-danger'><span>GH</span>&#8373; </span>{productDetails.regularPrice.$numberDecimal} </strong>}
                
                  </div>
                  {String(productDetails.available) === 'true' && (
                    <strong className='danger in_stock pt-1'>
                      {'Available'}
                    </strong>
                  )}
                  <br></br>
                  <div
                    className='product_descrp mb-0'
                    dangerouslySetInnerHTML={{
                      __html: productDetails.discription
                    }}
                  ></div>
                  {productAdded && (
                    <SelectMenu
                      id={productDetails._id}
                      price={productDetails.regularPrice.$numberDecimal}
                      basePrice={productDetails.regularPrice.$numberDecimal}
                      prod_quantity={productQty}
                      setQty={setProductQty}
                    />
                  )}
                  {productDetails.colors.length > 0 && (
                    <div>
                      <FormControl
                        size='small'
                        sx={{ minWidth: 120, marginTop: 2 }}
                      >
                        <InputLabel id='demo-select-small-label'>
                          Colors
                        </InputLabel>
                        <Select
                          labelId='demo-select-small-label'
                          id='demo-select-small'
                          label='Colors'
                          value={color}
                          onChange={handleColorChange}
                        >
                          {productDetails.colors.map((color, idx) => (
                            <MenuItem key={idx} value={color}>
                              {color}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {/* <RadioGroupComponent groupLabel={'Sizes: '} data={sizesData} onChange={onChangeSizesRadio} /> */}
                  {/* <RadioGroupComponent groupLabel={'Colours: '} data={coloursData} onChange={onChangeColoursRadio} /> */}
                  {String(productDetails.available) === 'true' ? (
                    <>
                      <div className='purchase__buttons mt-2'>
                        {productAdded !== true ? (
                          <button className='mt-2 add__cat' onClick={addBasket}>
                            Add to cart
                          </button>
                        ) : (
                          // <p className='text-success'>Added to cart!</p>
                          <button className='mt-2 view__cat' onClick={viewCart}>
                            View Cart
                          </button>
                        )}
                        <button className='buy__now mt-2' onClick={buyNow}>
                          Buy now
                        </button>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <p className='text-danger m-0 text-center fs-5 fw-bold'>
                      Out of stock
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='row'>
              <div className='col-sm col-xl product__details__purchase'>
                <div className='price__column pt-4'>
                  {/* <div><h5 className='fw-bold'>Delivery Locations</h5></div>
                          <div><p className='mb-3'>Choose your delivery location</p></div>
                          <form>
                            <div className='region'>
                              <select onChange={onChangeRegion} defaultValue={region}>
                                {addressLocation.map(loc=>
                                  <option key={loc.region} value={loc.region} label={loc.region} />
                                )}
                              </select>
                            </div>
                            <div className='city'>
                              <select onChange={e=>setCity(e.target.value)}  defaultValue={[city, 'Axim']}>
                                
                                {locLookups.length>0?
                                  <>
                                    {locLookups.map(loc=>
                                      <option key={loc} label={loc} value={loc}/>
                                    )}
                                  </>:
                                  
                                  <>
                                      <option label={city} value={city}/>
                                  </>
                                }
                              </select>
                            </div>
                          </form> */}
                  <div className='row mb-2'>
                    <div className='col-4 px-1'>
                      <h6 className='fw-bold mb-0' style={{ fontSize: '14px' }}>
                        Shipping
                      </h6>
                    </div>
                    <div className='col-8 px-1'>
                      <strong className='cas_p'>
                        <small>
                          We offer standard and same day delivery options at our
                          checkout!
                        </small>
                        {/* <br/>
                                <small style={{color:'gray', fontWeight:'normal'}}>Note: The shipping options varies due to the location you choose.</small> */}
                      </strong>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-4 px-1'>
                      <h6 className='fw-bold mb-0' style={{ fontSize: '14px' }}>
                        Visit our Outlets
                      </h6>
                    </div>
                    <div className='col-8 px-1'>
                      <strong className='fw-normal cas_p'>
                        <small>
                          Items are ready for pickup at any of our outlets
                          (Atua, Akuse Juction, Atimpoku).
                        </small>
                        {/* <small style={{color:'gray', fontSize:'13px'}}>Ready for delivery between <strong className='text-dark'>{tomorrow.toDateString() + ' and '+ nextTwoDays.toDateString()}</strong>
                                when you order within the next 20 hours
                                </small> */}
                      </strong>
                      {/* <div</div>
                                 when you order within the next 20 hours</small> */}
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-4 px-1'>
                      <h6 className='fw-bold mb-0' style={{ fontSize: '14px' }}>
                        Delivery
                      </h6>
                    </div>
                    <div className='col-8 px-1'>
                      <strong className='fw-normal cas_p'>
                        <small style={{ color: 'gray', fontSize: '13px' }}>
                          Estimated between
                          <strong className='text-dark'>
                            {startDatex.slice(0, -4) +
                              ' and ' +
                              endDatex.slice(0, -4)}
                          </strong>
                          {isCurrentTimeBefore(17, 0) && (
                            <small>
                              when you order within the next
                              {isCurrentTimeBefore(12, 0)
                                ? 13 - new Date().getHours() + ' hour(s)'
                                : isCurrentTimeBefore(17, 0) &&
                                  17 - new Date().getHours() + ' hour(s)'}
                            </small>
                          )}
                        </small>
                      </strong>
                      {/* <div</div>
                                 when you order within the next 20 hours</small> */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4 px-1'>
                      <h6 className='fw-bold mb-0' style={{ fontSize: '14px' }}>
                        Return Policy
                      </h6>
                    </div>
                    <div className='col-8 px-1'>
                      <div>
                        <small
                          className='cas_p'
                          style={{ fontSize: '13px', letterSpacing: '0px' }}
                        >
                          Eligible for Return, Refund or Replacement within 10
                          days
                        </small>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=' p-0 pt-2'>
            <div className=''>
              <div>
                <div className='row m-0'>
                  <div className='col-12'>
                    <div className='row bg-white'>
                      <div className='col-12 col-sm-3 col-lg-2'>
                        <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>
                          Product Description
                        </h3>
                      </div>
                      {productDetails.discription !== '' && (
                        <div className='col-12 col-sm-9 col-lg-6 d-block'>
                          <div>
                            <strong></strong>
                          </div>
                          <p
                            className='f_product_descrp'
                            dangerouslySetInnerHTML={{
                              __html: productDetails.discription
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                    <div className='row bg-white my-2'>
                      <div className='col-sm-3 col-lg-2'>
                        <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>
                          Product Specifications
                        </h3>
                      </div>
                      <div className='row col-12 col-sm-9 col-lg-10'>
                        {productDetails.specLists && (
                          <div className='product__details__specs__info'>
                            <div className='product__details__specs__info__props'>
                              <Table responsive size='sm' borderless>
                                <tbody>
                                  {productDetails.specLists.map((spec, idx) => (
                                    <tr key={idx}>
                                      <td
                                        scope='row'
                                        className='spec-name fw-bold'
                                      >
                                        {spec.name}
                                      </td>
                                      <td>{spec.value}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                        {/* style={{backgroundColor: '#F0F2F2', color: '#565959'}} */}
                        {/* <div className='col-12 col-sm-6'>
                              <h5 style={{fontSize:'16px', fontWeight:'bold'}}>General</h5>
                              <ul style={{fontSize:'15px'}}>
                                <li >Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                              </ul>
                            </div>
                            <div className='col-12 col-sm-6'>
                              <h5 style={{fontSize:'16px', fontWeight:'bold'}}>Features</h5>
                              <ul style={{fontSize:'15px'}}>
                                <li >Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                                <li>Resolution: 1240px</li>
                              </ul>
                            </div> */}
                      </div>
                    </div>
                    <ProductReviews name={productDetails.productName} />
                  </div>
                  {/* <div className='col-12 col-sm-4 px-0 px-sm-3 mt-2'>
                        <div className='s-info ps-2 bg-white'>
                          <div><h3 style={{fontSize:'18px', fontWeight:'bold'}} className='border-bottom py-2 m-0'>Seller's Information</h3></div>
                          <div className='border-bottom py-2'>
                            <Link style={{fontSize:'15px'}}>Seller store name (no. products)</Link>
                            <span className='d-block' style={{fontSize:'14px'}}>90% positive feedback</span>
                          </div>
                          <div style={{borderBottomStyle:'dashed'}} className='pb-2'>
                            <div><h3 style={{fontSize:'18px', fontWeight:'bold'}} className='border-bottom py-2 m-0'>Seller's Performance</h3></div>
                            <div className='d-flex'><p className='m-0 me-2' style={{fontSize:'14px', color:'#000000c3'}}>Order Fullfilment Rate: </p> <span className='fw-bold' style={{fontSize:'13px'}}>Excellent</span></div>
                            <div className='d-flex'><p className='m-0 me-2' style={{fontSize:'14px', color:'#000000c3'}}>Quality Score: </p> <span className='fw-bold' style={{fontSize:'13px'}}>Excellent</span></div>
                            <div className='d-flex'><p className='m-0 me-2' style={{fontSize:'14px', color:'#000000c3'}}>Customer Rating: </p> <span className='fw-bold' style={{fontSize:'13px'}}>Good</span></div>
                          </div>
                          <div className='d-flex flex-column py-2'>
                            <Link to={'#'} style={{fontSize:'14px'}}>Contact Seller</Link>
                            <Link to={'#'} style={{fontSize:'14px'}}>Visit Store</Link>
                            <Link to={'#'} style={{fontSize:'14px'}}>See More Items</Link>
                          </div>
                        </div>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
          <ProductSuggestions
            title={'Similar Products'}
            searchParams={suggestion}
          />
          <RelatedProducts title={'Related Products'} />
        </div>
        {/* <PopUp
          show={showModal}
          onHide={onHide}
          login={login}
          shipping={shipping}
        /> */}
      </div>
    </>
  )
}

export default Product_details

// let shipping_details = [
//   name: '',
//   email:'',
//   phone:'',
//   order = [
//     id:'',
//     customer_name:'',
//     customer_phone:'',
//     customer_email:'',
//     order_items= [
//       id:'',
//       product_name:'',
//       price:'',
//       quantity:''
//     ]
//   ]
// ]
