import React, { useState } from "react";
import { useEffect } from "react";
import axios from '../utils/Axios';
import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";



const About = () => {
  const about = useLoaderData();

  return (
    
    <>
      <Helmet>
        <title>Caspian - {about.seoTitle}</title>
        <meta name="description" content={about.seoDescription} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div dangerouslySetInnerHTML={{__html: about.contents}} className="container"></div>
    </>
  )
};


export default About;