import React, { useState } from "react";
import Rating from '@mui/material/Rating';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from "react-bootstrap/Button";
import { useLocation, useParams } from "react-router-dom";
import axios from '../utils/Axios';
import { Helmet } from "react-helmet";
import GHsymbol from "../components/GHsymbol";


const ReturnRequest = () => {
  const location = useLocation();
  const product = location.state;
  const params = useParams();
  const [returnRequest, setReturnRequest] = useState({orderId: product.orderId, id: product.id, name: params.name, quantity: 1, reason: '', details: ''});
  const [refundMethod, setRefundMethod] = useState({outlet:'', method: 'drop off at caspian outlet'});
  const [refundPayment, setRefundPayment] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['refund form', 'refund payment method', 'refund method'];

  const returnReasons = [
    {id: 0, reason: 'Packaging was damaged'},
    {id: 1, reason: 'I changed my mind'},
    {id: 2, reason: 'Item received was broken'},
    {id: 3, reason: 'Item not received'},
    {id: 4, reason: 'Item stopped working well after usage'},
    {id: 5, reason: 'Item received with missing part or accessories'},
    {id: 6, reason: "Size is correct but doesn't fit as expected"},
  ];
  
  const outlets = [
    {id: 0, name: 'Akuse'},
    {id: 1, name: 'Atimpoku'},
    {id: 2, name: 'Kodwonya'},
    {id: 3, name: 'Doherrto'},
    {id: 4, name: 'Bawalasie'},
    {id: 5, name: 'Akwawonman'},
    {id: 6, name: 'Afienya'},
    {id: 7, name: 'Atua'},
  ]
  // const rateProduct = () => {
  //   axios.post(`/product/${product.name}/review`, rateProducts)
  //   .then(res => alert(res.data))
  //   .catch(err => console.log(err))
  // }

  const handleQuantity=e=>{
    let value = e.target.value*1;
    var numberRegex = /^\d+$/;
    if (numberRegex.test(value)){
      if (value===0){
        setReturnRequest({...returnRequest, quantity: 1});
        // console.log("val is 0", value)
      }else {
        setReturnRequest({...returnRequest, quantity: value});
      }
    }else {
      setReturnRequest({...returnRequest, quantity: 1});
    }
  };

  const handleChange = (e) => setReturnRequest({...returnRequest, reason: e.target.value})
  const handleChangeRefundMethod = (e) => setRefundMethod({...refundMethod, method: e.target.value});
  const handleChangeOutlets = (e) => setRefundMethod({...refundMethod, outlet: e.target.value});
  const handleChangePayment = e => setRefundPayment(e.target.value);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      // localStorage.setItem('v_re', prevActiveStep + 1)
      return prevActiveStep + 1
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      // localStorage.setItem('v_re', prevActiveStep - 1)
      return prevActiveStep - 1
    })
  }
  const handleReturnRequest = () => {
    const returnRequest_ = {...returnRequest};
    if (refundMethod.method === 'caspian to collect the product'){
      delete refundMethod.outlet;
    }
    returnRequest_['refundMethod'] = refundMethod;
    returnRequest_['refundPaymentMethod'] = refundPayment;

    console.log(returnRequest_);
  }

  return (
    <>
      <Helmet>
        <title>Return Request - {product.name.replace(/-/g, ' ')}</title>
          <meta name={`Return Request - ${product.name.replace(/-/g, ' ')}`}/>
          <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className="mb-3 my-md-3" >
        <div className="bg-white ps-2">
          <h2 className="p-1 py-2">Return a Request</h2> 
        </div>
        {/* {activeStep !== 0 && ( */}
          <Stepper activeStep={activeStep} className='mb-3'>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        {/* )} */}
        {activeStep === 0 &&<> <div className="d-flex align-items-center mb-3 bg-white">
          <img src={product.img} height={100} alt="product"/>
          <div className="ms-4">
            <h6 className="fw-bold">{product.name}</h6>
            <p><GHsymbol /> {product.price}</p>
          </div>
        </div>
        <div className="mt-4"> 
          <h5 className="border-bottom pb-1 fs-6 fw-bold">Fill the form for your return request</h5>
          <div>
            <div className="d-flex mb-3 p-3 bg-white border rounded">
              <FormControl sx={{ marginTop:1, minWidth: 100 }} size="small">
                {returnRequest.quantity < 10 &&
                  <>
                    <InputLabel id="demo-select-small-label">Quantity</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label='Quantity'
                      value={returnRequest.quantity}
                      onChange={handleQuantity}
                      size='small'
                      sx={{height:'40px'}}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      {product.quantity >= 10 && <MenuItem value={10}>10+</MenuItem>}
                    </Select>
                  </>
                }
                {product.quantity >= 10 &&
                  <>
                    {returnRequest.quantity >= 10 &&
                      <>
                        <TextField type='number' size='small' onChange={handleQuantity} value={returnRequest.quantity} sx={{width: '100px'}} />
                        <small style={{fontSize:'13px', color:'gray'}}>Enter quantity</small>
                      </>
                    }
                  </>
                }
              </FormControl>
              <FormControl sx={{ marginTop:1, width: '100%' }} className="ms-1 ms-sm-4" size="small">
                <InputLabel id="demo-select-small-label">Select reason</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label='Select reason'
                  value={returnRequest.reason}
                  onChange={handleChange}
                  size='small'
                  sx={{height:'40px'}}
                >
                  {returnReasons.map(reason => <MenuItem key={reason.id} value={reason.reason}>{reason.reason}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <FloatingLabel controlId="floatingTextarea" label="More Information" className="mb-3" style={{height: '250px'}}>
              <Form.Control as="textarea" placeholder="Tell us more about your rating!" maxLength={100} value={returnRequest.details} onChange={e => setReturnRequest({...returnRequest, details: e.target.value})} style={{height: '250px'}}/>
            </FloatingLabel>
          </div>
        </div></>}
        {activeStep === 1 &&
          <div className="bg-white p-3" style={{minHeight: '300px'}}>
            <div className="border p-2 ">
              <h2 className="border-bottom pb-1">How would you want to get your refund</h2>
              <form>
              <div className="payment__type__select d-flex w-100">
                <div className='input_con'>
                  <input type="radio" name="payment" id="paystack" checked={refundPayment==='mobile_money' ? true : false} value={'mobile_money'} onChange={handleChangePayment} />
                </div>
                <div className="payment__type__details p-1" style={{color:'black'}}>
                  <h6>Mobile Money</h6>
                  <small style={{color:'gray'}}>You'll recieve your funds in your mobile money.</small>
                </div>
              </div>
              <hr/>
              <div className="payment__type__select d-flex w-100">
                <div className='input_con'>
                  <input type="radio" name="payment" id="paystack" checked={refundPayment==='cash' ? true : false} value={'cash'} onChange={handleChangePayment}/>
                </div>
                <div className="payment__type__details p-1" style={{color:'black'}}>
                  <h6>Cash</h6>
                  <small style={{color:'gray'}}>You should receive your refund at any of our outlets.</small>
                </div>
              </div>
              </form>
            </div>
          </div>
        }
        {activeStep === 2 &&
          <div className="bg-white p-3" style={{minHeight: '300px'}}>
            <div className="border p-2 ">
              {/* <div className="">
                <h2 className="border-bottom py-2">How would you want to return the product?</h2>
                <form>
                <div className="payment__type__select d-flex w-100">
                  <div className='input_con'>
                    <input type="radio" name="payment" id="paystack" checked={refundMethod.method==='caspian to collect the product' ? true : false} value={'caspian to collect the product'} onChange={handleChangeRefundMethod} />
                  </div>
                  <div className="payment__type__details p-1" style={{color:'black'}}>
                    <h6>I want caspian to collect product</h6>
                    <small style={{color:'gray'}}>A caspian agent will come to the address displayed below to collect the item.</small>
                  </div>
                </div>
                </form>
              </div>
              <hr /> */}
              <div>
                <form>
                <div className="payment__type__select d-flex w-100">
                  <div className='input_con'>
                    <input type="radio" name="payment" id="paystack" checked={refundMethod.method==='drop off at caspian outlet' ? true : false} value={'drop off at caspian outlet'} onChange={handleChangeRefundMethod} />
                  </div>
                  <div className="payment__type__details p-1" style={{color:'black'}}>
                    <h6>I want to drop off at one of your outlets</h6>
                    <small style={{color:'gray'}}>Choose your preferred outlet you want to drop off the product.</small>
                    <FormControl sx={{ marginTop:1, width: '100%' }} className="" size="small">
                      <InputLabel id="demo-select-small-label">Select outlet</InputLabel>
                      <Select labelId="demo-select-small-label" id="demo-select-small" label='Select outlet' value={refundMethod.outlet}
                        onChange={handleChangeOutlets} size='small' sx={{height:'40px'}} >
                        {outlets.map(outlet => <MenuItem key={outlet.id} value={outlet.name}>{outlet.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        }
        <div className="d-flex justify-content-evenly w-100 mt-3">
          {activeStep > 0 &&<Button className="" variant="outline-primary" onClick={handleBack}>Back</Button>}
          <Button className={activeStep < 1 && 'w-100'} variant="info" onClick={activeStep === steps.length - 1 ? handleReturnRequest : handleNext}>{activeStep === steps.length - 1 ? 'Submit' : 'Next'}</Button>
        </div>
      </div>
    </>
  )
};



export default ReturnRequest;