import { json } from 'react-router-dom';
import axios from '../utils/Axios';
import EventEmitter from '../utils/eventEmitter';



export const loadProduct = async(productSeo) => {
    const response = await axios.get(`/products/${productSeo}`)
    .catch(err=>{
        throw json({message: 'The product could not be found!', status:'Oops!'})})
    if (response.data.length === 0)throw json({message: 'This page could not be found!', status:'Oops!'})
    // console.log(response.data)
    return await response.data;
}

export const loadOrders = async(id) => {
    const response = await axios.get(`/${'1234-5678'}/orders`)
    .catch(err=>{
        throw new Error('Could not fetch data!\nPlease check your network connectivity!')})
    if (response.data.length === 0)throw json({message: 'This page could not be found!', status:'Oops!'})
    return await response.data;
}


export const loadSearchProduct = async(name) => {
    const response = await axios.get(`searchproducts/${name}`)
    .catch(err=>{
        throw new Error('Could not fetch data!\nPlease check your network connectivity!')})
    if (response.data.length === 0)throw json({message: 'Search product could not be found!', status:'Oops!'})
    return await response.data;
}