export const initialState = {
  basket: [],
  user: null
}

export const getBasketTotal = (basket) => basket?.reduce((amount, item) => (item.price + amount) , 0)
  //   let discountPrice
  //   if (item.discount && item.discount > 0) {
  //     discountPrice = (
  //       ((100 - parseInt(item.discount)) / 100) *
  //       item.price
  //     ).toFixed(2)
  //     return discountPrice * 1 + amount
  //   }
  //   return item.price * 1 + amount
  // }, 0)

const reducer = (state, action) => {
  switch (action.type) {

    case 'LOAD_BASKET':
      return { ...state, basket: [...action.items] }

    case 'ADD_TO_BASKET':
      // localStorage.setItem('basket',JSON.stringify([...state.basket, action.item]))
      return { ...state, basket: [...state.basket, action.item] }

    case 'TESTING':
      console.log(action.message)
      return;

    case 'REMOVE_FROM_BASKET':
      let newBasket = [...state.basket]
      const index = state.basket.findIndex(
        (basketItem) => basketItem.id === action.id
      )

      if (index >= 0) {
        newBasket.splice(index, 1);
        // if (newBasket.length === 0) {
        //   localStorage.removeItem('basket')
        // } else {
        //   localStorage.setItem('basket', JSON.stringify([...newBasket]))
        // }
      } else {
        alert(`can't remove product (id: ${action.id}) as its not in basket`)
      }
      return { ...state, basket: newBasket }

    case 'CLEAR_USER_BASKET':
      state.basket = []
      // localStorage.removeItem('basket')
      return { ...state, basket: state.basket }

    case 'INCREASE_QUANTITY':
      let basket_ = [...state.basket]
      // console.log(basket_)
      const foundItem = state.basket.findIndex(
        (item) => item.id === action.item.id
      )
      if (foundItem !== -1) {
        basket_[foundItem]['quantity'] = action.item.quantity
        basket_[foundItem]['price'] = action.item.price
        state.basket[foundItem] = basket_[foundItem]
        // localStorage.setItem('basket', JSON.stringify(state.basket))
        return { ...state, basket: state.basket }
      }

    case 'DECREASE_QUANTITY':
      let basket__ = [...state.basket]
      const foundItem_ = state.basket.findIndex(
        (item) => item.id === action.item.id
      )
      if (foundItem_ !== -1) {
        basket__[foundItem_]['quantity'] = action.item.quantity
        basket__[foundItem_]['price'] = action.item.price
        state.basket[foundItem_] = basket__[foundItem_]
        // localStorage.setItem('basket', JSON.stringify(state.basket))
        return { ...state, basket: state.basket }
      }

    case 'UPDATE_COLOR': 
      let basket___ = [...state.basket];
      const foundItem__ = state.basket.findIndex(item => item.id === action.item.id);
      if (foundItem__ !== -1) {
        basket___[foundItem__].color = action.item.color;
        state.basket[foundItem__] = basket___[foundItem__];
        // localStorage.setItem('basket', JSON.stringify(state.basket));
        return { ...state, basket: state.basket }
      }

    default:
      return state
  }
}

export default reducer
