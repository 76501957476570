import React, { useState } from "react";
import orday from '../images/orday.svg';
import axios from '../utils/Axios';


const VendorRegistrationPage = () => {
  const [vendorInfo, setVendorInfo] = useState({firstname:'', lastname:'', email:'', password:''});
  const [confirmPassword, setConfirmPassword] = useState('');

  const signup_vendor = async(e) => {
    e.preventDefault();
    try {
      await axios.post('vendor/add', vendorInfo)
      alert('created successfully!')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div style={{ maxWidth: '1224px'}} className="container m-0 min-vh-100 d-flex justify-content-center align-items-center">
      {/* <div className="container" style={{maxWidth:'1024px'}}> */}
        <div className="d-flex align-items-center justify-content-center justify-content-lg-around h-100 gap-md-2">
          <div className="d-none d-md-flex bg-white h-100 w-50"><img src={orday} height={'100%'} width={'100%'} alt="store icon"/></div>
          <div className="auth_form_wrapper bg-white p-2"> 
            <h3>Sell on Caspian</h3>
            <form onSubmit={signup_vendor} className=''>
            {/* <div className='signup_heading_container mb-5'>
              <h1 className='signup_heading'>Sell on Caspian</h1>
              <p className='signup_text'>Sign In</p>
            </div> */}
            <div className='field login input'>
              <label>Firstname <span style={{color:'red'}}>*</span></label>
              <input name='telnumber' className='w-100' value={vendorInfo.firstname} onChange={(e) => setVendorInfo({...vendorInfo, firstname: e.target.value})} type='text' />
            </div>
            <div className='field login input'>
              <label>Surname <span style={{color:'red'}}>*</span></label>
              <input name='telnumber' className='w-100' value={vendorInfo.lastname} onChange={(e) => setVendorInfo({...vendorInfo, lastname: e.target.value})} type='text' />
            </div>
            <div className='field login input'>
              <label>Email <span style={{color:'red'}}>*</span></label>
              <input name='telnumber' className='w-100' value={vendorInfo.email} onChange={(e) => setVendorInfo({...vendorInfo, email: e.target.value})} type='text' />
            </div>
            <div className='field login input'>
              <label>Password <span style={{color:'red'}}>*</span></label>
              <input name='password' className='w-100' value={vendorInfo.password} onChange={(e) => setVendorInfo({...vendorInfo, password: e.target.value})} type='password' />
            </div>
            <div className='field login input'>
              <label>Confirm Password <span style={{color:'red'}}>*</span></label>
              <input name='password' className='w-100' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' />
            </div>
              <button className="btn btn-secondary submitbtn w-100" type="submit">Sign Up</button>
            </form>
          </div>
        </div>
    </div>
  )
};


export default VendorRegistrationPage;