import React, { useContext } from 'react'
import '../css/Subtotal.css'
import { useStateValue } from '../utils/StateProvider'
import { getBasketTotal } from '../utils/Reducer'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import useWindowDimensions from '../utils/useWindowDimension'
import CallIcon from '@mui/icons-material/Call'
import AuthContext from '../utils/AuthLoggedIn'
import GHsymbol from './GHsymbol'
import PopUp from '../unused_components/PopUp'

function Subtotal({ show, onHide, setModalShow, login, shipping }) {
  const [{ basket }, dispatch] = useStateValue()
  const { height, width } = useWindowDimensions()
  const navigate = useNavigate()
  // const { loggedIn } = useContext(AuthContext);
  async function fillPaymemtDetails() {
    if (basket.length !== 0) {
      shipping()
    } else {
      alert('Please, your basket is empty. shop and add to cart!')
    }
  }
  const totalAmount = getBasketTotal(basket) - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0).toFixed(2);
  return (
    <>
      <div className='subtotal'>
        <div className='subtotal__title'>CART SUMMARY </div>

        <div className='subtotal__price'>
          <div>Subtotal</div>
          <div>
            <strong>
              <GHsymbol /> {(totalAmount * 1).toFixed(2)}
            </strong>
          </div>
        </div>
      </div>
      {width > 600 ? (
        <>
          <div className='d-flex d-md-block'>
            <div className='callBtn h-100 align-self-center d-md-none'>
              <a href='tel:0593152806' style={{ outline: 'none', padding: 0 }}>
                <CallIcon />
              </a>
            </div>
            <div className='d-flex justify-content-end w-100'>
              <button onClick={fillPaymemtDetails} className='checkoutbtn'>
                {' '}
                CHECKOUT{' '}
              </button>
            </div>
          </div>
          {!window.location.pathname.includes('checkout') && (
            <div className='d-flex justify-content-end px-3'>
              <Button
                variant='info'
                onClick={() => navigate('/store')}
                className='p-1'
              >
                Continue shopping
              </Button>
            </div>
          )}
          {/* <PopUp 
              show={show}
              onHide={onHide}
              login={login}
              shipping={shipping}
            /> */}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Subtotal
