import { json } from 'react-router-dom';
import axios from '../utils/Axios';

// /orders/:id/details
export const loadOrderDetails = async(orderNo) => {
    const response = await axios.get(`orders/${orderNo}/details`)
    .catch(err=>{
        throw new Error('Could not fetch data!\nPlease check your network connectivity!')})
    if (response.data.length === 0)throw json({message: 'Could not found order details!', status:'Oops!'})
    const data = await response.data;
    data.orderItems.forEach(order => order['cart'] = false);
    return data;
}   