import React from "react";
import { Link } from "react-router-dom";



const ProductSquareChildComponent=({ title, search, category, image})=>{
  return (
    <div className="p-1">
      <div className="product_square__item">
      <Link to={`/category/${category.replace(/ /g, '-')}/${search.replace(/ /g, '-')}`}>
        <img src={image} alt={search}  className="img_square"/>
      </Link>
      <div className="p_cont"><p>{search}</p></div>
    </div>
  </div>
  )
}


export default ProductSquareChildComponent;