    import { useState } from "react";
    import { Routes, BrowserRouter as Router, Route, BrowserRouter, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
    import Topbar from "../scenes/global/Topbar";
    import Sidebar from "../scenes/global/Sidebar";
    import Dashboard from "../scenes/dashboard";
    import Team from "../scenes/team";
    import Invoices from "../scenes/invoices";
    import Contacts from "../scenes/contacts";
    import Bar from "../scenes/bar";
    import Form from "../scenes/form";
    import Line from "../scenes/line";
    import Pie from "../scenes/pie";
    import FAQ from "../scenes/faq";
    import Geography from "../scenes/geography";
    import { CssBaseline, ThemeProvider } from "@mui/material";
    import { ColorModeContext, useMode } from "../utils/theme";
    import Calendar from "../scenes/calendar/calendar";
    import "../css/AdminRoute.css";
    import PostProductComponenet from "./PostProductComponenet";
    import ProductList from "../scenes/products/productlist";
import Orders from "../scenes/Orders";
import AddImage from "../scenes/form/addproduct";
import AdminLayout from "../layouts/AdminLayout";

    // function AdminRoute() {
    //   const [theme, colorMode] = useMode();
    //   const [isSidebar, setIsSidebar] = useState(true);

    //   return (
        
        
    //     <ColorModeContext.Provider value={colorMode}>
    //       <ThemeProvider theme={theme}>
    //         <CssBaseline />
    //         <div className="admin__app">
    //           <Sidebar isSidebar={isSidebar} />
    //           <main className="content">
    //             <Topbar setIsSidebar={setIsSidebar} />
    //             <Routes>
    //               <Route path="/orders" element={<Orders />} />
    //               <Route path="/team" element={<Team />} />
    //               <Route path="/contacts" element={<Contacts />} />
    //               <Route path="/productlist" element={<ProductList />} /> 
    //               <Route path="/invoices" element={<Invoices />} />
    //               <Route path="/form" element={<Form />} /> 
    //               <Route path="/addimage" element={<AddImage />} /> 
    //               <Route path="/bar" element={<Bar />} /> 
    //               <Route path="/line" element={<Line />} /> 
    //               <Route path="/pie" element={<Pie />} /> 
    //               <Route path="/geography" element={<Geography />} />
    //               <Route path="/" element={<Dashboard />} />
    //             </Routes>
    //           </main>
    //         </div>
    //       </ThemeProvider>
    //     </ColorModeContext.Provider>
        
    //   );
    // }

    // export default AdminRoute;



const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AdminLayout />}>
      <Route index element={<Dashboard />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/productlist" element={<ProductList />} /> 
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/form" element={<Form />} /> 
        <Route path="/addimage" element={<AddImage />} /> 
        <Route path="/bar" element={<Bar />} /> 
        <Route path="/line" element={<Line />} /> 
        <Route path="/pie" element={<Pie />} /> 
        <Route path="/geography" element={<Geography />} />
      </Route>
    </>
));


export default adminRouter;