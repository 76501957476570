import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import axios from '../utils/Axios'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { getCookie } from '../utils/readCookie'
import { useNavigate } from 'react-router-dom'

const seller_info = {
  firstname: '',
  middlename: '',
  lastname: '',
  country_of_citizenship: '',
  country_of_birth: '',
  dob: '',
  residential_address: {
    country: '',
    zip_postal_code: '',
    address1: '',
    address2: '',
    city: '',
    region: ''
  },
  phone_no: '',
  identity_proof: '',
  country_of_issue: '',
  id_No: '',
  date_of_expiry: '',
  confirm_if_primary_contact_person: {
    a: 'is a beneficial owner of the business',
    b: 'is a legal representative of the business'
  },
  i_have_added_all_the_benificial_owners_of_the_business: 'is boolean type'
}

const business_info = {
  buisness_name: '',
  company_regis_no: '',
  business_address: {
    country: '',
    zip_postal_code: '',
    address1: '',
    address2: '',
    city: '',
    region: ''
  },
  //recieve pin through sms
  //captch type characters
  phone_no: '', //for verification
  sms_verify_lang: ''
}

const billing = {
  financial_institution_name: '',
  country: '',
  nine_digit_routing_no: '',
  bank_acc_no: '',
  reType_bank_acc_no: '',
  payment_info: { card_no: '', expires_on: '', card_holder_name: '' }
}
//gs1.org

const store = {
  store_name: '',
  universal_product_codes: 'type boolean',
  diversity_certs: 'type boolean', //Do you have any diversity certifications (e.g. Minority, Women, Veteran or LGBT-owned business) that you wish to add to your account?
  own_brand: 'type boolean, some of them', //Do you own a brand? Or do you serve as an agent or representative or manufacturer of a brand for any of the products you want to sell on Amazon?
  active_registered_trademark: 'type boolean' //Does your brand have an active registered trademark
}

const steps = ['Business information', 'Seller information', 'Billing']

const AddSeller = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)

  // const [shopDetails, setShopDetails] = useState({name:'', location:'', product:['']});
  // const [customerCare, setCustomerCare] = useState({supportName:'', phone_no:'', email:'', address:'', country:'', region:'', city:''});
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    company_regis_no: '',
    country: '',
    zip_postal_code: '',
    address1: '',
    city: '',
    region: '',
    phone_no: '',
    sms_verify_lang: ''
  })

  const [sellerInfo, setSellerInfo] = useState({
    firstname: '',
    surname: '',
    country_of_citizenship: '',
    country_of_birth: '',
    dob: '',
    country: '',
    address1: '',
    city: '',
    region: '',
    phone_no: '',
    identity_proof: '',
    country_of_issue: '',
    id_No: '',
    id_type: '',
    primary_contact_person: '',
    all_the_benificial_owners_of_the_business: false
  })
  const [payment, setPayment] = useState({ accountName: '', phone_no: '' })
  const [idFrontSide, setIdFrontSide] = useState(null)
  const [idBackside, setIdBackSide] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    const userId = getCookie('userId')
    const data = {
      sellerInfo: sellerInfo,
      businessInfo: businessInfo,
      payment: payment
    }
    const formData = new FormData()
    formData.append('id_images', idFrontSide)
    formData.append('id_images', idBackside)
    formData.append('data', JSON.stringify(data))
    axios
      .post(`/vendor/add_seller/${userId}`, formData)
      .then((res) => {
        alert('details submitted sucsessfully...')
        navigate('/')
      })
      .catch((err) => console.log(err))
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      localStorage.setItem('v_re', prevActiveStep + 1)
      return prevActiveStep + 1
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      localStorage.setItem('v_re', prevActiveStep - 1)
      return prevActiveStep - 1
    })
  }

  // setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleReset = () => {
    setActiveStep(0)
  }

  const trackRegistration = () => {
    localStorage.setItem('v_re', 1)
    setActiveStep(1)
  }

  useEffect(() => {
    const step = localStorage.getItem('v_re')
    if (step !== null) {
      setActiveStep(step * 1)
    }
  }, [])

  async function Logout() {
    await axios.get('/logout')
    window.location.href = '/';
  }

  return (
    <div className='h-50'>
      <div className='cas-sell-cen d-flex justify-content-between'>
        <h1>Caspian Seller Center</h1>
        <Button size='sm' variant='outline-danger' onClick={Logout}>Logout</Button>
      </div>
      {activeStep === 0 && (
        <div
          className='container d-flex flex-column align-items-center justify-content-center mb-4 h-100'
          style={{ maxWidth: '800px' }}
        >
          <div className='mb-4'>
            <div className='h-25'>
              <h4 className='fw-bold'>Welcome! Here's what to expect</h4>
            </div>
            <ol>
              <li className='fw-bold fs-6'>
                Provide your information and documentation
              </li>
              <span>
                To meet identity and verification requirements, we must collect
                appropriate personal and business information. We may need
                further information or documentation later.
              </span>
              <li className='fw-bold fs-6'>Get verified and start selling</li>
              <span>
                We will verify the required information as soon as possible
                after receiving it.
              </span>
            </ol>
          </div>
          <div className='w-100 mb-4'>
            <h4 className='fw-bold'>What you'll need:</h4>
            <ul>
              <li>Valid government issued ID or passport</li>
              <li>Mobile money account</li>
              <li>Mobile phone</li>
            </ul>
          </div>
          <div className='w-100 d-flex justify-content-center'>
            <Button className='w-50' onClick={trackRegistration}>
              Begin
            </Button>
          </div>
        </div>
      )}
      <div className='w-100 container-lg' style={{ maxWidth: '800px' }}>
        {activeStep !== 0 && (
          <Stepper activeStep={activeStep} className='mb-3'>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        )}
        {/* {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : ( */}
        <div style={{ maxWidth: '900px' }}>
          {activeStep === 1 && (
            <Form>
              <h3>Business Information</h3>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Business Name</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='bus_name'
                  value={businessInfo.name}
                  onChange={(e) =>
                    setBusinessInfo({ ...businessInfo, name: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Company Registration no</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='company_reg_no'
                  value={businessInfo.company_regis_no}
                  onChange={(e) =>
                    setBusinessInfo({
                      ...businessInfo,
                      company_regis_no: e.target.value
                    })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Country</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='country'
                  value={businessInfo.country}
                  onChange={(e) =>
                    setBusinessInfo({
                      ...businessInfo,
                      country: e.target.value
                    })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Address 1</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='address 1'
                  value={businessInfo.address1}
                  onChange={(e) =>
                    setBusinessInfo({
                      ...businessInfo,
                      address1: e.target.value
                    })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Region</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='region'
                  value={businessInfo.region}
                  onChange={(e) =>
                    setBusinessInfo({ ...businessInfo, region: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>City</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='city'
                  value={businessInfo.city}
                  onChange={(e) =>
                    setBusinessInfo({ ...businessInfo, city: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          )}
          {activeStep === 2 && (
            <Form>
              <h3>Seller Information</h3>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Firstname</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='bus_name'
                  value={sellerInfo.firstname}
                  onChange={(e) =>
                    setSellerInfo({ ...sellerInfo, firstname: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Lastname</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='company_reg_no'
                  value={sellerInfo.surname}
                  onChange={(e) =>
                    setSellerInfo({ ...sellerInfo, surname: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12}>
                <div className='row'>
                  <Form.Group as={Col} lg={4} className='mb-3'>
                    <Form.Label className='m-0'>
                      Country of citizenship
                    </Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='country'
                      value={sellerInfo.country_of_citizenship}
                      onChange={(e) =>
                        setSellerInfo({
                          ...sellerInfo,
                          country_of_citizenship: e.target.value
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={4} className='mb-3'>
                    <Form.Label className='m-0'>Country of Birth</Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='address 1'
                      value={sellerInfo.country_of_birth}
                      onChange={(e) =>
                        setSellerInfo({
                          ...sellerInfo,
                          country_of_birth: e.target.value
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={4} className='mb-3'>
                    <Form.Label className='m-0'>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      size='sm'
                      name='address 1'
                      value={sellerInfo.date_of_expiry}
                      onChange={(e) =>
                        setSellerInfo({
                          ...sellerInfo,
                          date_of_expiry: e.target.value
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Group as={Col} lg={12}>
                <div className='row'>
                  <h6>Residential Address</h6>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>Country</Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='region'
                      value={sellerInfo.country}
                      onChange={(e) =>
                        setSellerInfo({
                          ...sellerInfo,
                          country: e.target.value
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>Region</Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='region'
                      value={sellerInfo.region}
                      onChange={(e) =>
                        setSellerInfo({ ...sellerInfo, region: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>City</Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='city'
                      value={sellerInfo.city}
                      onChange={(e) =>
                        setSellerInfo({ ...sellerInfo, city: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>Address</Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='region'
                      value={sellerInfo.address1}
                      onChange={(e) =>
                        setSellerInfo({
                          ...sellerInfo,
                          address1: e.target.value
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={12}
                className='border-top border-bottom mb-3'
              >
                <div className='row'>
                  <h6>Proof of identity</h6>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>ID Type</Form.Label>
                    <Form.Select
                      size='sm'
                      name='id_type'
                      value={sellerInfo.id_type}
                      onChange={(e) =>
                        setSellerInfo({
                          ...sellerInfo,
                          id_type: e.target.value
                        })
                      }
                    >
                      <option selected value={'voter_id'} label='Voter ID' />
                      <option value={'driver_license'} label='Driver License' />
                      <option value={'ssnit'} label='SSNIT' />
                      <option value={'passport'} label='Passport' />
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>ID Number</Form.Label>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='id_number'
                      value={sellerInfo.id_No}
                      onChange={(e) =>
                        setSellerInfo({ ...sellerInfo, id_No: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>ID Image Frontside</Form.Label>
                    <Form.Control
                      type='file'
                      size='sm'
                      name='id_frontside'
                      onChange={(e) => setIdFrontSide(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6} className='mb-3'>
                    <Form.Label className='m-0'>ID Number Backside</Form.Label>
                    <Form.Control
                      type='file'
                      size='sm'
                      name='id_backside'
                      onChange={(e) => setIdBackSide(e.target.files[0])}
                    />
                  </Form.Group>
                </div>
              </Form.Group>
              <Form.Group as={Col} lg={12}>
                <Form.Group as={Col} lg={6} className='mb-3'>
                  <Form.Label className='m-0'>
                    Phone number for verification
                  </Form.Label>
                  <Form.Control
                    type='tel'
                    size='sm'
                    name='city'
                    value={sellerInfo.phone_no}
                    onChange={(e) =>
                      setSellerInfo({ ...sellerInfo, phone_no: e.target.value })
                    }
                  />
                </Form.Group>
              </Form.Group>
            </Form>
          )}
          {activeStep === 3 && (
            <Form>
              <h3>Billing</h3>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Account Name</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='name'
                  value={payment.accountName}
                  onChange={(e) =>
                    setPayment({ ...payment, accountName: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group as={Col} lg={12} className='mb-3'>
                <Form.Label className='m-0'>Number</Form.Label>
                <Form.Control
                  type='text'
                  size='sm'
                  name='number'
                  value={payment.phone_no}
                  onChange={(e) =>
                    setPayment({ ...payment, phone_no: e.target.value })
                  }
                />
              </Form.Group>
              {/* <Form.Group as={Col} lg={12}>
                  <Button size="sm" variant="outline-danger">Verify Account</Button>
                </Form.Group> */}
            </Form>
          )}
          {activeStep !== 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                pt: 2
              }}
            >
              <Button
                variant='secondary'
                disabled={activeStep === 0}
                onClick={handleBack}
                className='w-25'
              >
                Back
              </Button>
              <Button
                className='w-25'
                variant={activeStep === steps.length - 1 ? 'success' : 'info'}
                onClick={(e) =>
                  activeStep !== steps.length - 1
                    ? handleNext()
                    : handleSubmit(e)
                }
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          )}
        </div>
      </div>
      {/* <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={6}>
            <Form.Group as={Row} className="m-0 mb-3">
              <h3>Shop Details</h3>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Shop Name</Form.Label>
                <Form.Control type="text" size="sm" name="name" value={shopDetails.name} onChange={e=>setShopDetails({...shopDetails, name:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Shop Location</Form.Label>
                <Form.Control type="text" size="sm" name="location" value={shopDetails.location} onChange={e=>setShopDetails({...shopDetails, location:e.target.value})}/>
              </Form.Group>
            </Form.Group>

            <Form.Group as={Row} className="m-0 mb-3">
              <h3>Customer Care</h3>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Customer Support name</Form.Label>
                <Form.Control type="text" size="sm" name="supporName" value={customerCare.supportName} onChange={e=>setCustomerCare({...customerCare, supportName:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Phone Number</Form.Label>
                <Form.Control type="tel" size="sm" name="phone_no" value={customerCare.phone_no} onChange={e=>setCustomerCare({...customerCare, phone_no:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Email</Form.Label>
                <Form.Control type="email" size="sm" name="email" value={customerCare.email} onChange={e=>setCustomerCare({...customerCare, email:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Address</Form.Label>
                <Form.Control type="text" size="sm"  name="address" value={customerCare.address} onChange={e=>setCustomerCare({...customerCare, address:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={4} className="mb-3">
                <Form.Label className="m-0">Country</Form.Label>
                <Form.Select value={customerCare.country} onChange={e=>setCustomerCare({...customerCare, country:e.target.value})}>
                  <option>select country</option>
                  <option>Ghana</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} lg={4} className="mb-3">
                <Form.Label className="m-0">Region</Form.Label>
                <Form.Select value={customerCare.region} onChange={e=>setCustomerCare({...customerCare, region:e.target.value})}>
                  <option>select region</option>
                  <option>Eastern</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} lg={4} className="mb-3">
                <Form.Label className="m-0">City</Form.Label>
                <Form.Select value={customerCare.city} onChange={e=>setCustomerCare({...customerCare, city:e.target.value})}>
                  <option>select city</option>
                  <option>Somanya</option>
                </Form.Select>
              </Form.Group>
            </Form.Group>
          </Col>
        
          <Col lg={6}>
            <Form.Group as={Row} className="m-0 mb-3">
              <h3>Business Information</h3>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">Full Name</Form.Label>
                <Form.Control type="text" size="sm" name="name" value={businessInfo.fullName} onChange={e=>setBusinessInfo({...businessInfo, fullName:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={6} className="mb-3">
                <Form.Label className="m-0">ID Type</Form.Label>
                <Form.Control type="text" size="sm" name="id_type" value={businessInfo.idType} onChange={e=>setBusinessInfo({...businessInfo, idType:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={4} className="mb-3">
                <Form.Label className="m-0">ID Number</Form.Label>
                <Form.Control type="text" size="sm" name="id_number" value={businessInfo.idNumber} onChange={e=>setBusinessInfo({...businessInfo, idNumber:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={4} className="mb-3">
                <Form.Label className="m-0">ID Image Frontside</Form.Label>
                <Form.Control type="file" size="sm" name="id_frontside" onChange={e=>setIdFrontSide(e.target.files[0])}/>
              </Form.Group>
              <Form.Group as={Col} lg={4} className="mb-3">
                <Form.Label className="m-0">ID Number Backside</Form.Label>
                <Form.Control type="file" size="sm" name="id_backside"  onChange={e=>setIdBackSide(e.target.files[0])}/>
              </Form.Group>
            </Form.Group>

            <Form.Group as={Row} className="m-0 mb-3">
              <h3>Payment</h3>
              <Form.Group as={Col} lg={12} className="mb-3">
                <Form.Label className="m-0">Account Name</Form.Label>
                <Form.Control type="text" size="sm" name="name" value={payment.accountName} onChange={e=>setPayment({...payment, accountName:e.target.value})}/>
              </Form.Group>
              <Form.Group as={Col} lg={12} className="mb-3">
                <Form.Label className="m-0">Number</Form.Label>
                <Form.Control type="text" size="sm" name="number" value={payment.phone_no} onChange={e=>setPayment({...payment, phone_no:e.target.value})}/>
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="w-100 px-3 d-flex justify-content-end">
          <Button variant="success" type="submit">Submit</Button>
        </Form.Group>
      </Form> */}
    </div>
  )
}

export default AddSeller
