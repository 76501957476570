

function genUniqueNumbers(len){
    let result = '';
    let numbers = '0123456789';
    for (var i = 0; i < len; i++){
        result += numbers.charAt(Math.floor(Math.random()*numbers.length));
    }
    return result;
}

export default genUniqueNumbers;