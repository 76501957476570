import React from 'react'
import { Link } from "react-router-dom";
import "../css/MobileCategories.css";
import useWindowDimensions from '../utils/useWindowDimension';

function MobileCategories({ children }) {
    
    const { height, width } = useWindowDimensions();
    
    const title1 = "Rice Cookers";
    const title2 = "Kettles";
    const title4 = "AC";
    const title3 = "Televisions";
   const title = "Washing";
   if (width < 600) {
  return (
    <div className="mobile__cat">
        { children }
        {/* <div className="catgr__item">
        <div className="link__image ">
         <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: title1,
             }
         }}>
             <img src="/uploads/Banners/rrrrr-removebg-preview.jpg" alt="hello" className="catt__img "/>
             
         </Link>
         </div>
         <div className="title__cat ">{title1}</div>
         </div>
         <div className="catgr__item">
         <div className="link__image ">
         <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Air Conditioners",
             }
         }}>
             <img src="/uploads/Banners/accc-removebg-preview.jpg" alt="hello" className="catt__img "/>
             
         </Link>
         </div>
         <div className="title__cat ">{title4}</div>
         </div>
         <div className="catgr__item">
         <div className="link__image ">
         <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: title2,
             }
         }}>
             <img src="/uploads/Banners/kettle-removebg-preview.jpg" alt="hello" className="catt__img"/>
             
         </Link>
         </div>
         <div className="title__cat ">{title2}</div>
         </div>
         <div className="catgr__item">
         <div className="link__image ">
            
         <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Washing Machines",
             }
         }}>
             <img src="/uploads/Banners/wasrrr-removebg-preview.jpg" alt="hello" className="catt__img"/>
             
         </Link>
         </div>
         <div className="title__cat ">{title}</div>
         </div> */}
    </div>
  )
  }else{
    return (
        <></>
    )
  }
}

export default MobileCategories