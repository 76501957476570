import React from 'react'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import StorefrontIcon from '@mui/icons-material/Storefront'

const TestHeaderNavLinks = () => {
  return (
    <div className='nav_links h-12'>
      <Nav className='container-xxl'>
        <ul>
          <Nav.Item as='li' className='me-2'>
            <Nav.Link className='links' as={Link} to={'/store'}>
              <StorefrontIcon />
              <span>Store</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li'>
            <Nav.Link
              className='links flex items-center'
              as={Link}
              to={'/category/Electronics'}
            >
              <img
                src={'/uploads/tcl43.png'}
                alt='link'
                height={30}
                width={30}
              />
              <span className='font-semibold'>Electronics</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='me-2'>
            <Nav.Link
              className='links flex items-center'
              as={Link}
              to={'/category/Home,-Furniture-&-Appliances'}
            >
              <img
                src={'/uploads/SutaiKettle.png'}
                alt='link'
                height={30}
                width={30}
              />
              <span className='d-inline font-semibold'>Home & Appliances</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='me-2'>
            <Nav.Link
              className='links flex  items-center'
              as={Link}
              to={'/category/Construction'}
            >
              <img
                src={'/uploads/ConcreteBitt8mm(short).png'}
                alt='link'
                height={30}
                width={30}
              />
              <span className=' d-inline font-semibold'>
                Building Materials
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as='li' className='me-2'>
            <Nav.Link
              className='links flex  items-center'
              as={Link}
              to={'/category/Books'}
            >
              <img
                src={'/uploads/Animie.png'}
                alt='link'
                height={30}
                width={30}
              />
              <span className=' d-inline font-semibold'>Books</span>
            </Nav.Link>
          </Nav.Item>
        </ul>
      </Nav>
    </div>
  )
}

export default TestHeaderNavLinks
