import React, { useState, useEffect } from 'react'
import "../css/CategoryComponent.css";
import Product from './Product';
import axios from '../utils/Axios';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    slidesToSlide: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 600, min: 450 },
    items: 2,
    slidesToSlide: 2
  },
  small: {
    breakpoint: { max:450, min:0},
    items: 1,
    slidesToSlide: 1
  }
  
};

 function CarouselProductCategory({ clsname, children }) {

  return (
    <div className='cat__type_c'>
      <div className="category_component">
        <div className={`info ${clsname}`}>
          <div className="title">Top deals & Latest arrivals</div>
          <div className="show_link">show all</div>
        </div>
        <div className="c_home__row">
          <Carousel
            responsive={responsive}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            dotListClass="custom-dot-list-style">
            { children }
          </Carousel>       
        </div>
      </div>
    </div>
  )
}

export default CarouselProductCategory;






export const CategoryProducts=({ queryone, querytwo, querythree, queryfour })=>{
  const [prodChain, setProdChain] = useState([]);

  useEffect(() => {
    async function getChainProducts(){
        await axios.get(`/chainedproducts/${queryone}/${querytwo}/${querythree}/${queryfour}`).then((response) =>{
          const chainData = response.data;
          if (chainData) {
            setProdChain(chainData);
          }
          
        });
    }
    getChainProducts();
  }, [queryone, querytwo, querythree, queryfour]);

  return (
    <>
      {prodChain.map((prodchn) =>{
        return (
          <div key={prodchn.id} className="cproduct_item">
            <Product
              id={prodchn._id}
              title={prodchn.productName.charAt(0) + prodchn.productName.substring(1).toLowerCase()}
              price={prodchn.regularPrice}
              rating={5}
              image={`/uploads/${prodchn.fileName}`} />
          </div> 
          )}
        )
      }
    </>
  )
}
