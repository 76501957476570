import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
// import { GoogleOAuthProvider } from '@react-oauth/google'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { StateProvider } from './utils/StateProvider'
import reducer, { initialState } from './utils/Reducer'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-quill/dist/quill.snow.css'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
// import 'suneditor/assets/css/suneditor.css'
// import 'suneditor/assets/css/suneditor-contents.css'
// import 'bootstrap/dist/css/bootstrap.css';
import AdminRoute from './unused_components/AdminRoute'
import { AuthContextProvider } from './utils/AuthLoggedIn'
// import GoogleAuthProvider from './utils/GoogleLoggedIn'
import axios from './utils/Axios'
import Maintenance from './Maintenance'

axios.defaults.withCredentials = true

const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render (
//   <React.StrictMode>
//     <Maintenance />
//   </React.StrictMode>
// )


root.render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId='1010404100320-q2rjpvq7ub24ljgj9nh477c8257ieauf.apps.googleusercontent.com'>  */}
        {/* <GoogleAuthProvider> */}
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        {/* </GoogleAuthProvider> */}
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
