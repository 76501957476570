import React from "react";
import GroupComponent from "../components/GroupComponent";




const CategoryTypeComponent=({ categoryitem, title, fromHome })=>{
  return (
    <div className="cat__type">
      <GroupComponent categoryitem={categoryitem} title={title} fromHome={fromHome}/>
    </div>
  )
};


export default CategoryTypeComponent;