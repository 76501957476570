import React, { useContext, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
// import Topbar from "./scenes/global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../utils/theme";
import "../css/AdminRoute.css";
import Topbar from "../scenes/global/Topbar";
import Sidebar from "../vendors/scenes/global/Sidebar";
import MyDrawer from "../vendors/scenes/global/MyDrawer";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useWindowDimensions from "../utils/useWindowDimension";
import AuthContext, { useAuthContext } from "../utils/AuthLoggedIn";



const VendorsLayout = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { width } = useWindowDimensions();
  const { details, loginDetails } = useAuthContext();
  const navigate = useNavigate();

if (details.varified === false) {
      navigate('/ven/add-seller');
    }

  useEffect(() => {
    
    loginDetails();
    
  },[])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="admin__app">
          {width > 900 &&<Sidebar isSidebar={isSidebar} />}
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} loginDetails={loginDetails} />
            {/* <IconButton><MenuIcon /></IconButton> */}
            {width < 900 &&<MyDrawer />}
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
};


export default VendorsLayout;