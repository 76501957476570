import React, { useState, useEffect, createContext, useContext } from 'react'
import axios from './Axios'
import jwt_decode from 'jwt-decode'
import EventEmitter from './eventEmitter'
// import { googleLogout } from '@react-oauth/google'
// import { GoogleAuthContext } from './GoogleLoggedIn'

export const AuthContext = createContext({
  token: null,
  setToken: () => {}
})

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({ role: 'user' })

  useEffect(() => {
    axios
      .get('/getToken')
      .then((res) => {
        if (res.data.token !== undefined) {
          const token = res.data.token
          const decode = jwt_decode(token)
          // EventEmitter.dispatch('details', decode);
          setUser(decode)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <AuthContext.Provider value={{ token: user, setToken: setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  // const [loggedIn, setLoggedIn] = useState(undefined)
  const [details, setDetails] = useState({})
  const [loggedIn, setLoggedIn] = useState(false);
  // const googleCTX = useContext(GoogleAuthContext)
  const authCTX = useContext(AuthContext)
  // async function getLoggedIn() {
  //   const loggedInRes = await axios.get('/loggedIn')
  //   setLoggedIn(loggedInRes.data.loggedIn)
  //   if (loggedInRes.data.loggedIn) setData(loggedInRes.data.info);
  // }

  const loginDetails = () => {
    axios
      .get('/getToken')
      .then((res) => {
        if (res.data.token !== undefined) {
          const token = res.data.token
          const decode = jwt_decode(token)
          EventEmitter.dispatch('details', decode);
          setDetails(decode)
        }
      })
      .catch((err) => console.log(err))
  }

  const login = (loginData, setLoggingIn) => {
    setLoggedIn(true);
    axios.post('login', loginData)
    .then(res => {
      EventEmitter.dispatch('loggedIn', true);
      setLoggedIn(true);
      // if (res.data.varified !== undefined && res.data.varified === false){
      //   window.location.href = '/ven/add-seller';
      // }else {
        window.location.href = '/';
      // }
    })
    .catch(err => console.log(err))
    .finally(()=> setLoggingIn(false))
  }

  const logout = () => {
    authCTX.setToken({ role: 'user' })
    axios
      .get('logout')
      .then((res) => setLoggedIn(false))
      .catch((err) => console.log(err))

    setLoggedIn(false)
    EventEmitter.dispatch('loggedIn', false)
  }

  useEffect(() => {
    loginDetails()
  }, [loggedIn])

  return { details, loggedIn, login, logout, loginDetails }
}

export default AuthContext
