import React, { useState, useEffect } from "react";
import axios from "../utils/Axios";
import { Box, Divider, MenuItem, Typography, useTheme } from "@mui/material";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../utils/theme";
import Loader from "../components/Loader";
import LemMenu from "../components/LemMenu";



const Orders = () => {
    const [allprod, setAllOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(1);


    useEffect(() => {
      async function getChainProducts(){
        await axios.get(`/orders?page=${page}&&limit=${pageSize}`).then((response) =>{
          const chainData = response.data;
            // setAllOrders(chainData);
            setOrders(chainData);
            // setTotalPages(chainData.totalPages);
          // console.log(chainData)
        });
      }
      getChainProducts();
    }, [page, pageSize]);


    const orderStatus = (id, status) => {
      axios.patch(`orders/${id}/${status}`)
      .then(res => setOrders(res.data))
      .catch(err => console.log('oops! something has happened'));
    }

    const columns = [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "phone",
        headerName: "Phone Number",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "paymentchanel",
        headerName: "Payment Channel",
        flex: 1,
      },
      {
        field: "date",
        headerName: "Date",
        flex: 1,
      },
      {
        field: "Actions",
        renderCell: (params) => 
        <div {...params} className="w-100 d-flex align-items-center">
          <span className="me-3">View</span>
          {(params.row.status !=='delivered' && params.row.status !== 'cancelled') && <LemMenu className='d-block'>
            {params.row.status === 'order placed' && <MenuItem style={{height: '30px'}} onClick={()=>orderStatus(params.row.id, 'received')}>Received</MenuItem>}
            {params.row.status === 'received'&&<MenuItem style={{height: '30px'}} onClick={()=>orderStatus(params.row.id, 'processing')}>Processing</MenuItem>}
            {params.row.status === 'processing' &&<MenuItem style={{height: '30px'}} onClick={()=>orderStatus(params.row.id, 'shipped')}>Shipped</MenuItem>}
            {params.row.status === 'shipped' && <MenuItem style={{height: '30px'}} onClick={()=>orderStatus(params.row.id, 'delivered')}>Delivered</MenuItem>}
            <Divider />
            {params.row.status !=='delivered'&&<MenuItem style={{height: '30px', color: 'red'}} onClick={()=>orderStatus(params.row.id, 'cancel')}>Cancel Order</MenuItem>}
          </LemMenu>}
        </div>,
        headerName: 'Actions',
        // flex: 1,

      }
    ];

    const onPaginationModelChange = (model, details) => {
      if (model.page === 0){
        // setPaginationModel({...paginationModel, page:1, pageSize:model.pageSize});
        setPage(1);
        console.log('page is zero: ', model.page, details)
        setPageSize(model.pageSize);
      }else {
        // setPaginationModel({...paginationModel, page:model.page, pageSize:model.pageSize});
        setPage(model.page + 1);
        console.log('page is not zero: ', model, details)
        setPageSize(model.pageSize);
      }
    }

    return (
      <Box m="20px" height="82vh">
        <Box
          m="40px 0 0 0"
          height="85vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid 
            rows={orders} 
            columns={columns} 
            rowCount={orders.length}
            pagination
            initialState={{pagination:{paginationModel:{page:0}}}}
            paginationModel={{page:page-1, pageSize:pageSize}} 
            onPaginationModelChange={onPaginationModelChange}
            paginationMode="server"
          />
        </Box>
      </Box>
    );
}

export default Orders;