import React from "react";
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import PersonIcon from '@mui/icons-material/Person'
import { FaBox, FaUser } from 'react-icons/fa';
import { MdOutlineHistory } from 'react-icons/md';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Outlet } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { useStateValue } from "../utils/StateProvider";



const CustomerLayout = () => {
  const [{ basket }, dispatch] = useStateValue();
  return (
    <div className="container-md p-0 " style={{maxWidth: '1200px'}}>
      <div className="row m-0 w-100">
        {/* <div className="d-none d-md-block col-3">
          <div className="bg-white">
            <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Item href="/home" className="p-2 my-1 cs-hover">
                <FaBox size={'1.3rem'}className="me-2"/>
                <Nav.Link className="d-inline-block p-0 cs-hover" eventKey={'link-1'} as={Link} to={'/customer/orders'}>Orders</Nav.Link>
              </Nav.Item>
              <Nav.Item href="/home" className="p-2 my-1 cs-hover">
                <MdOutlineHistory size={'1.3rem'}className="me-2"/>
                <Nav.Link className="d-inline-block p-0 cs-hover" eventKey={'link-2'} as={Link} to={'/customer/recently-viewed'}>Recently viewed</Nav.Link>
              </Nav.Item>
              <Nav.Item href="/home" className="p-2 my-1 cs-hover">
                <FaUser size={'1.3rem'} className="me-2"/>
                <Nav.Link className="d-inline-block p-0 cs-hover" eventKey={'link-3'}>Account</Nav.Link>
              </Nav.Item>
              <Divider />
              <Nav.Item href="/home" className="text-center py-2">
                <Button className="w-75" variant="outline-danger">Logout</Button>
              </Nav.Item>
            </Nav>
          </div>
        </div> */}
        <div className="col-12 col-md-12 p-0 px-md-3">
          {/* {<div className="w-100 bg-white mb-2 d-md-none d-flex align-items-center">
            <IconButton><ArrowBackIcon fontSize="medium"/></IconButton>
            <h4 className="text-center w-75 m-0">Your Orders</h4>
          </div>} */}
          <Outlet context={{dispatch:dispatch, basket: basket}} />
        </div>
      </div>
    </div>
  )
};



export default CustomerLayout;