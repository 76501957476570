import React, { useEffect, useState } from 'react'
import ProductSqureComponent from '../components/ProductSqureComponent'
import AidsComponent from '../components/AidsComponent'
import useWindowDimensions from '../utils/useWindowDimension'
import ProductSquareChildComponent from '../components/ProductSquareChildComponent'
import Loader from '../components/Loader'
import axios from '../utils/Axios'

const responsive = {
  desktop: 1200, //greater than laptop
  laptop: 1024,
  tablet: 768,
  mobile: 480
}

export default function CategoryRendering({
  catTypeOne,
  catTypeTwo,
  catTypeThree,
  catTypeFour
}) {
  const { height, width } = useWindowDimensions()
  const [books, setBooks] = useState([])
  const [electronics, setElectronics] = useState([])
  const [constructions, setConstructions] = useState([])
  const [homeAppliances, setHomeAppliances] = useState([])

  const electronicsSubCategory = {
    sub1: 'Televisions',
    sub2: 'Accessories & Supplies for Electronics',
    sub3: 'Audio & Music Equipments',
    sub4: ''
  }
  const constructionSubCategory = {
    sub1: 'Iron rods',
    sub2: 'Cements',
    sub3: 'Blocks',
    sub4: 'Tools'
  }
  const homeSubCategory = {
    sub1: 'Home Appliances',
    sub2: 'Kitchen Appliances',
    sub3: 'Refrigerators',
    sub4: 'Air Conditioners'
  }
  const bookSubCategory = {
    sub1: 'Novels',
    sub2: 'Fiction',
    sub3: 'Children’s Books',
    sub4: 'Adventure'
  }

  useEffect(() => {
    let isMounted = true
    let random_ = []
    axios
      .post(`/api/cate/${catTypeOne}`, electronicsSubCategory)
      .then((response) => {
        const products = response.data
        if (isMounted) setElectronics(products)
      })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    let random_ = []
    axios
      .post(`/api/cate/${catTypeTwo}`, constructionSubCategory)
      .then((response) => {
        const products = response.data
        if (isMounted) setConstructions(products)
      })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    let random_ = []
    axios
      .post(`/api/cate/${catTypeThree}`, homeSubCategory)
      .then((response) => {
        const products = response.data
        if (isMounted) setHomeAppliances(products)
      })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    let random_ = []
    axios.post(`/api/cate/${catTypeFour}`, bookSubCategory).then((response) => {
      const products = response.data
      if (isMounted) setBooks(products)
    })
    return () => {
      isMounted = false
    }
  }, [])

  // id: prod._id,
  // pic: `${prod.imageUrl}`,
  // name: prod.productName.charAt(0) + prod.productName.substring(1).toLowerCase(),
  // weight:prod.shipping,
  // price: prod.regularPrice,
  // console.log(electronics)
  // console.log('mygate: ', catTypeOne)
  return (
    <div className='cate_ren pushup d-flex'>
      {width > responsive.desktop && (
        <>
          <ProductSqureComponent title={catTypeOne} searchGate={catTypeOne}>
            {electronics.length > 0 ? (
              <>
                {electronics.map((product) => (
                  <ProductSquareChildComponent
                    key={product._id}
                    category = {catTypeOne}
                    search={product['subCategory']}
                    title={
                      product.productName.charAt(0) +
                      product.productName.substring(1).toLowerCase()
                    }
                    image={`${product.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent
            title={'Building Materials'}
            searchGate={catTypeTwo}
          >
            {constructions.length > 0 ? (
              <>
                {constructions.map((construction) => (
                  <ProductSquareChildComponent
                    key={construction._id}
                    category = {catTypeTwo}
                    search={construction['subCategory']}
                    title={
                      construction.productName.charAt(0) +
                      construction.productName.substring(1).toLowerCase()
                    }
                    image={`${construction.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent title={'Appliances'} searchGate={catTypeThree}>
            {homeAppliances.length > 0 ? (
              <>
                {homeAppliances.map((homeAppliance) => (
                  <ProductSquareChildComponent
                    key={homeAppliance._id}
                    category = {catTypeThree}
                    search={homeAppliance['subCategory']}
                    title={
                      homeAppliance.productName.charAt(0) +
                      homeAppliance.productName.substring(1).toLowerCase()
                    }
                    image={`${homeAppliance.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent title={catTypeFour} searchGate={catTypeFour}>
            {books.length > 0 ? (
              <>
                {books.map((book) => (
                  <ProductSquareChildComponent
                    key={book._id}
                    category = {catTypeFour}
                    search={book['subCategory']}
                    title={
                      book.productName.charAt(0) +
                      book.productName.substring(1).toLowerCase()
                    }
                    image={`${book.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          {/* <AidsComponent /> */}
        </>
      )}
      {width > responsive.laptop && width <= responsive.desktop && (
        <>
          <ProductSqureComponent title={catTypeOne} searchCate={catTypeOne}>
            {electronics.length > 0 ? (
              <>
                {electronics.map((product) => (
                  <ProductSquareChildComponent
                    key={product._id}
                    category = {catTypeOne}
                    search={product['subCategory']}
                    title={
                      product.productName.charAt(0) +
                      product.productName.substring(1).toLowerCase()
                    }
                    image={`${product.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent
            title={'Building Materials'}
            searchGate={catTypeTwo}
          >
            {constructions.length > 0 ? (
              <>
                {constructions.map((construction) => (
                  <ProductSquareChildComponent
                    key={construction._id}
                    category = {catTypeTwo}
                    search={construction['subCategory']}
                    title={
                      construction.productName.charAt(0) +
                      construction.productName.substring(1).toLowerCase()
                    }
                    image={`${construction.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent title={'Appliances'} searchGate={catTypeThree}>
            {homeAppliances.length > 0 ? (
              <>
                {homeAppliances.map((homeAppliance) => (
                  <ProductSquareChildComponent
                    key={homeAppliance._id}
                    category = {catTypeThree}
                    search={homeAppliance['subCategory']}
                    title={
                      homeAppliance.productName.charAt(0) +
                      homeAppliance.productName.substring(1).toLowerCase()
                    }
                    image={`${homeAppliance.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent title={catTypeFour} searchGate={catTypeFour}>
            {books.length > 0 ? (
              <>
                {books.map((book) => (
                  <ProductSquareChildComponent
                    key={book._id}
                    category = {catTypeFour}
                    search={book['subCategory']}
                    title={
                      book.productName.charAt(0) +
                      book.productName.substring(1).toLowerCase()
                    }
                    image={`${book.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
        </>
      )}

      {width > responsive.tablet && width <= responsive.laptop && (
        <>
          <ProductSqureComponent title={catTypeOne} searchGate={catTypeOne}>
            {electronics.length > 0 ? (
              <>
                {electronics.map((product) => (
                  <ProductSquareChildComponent
                    key={product._id}
                    category = {catTypeOne}
                    search={product['subCategory']}
                    title={
                      product.productName.charAt(0) +
                      product.productName.substring(1).toLowerCase()
                    }
                    image={`${product.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent
            title={'Building Materials'}
            searchGate={catTypeTwo}
          >
            {constructions.length > 0 ? (
              <>
                {constructions.map((construction) => (
                  <ProductSquareChildComponent
                    key={construction._id}
                    category = {catTypeTwo}
                    search={construction['subCategory']}
                    title={
                      construction.productName.charAt(0) +
                      construction.productName.substring(1).toLowerCase()
                    }
                    image={`${construction.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent title={'Appliances'} searchGate={catTypeThree}>
            {homeAppliances.length > 0 ? (
              <>
                {homeAppliances.map((homeAppliance) => (
                  <ProductSquareChildComponent
                    key={homeAppliance._id}
                    category = {catTypeThree}
                    search={homeAppliance['subCategory']}
                    title={
                      homeAppliance.productName.charAt(0) +
                      homeAppliance.productName.substring(1).toLowerCase()
                    }
                    image={`${homeAppliance.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
        </>
      )}
      {width >= responsive.mobile && width <= responsive.tablet && (
        <>
          <ProductSqureComponent title={catTypeOne} searchGate={catTypeOne}>
            {electronics.length > 0 ? (
              <>
                {electronics.map((product) => (
                  <ProductSquareChildComponent
                    key={product._id}
                    category = {catTypeOne}
                    search={product['subCategory']}
                    title={
                      product.productName.charAt(0) +
                      product.productName.substring(1).toLowerCase()
                    }
                    image={`${product.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent
            title={'Building Materials'}
            searchGate={catTypeTwo}
          >
            {constructions.length > 0 ? (
              <>
                {constructions.map((construction) => (
                  <ProductSquareChildComponent
                    key={construction._id}
                    category = {catTypeTwo}
                    search={construction['subCategory']}
                    title={
                      construction.productName.charAt(0) +
                      construction.productName.substring(1).toLowerCase()
                    }
                    image={`${construction.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          {/* <AidsComponent /> */}
        </>
      )}
      {width < responsive.mobile && (
        <>
          <ProductSqureComponent title={'Appliances'} searchGate={catTypeThree}>
            {homeAppliances.length > 0 ? (
              <>
                {homeAppliances.map((homeAppliance) => (
                  <ProductSquareChildComponent
                    key={homeAppliance._id}
                    category = {catTypeThree}
                    search={homeAppliance['subCategory']}
                    title={
                      homeAppliance.productName.charAt(0) +
                      homeAppliance.productName.substring(1).toLowerCase()
                    }
                    image={`${homeAppliance.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
          <ProductSqureComponent
            title={'Building Materials'}
            searchGate={catTypeTwo}
          >
            {constructions.length > 0 ? (
              <>
                {constructions.map((construction) => (
                  <ProductSquareChildComponent
                    key={construction._id}
                    category = {catTypeTwo}
                    search={construction['subCategory']}
                    title={
                      construction.productName.charAt(0) +
                      construction.productName.substring(1).toLowerCase()
                    }
                    image={`${construction.imageUrl}`}
                  />
                ))}
              </>
            ) : (
              <Loader />
            )}
          </ProductSqureComponent>
        </>
      )}
    </div>
  )
}
