import React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import SunEditor, { buttonList } from 'suneditor-react';
import plugins from 'suneditor/src/plugins'
import { useState } from "react";
import axios from '../../utils/Axios';
import { contentPublishAlert, updateContentAlert } from "../alerts";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const options = {
  plugins: plugins,
  buttonList: buttonList.complex
}

const label = { inputProps: { 'aria-label': 'publish checkbox' } };

const AddContent = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [contents, setContent] = useState('');
  const location = useLocation();
  const id = location.state || '';
  const [published, setPublished] = useState(true);
  const [seo, setSeo] = useState({ page_name: '', page_title: '', description: '', display_order: 1 });

  const handleBeforeUpload = (files, info, uploadHandler) => {
    console.log(files, info); 
    uploadHandler(files);
  };
  // console.log(seo, published)
  const publish_contents = (swalWithBootstrapButtons) => {
    let seo_ = {...seo};
    seo_['page_name'] = seo_['page_name'].replace(/ /g, '-');
    axios.post('content-management', {contents, seo_, published})
    .then(res =>swalWithBootstrapButtons.fire('Published!', res.data.message, 'success'))
    .catch(err => swalWithBootstrapButtons.fire('Error!', err.response.data.message, 'error'))
  }
  useEffect(() => {
    if (id !== '') {
      axios.get('/content-management/edit/'+id)
      .then(res => {
        let data = res.data;
        console.log(data)
        setContent(data.contents);
        setSeo({...seo, page_name: data.seoPageName, page_title: data.seoTitle, description: data.seoDescription, display_order: data.display_order})
        setPublished(data.published);
      })
      .catch(err => console.log(err))
    }
  },[id]) 

  const update_content = (swalWithBootstrapButtons) => {
    let seo_ = {...seo};
    seo_['page_name'] = seo_['page_name'].replace(/ /g, '-');
    axios.put('content-management', {contents, seo_, id, published})
    .then(res =>swalWithBootstrapButtons.fire('Updated!', res.data.message, 'success'))
    .catch(err => swalWithBootstrapButtons.fire('Error!', err.response.data.message, 'error'))
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-end mb-3"><Button variant="contained" color="success" onClick={()=>id==='' ? contentPublishAlert(publish_contents) : updateContentAlert(update_content)}>{ published ? id!==''?'Update and Publish':'Publish': id!==''?'Update':'Save'}</Button></div>
      <Box display={'grid'} gap={'20px'} gridTemplateColumns="repeat(1, minmax(0, 1fr))" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
        <Box display={'flex'}>
          <Typography sx={{width: '10%'}}>Publish</Typography>
          <Checkbox {...label} color={published ? 'success': 'secondary'} value={published} checked={published} onChange={e=>setPublished(e.target.checked)}/>
          {/* <TextField type="number" variant="filled" value={seo.display_order} onChange={e=>setSeo({...seo, display_order:e.target.value})}/> */}
        </Box>
        <Box display={'flex'} flex={1}>
          <Typography sx={{width: '10%'}}>SEO Page Name</Typography>
          <TextField variant="filled" value={seo.page_name} onChange={e=>setSeo({...seo, page_name:e.target.value})} sx={{ flex: 1}}/>
        </Box>
        <Box display={'flex'} flex={1}>
          <Typography sx={{width: '10%'}}>SEO Title</Typography>
          <TextField variant="filled" value={seo.page_title} onChange={e=>setSeo({...seo, page_title:e.target.value})} sx={{ flex: 1}}/>
        </Box>
        <Box display={'flex'} flex={1}>
          <Typography sx={{width: '10%'}}>SEO Description</Typography>
          <TextField variant="filled" value={seo.description} onChange={e=>setSeo({...seo, description:e.target.value})} sx={{ flex: 1}}/>
        </Box>
        <Box>
        <Box display={'flex'}>
          <Typography sx={{width: '10%'}}>Display Order</Typography>
          <TextField type="number" variant="filled" value={seo.display_order} onChange={e=>setSeo({...seo, display_order:e.target.value})}/>
        </Box>
          <Typography>Contents</Typography>
          <SunEditor setOptions={options} height="230px" onSave={contents => setContent(contents)} setContents={contents} onImageUpload={(targetImgElement, index, state, imageInfo, remainingFilesCount)=>console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)} onImageUploadBefore={handleBeforeUpload}/>
        </Box>
      </Box>
    </div>
  )
};


export default AddContent;