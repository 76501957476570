// import React, { useState, useEffect } from "react";
// import { Box, Button, MenuItem, TextField} from "@mui/material";
// import { Formik } from "formik";
// import * as yup from "yup";
// import axios from './Axios';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import CheckIcon from '@mui/icons-material/Check';
// import { Link } from "react-router-dom";

// function Address({onChange, title, setIsPayment, setIsAddressCompleted, isOrderConfirmed, setIsOrderConfirmed, setPaymentConfirmed}) {
//     const isNonMobile = useMediaQuery("(min-width:600px)");
//     const [isAddressComplete, setIsAddressComplete] = useState(false);
//     const [formData, setFormData] = useState({});
//     const [checkClassName, setCheckClassName] = useState("check");
//     const [address, setAddress] = useState("");
//     const [firstName, setFirstName] = useState("");
//     const [lastName, setLastName] = useState("");
//     const [phone, setPhone] = useState("");
//     const [city, setCity] = useState("");
//     const [addresscity, setAddresscity] = useState("");
//     const [region, setRegion] = useState("");
//     const [addressLocation, setAddressLocation] = useState([]);
//     const [email, setEmail] = useState('');
//     const [locLookups, setLocLookups] = useState([])
//     const [delivery, setDelivery] = useState(0);
//     const [lookupsObj, setLookupsObj] = useState({});
//     const [formErrors, setFormErrors] = useState({firstName:false, lastName:false, email:false, address:false, addressLocation:false, addresscity:false, region:false, phone:false});
//     // const [billingData, setBillingData] = useState({firstName:'', lastName:'', email:'', address:'', addressLocation:'', addresscity:'', region:''});
//     const theme = useTheme();
//     const [firstNameError, setFirstNameError] = useState(false);
//     const [lastNameError, setLastNameError] = useState(false);
//     const [emailError, setEmailError] = useState(false);
//     const [addressError, setAddressError] = useState(false);
//     const [addressLocationError, setAddressLocationError] = useState(false);
//     const [addressCityError, setAddressCityError] = useState(false);
//     const [regionError, setRegionError] = useState(false);
//     const [phoneError, setPhoneError] = useState(false);
//     const [allRequired, setAllRequired] = useState(true);
    
//   useEffect(() => {
//       onChange(formData)    
//   }, [formData, onChange]);

//     useEffect(() => {
//       async function getLocationLookups(){
//          await axios.get('/getlocationlookups').then((response) =>{
//             const locationLookups = response.data;
//             setAddressLocation(locationLookups)
//           });
//       }
//       getLocationLookups();
//     }, []);

//     useEffect(() => {
//       async function getTown(){
//         for (let i = 0; i < addressLocation.length; i++) {
//           if (addressLocation[i].region === region) {
//             for(var lockey in addressLocation[i].townLookUps){
//               locLookups.push(lockey)
//             }
//             setLookupsObj(addressLocation[i].townLookUps)
//           }
//         }
//       }
//       getTown();
//     }, [region]);

//     useEffect(() => {
//         setDelivery(lookupsObj[city]);
//     }, [lookupsObj, city])


//     const checkoutSchema = yup.object().shape({
//     firstName: yup.string().required("required"),
//     lastName: yup.string().required("required"),
//     email: yup.string().required('required'),
//     address: yup.string().required("required"),
//     phone: yup.string().required("required"),
//     city: yup.string().required("required"),
//     addressDiscription: yup.string().required("required"),
//     region: yup.string().required("required"),
//   });


//   const initialValues = {
//     firstName: firstName,
//     lastName:lastName,
//     email: email,
//     address:address,  
//     phone:phone,
//     city: city,
//     addressDiscription: addresscity,
//     region: region
//   };


//   const changeAddress = async () => {
//     try {
//       setPaymentConfirmed(false);
//       setIsAddressComplete(false);
//       setIsOrderConfirmed(false);
//       setIsPayment(false);
//       const addresData = {
//       firstName: undefined,
//       lastName: undefined,
//       email: undefined,
//       address: undefined,
//       phone: undefined,
//       city: undefined,
//       addresscity: addresscity,
//       region: region,
//       addressdelveryfee: delivery,
//     };
//     // if (title.lowerCase()==='shipping address'){
//       setFormData(addresData);
//     // }else if (title.lowerCase()==='billing address'){
//     //   setBillingData(addresData)
//     // }
//     } catch (error) {
      
//     }
//   }

//   useEffect(()=>{
//     if (firstName===''){setFirstNameError(true)}else{setFirstNameError(false)};
//     if (lastName===''){setLastNameError(true)}else{setLastNameError(false)};
//     if (email===''){setEmailError(true)}else{setEmailError(false)};
//     if (address===''){setAddressError(true)}else{setAddressError(false)};
//     if (phone===''){setPhoneError(true)}else{setPhoneError(false)};
//     if (city===''){setAddressCityError(true)}else{setAddressCityError(false)};
//     if (addresscity===''){setAddressLocationError(true)}else{setAddressLocationError(false)};
//     if (region===''){setRegionError(true)}else{setRegionError(false)};
//     // if (delivery===''){setDeliveryError(true)}else{setDeliveryError(false)};
//   }, [firstName, lastName, email, address, phone, city, addresscity, region])
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     if (firstName!==''&&lastName!==''&&email!==''&&address!==''&&phone!==''&&city!==''&&addresscity!==''&&region!==''){
//       const uniqueId = () => "CA"+ parseInt(Date.now() * Math.random()).toString() + lastName[0] + phone[2] + email[1].toUpperCase();
//       let addresData = {
//         orderId: uniqueId(),
//         firstName: firstName,
//         lastName: lastName,
//         email: email,
//         address: address,
//         phone: phone,
//         city: city,
//         addresscity: addresscity,
//         region: region,
//         addressdelveryfee: delivery
//       }


  
//       setAllRequired(false);
//       try {
//         setIsAddressComplete(true)
//         setIsAddressCompleted(true);
//         setFormData(addresData);
//         setCheckClassName("checkCompleted")
//         await axios.post('/billing/new', addresData);          
        
//         }catch (err) {
//           alert(err.response.data.errorMessage);
//         }
//     }else{
//       setAllRequired(true);
//       if (firstName===''){setFirstNameError(true)}else{setFirstNameError(false)};
//       if (lastName===''){setLastNameError(true)}else{setLastNameError(false)};
//       if (email===''){setEmailError(true)}else{setEmailError(false)};
//       if (address===''){setAddressError(true)}else{setAddressError(false)};
//       if (phone===''){setPhoneError(true)}else{setPhoneError(false)};
//       if (city===''){setAddressCityError(true)}else{setAddressCityError(false)};
//       if (addresscity===''){setAddressLocationError(true)}else{setAddressLocationError(false)};
//       if (region===''){setRegionError(true)}else{setRegionError(false)};
//     }
    
    
//   };

//   return (
//     <>
//     {!isAddressComplete ? (
//     <Box className="shipping-form ">
     
//         {/* <Formik
//         initialValues={initialValues}
//         validationSchema={checkoutSchema}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           handleBlur,
//           handleChange,
//           handleSubmit,
//         }) => ( */}
//           <form onSubmit={handleSubmit} noValidate >
//             <div className="address__tittle">
//               <div className="icon__text">
//                 <CheckIcon className={checkClassName}/>
//                 <div className="ps-2" style={{fontSize:'14px'}}>{'Billing Address'}</div>
//               </div>
//             </div>
//             {allRequired&&<p className="m-0 ms-4 text-danger" style={{fontSize:'14px'}}>All fields are required *</p>}
//             <hr className="hoz__rule" />
//             <Box m="20px"
//               display="grid"
//               // component={'form'}
//               gap="12px"
//               gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//               sx={{
//                 "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                 fontSize: '14px'
//               }}
//             >

//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   variant="outlined"
//                   type="text"
//                   label="First Name"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setFirstName(e.target.value)}
//                   value={firstName}
//                   name="firstName"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
//                   error={firstNameError}
//                   // helperText={firstNameError&&'required'}
//                   // color={!firstNameError?'success':'primary'}
//                 />
//                 {firstNameError&&<span style={{color:firstNameError&&'red'}}>required</span>}
//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   variant="outlined"
//                   type="text"
//                   label="Last Name"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setLastName(e.target.value)}
//                   value={lastName}
//                   name="lastName"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
//                   error={lastNameError}
//                   // helperText={lastNameError&&'required'}
//                   // color={!lastNameError?'success':'primary'}
//                 />
//                 {lastNameError&&<span style={{color:lastNameError&&'red'}}>required</span>}
//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   variant="outlined"
//                   type="text"
//                   label="Phone Number"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setPhone(e.target.value)}
//                   value={phone}
//                   name="phone"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
//                   error={ phoneError}
//                   // helperText={phoneError&&'required'}
//                   // color={!phoneError?'success':'primary'}
//                 />
//                   {lastNameError&&<span style={{color:lastNameError&&'red'}}>required</span>}
//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   variant="outlined"
//                   type="text"
//                   label="Email"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setEmail(e.target.value)}
//                   value={email}
//                   name="email"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
//                   error={emailError}
//                   // helperText={emailError&&'required'}
//                   // color={!emailError?'success':'primary'}
//                 />
//                 {emailError&&<span style={{color:emailError&&'red'}}>required</span>}

//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   variant="outlined"
//                   type="text"
//                   label="Address"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setAddress(e.target.value)}
//                   value={address}
//                   name="address"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
//                   error={addressError}
//                   // helperText={addressError&&'required'}
//                   // color={!addressError?'success':'primary'}
//                 />
//                 {addressError&&<span style={{color:addressError&&'red'}}>required</span>}
//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   variant="outlined"
//                   type="text"
//                   label="Address Discription"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setAddresscity(e.target.value)}
//                   value={addresscity}
//                   name="addressDiscription"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360' }}
//                   error={addressLocationError}
//                   // helperText={addressLocationError&&'required'}
//                   // color={!addressLocationError?'success':'primary'}
//                 />
//                 {addressLocationError&&<span style={{color:addressLocationError&&'red'}}>required</span>}
//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   select
//                   variant="outlined"
//                   type="text"
//                   label="region"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setRegion(e.target.value)}
//                   value={region}
//                   name="region"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360'}}
//                   error={regionError}
//                   // helperText={regionError&&'required'}
//                   // color={!regionError?'success':'primary'}
//                 >
//                   {addressLocation.map((loc) => (
//                     <MenuItem key={loc.region} value={loc.region}>
//                       {loc.region}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//                 {regionError&&<span style={{color:regionError&&'red'}}>required</span>}
//               </Box>
//               <Box sx={{gridColumn:'span 2'}}>
//                 <TextField
//                   fullWidth
//                   size="small"
//                   required
//                   select
//                   variant="outlined"
//                   type="text"
//                   label="city"
//                   // onBlur={handleBlur}
//                   value={} onChange={(e) => setCity(e.target.value)}
//                   value={city}
//                   name="city"
//                   sx={{ gridColumn: "span 2", backgroundColor:'#d3d3d360', fontSize:'14px'}}
//                   error={addressCityError}
//                   // helperText={addressCityError&&'required'}
//                   // color={!addressCityError?'success':'primary'}
//                 >
//                   {locLookups.map((lookups) => (
//                     <MenuItem key={lookups} value={lookups}>
//                       {lookups}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//                 {addressCityError&&<span style={{color:addressCityError&&'red'}}>required</span>}
//               </Box>
//             </Box>
//             <div className="d-flex justify-content-end pe-3 mb-3">
//               <Button type="submit" color="secondary" variant="contained" className="h-100 rounded-pill">
//                 Done
//               </Button>
//               </div>
//           </form>
//         {/* )}
//       </Formik>
//       */}
//     </Box>
//     ):(
//       <div className="address__compt">
//         <div className="address__tittle">
//           <div className="icon__text">
//             <CheckIcon className={checkClassName}/>
//               <div className="ps-2">{title.toLowerCase()==='shipping address'?'SHIPPING ADDRESS':'BILLING ADDRESS'}</div>
//           </div>
//           {!isOrderConfirmed&&<div>
//             <button onClick={changeAddress} className="changebtn">Change</button>
//           </div>}
//           </div>
//           <hr className="hoz__rule"/>
//        <div className="cus__name">{firstName} {lastName}</div>
//        <div className="cus__address">{address}, {addresscity} | {region} - {city} | {phone}</div>
//     </div>
//    )}
//     </>
//   )
// }

// export default Address



import { useEffect, useState } from 'react';
import axios from '../utils/Axios';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from '@mui/material/Button';
import '../css/BillingAddress.css';



const addressDescription = ['Apartment', 'Office'];




function BillingAddress({ formdata }) {
  const [validated, setValidated] = useState(false);
  const [billingData, setBillingData] = useState(formdata);
  const [addressLocation, setAddressLocation] = useState([]);
  const [lookupsObj, setLookupsObj] = useState();
  const [region, setRegion] = useState('');
  const [locLookups, setLocLookups] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [countries, setCountry] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(()=>{
    if (JSON.stringify(formdata)===JSON.stringify(billingData)){
      setSubmit(false);
    }else {
      setSubmit(true);
    }
  },[billingData])


  useEffect(() => {
    async function getLocationLookups(){
      await axios.get('/getlocationlookups').then((response) =>{
        const locationLookups = response.data;
        setAddressLocation(locationLookups)
      });
    }
    getLocationLookups();
  }, []);


  useEffect(() => {
    function getTown(){
      let lookups = [];
      for (let i = 0; i < addressLocation.length; i++) {
        if (addressLocation[i].region === billingData.region) {
          for(var lockey in addressLocation[i].townLookUps){
            lookups.push(lockey)
          }
          setLocLookups(lookups);
          setLookupsObj(addressLocation[i].townLookUps)
        }
      }
    }
    getTown();
  }, [billingData.region, addressLocation]);

  const handleSubmit =async (event) => {
    delete billingData.deliveryfee;
    delete billingData.orderId;
    try{
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false){
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      }else {
        // await axios.post('/billing/new', billingData);
        setErrorMessage('');
        setValidated(false);
        setIsSave(true);
      }
    }catch(err){
      setErrorMessage(err.response.data.errorMessage);
    }
  };

  const onChangeFirstName=(e)=>setBillingData({...billingData, firstName:e.target.value});
  const onChangeLastName=(e)=>setBillingData({...billingData, lastName:e.target.value});
  const onChangePhone=(e)=>setBillingData({...billingData, phone:e.target.value});
  const onChangeEmail=(e)=>setBillingData({...billingData, email:e.target.value});
  const onChangeAddress=(e)=>setBillingData({...billingData, address:e.target.value});
  const onChangeAddressDescription=(e)=>setBillingData({...billingData, addresscity:e.target.value});
  const onChangeRegion=(e)=>setBillingData({...billingData, region:e.target.value});
  const onChangeCity=(e)=>setBillingData({...billingData, city:e.target.value});
  const onChangeCountry=(e)=>setBillingData({...billingData, country:e.target.value});

  const onChangeBillingData=()=>setIsSave(false);
  
  useEffect(()=>{
    fetch('https://countriesnow.space/api/v0.1/countries', {method:'GET'})
    .then(res=>res.json())
    .then(res=>{
      if (res.error===false){
        const countries = res.data.map(countries=>countries.country);
        // [...new Set(selected[0]['option'])]
        setCountry([...new Set(countries)])
      }
    })
  },[])

  return (
    <>{!isSave?
    <Form noValidate validated={validated} onSubmit={handleSubmit} className='address_form'>
      <Row className="mx-0">
    
        <Form.Group className='mb-3' sm="12" md="6" as={Col}>
            <FloatingLabel controlId='valSelect' label='Country'>
              <Form.Select style={{backgroundColor: 'white'}} value={billingData.country} onChange={onChangeCountry} className='bg-light select_font' required>
                <option />
                {countries.map((country)=>
                  <option style={{color:'black'}} key={country} label={country} value={country}/>  
                )}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <div className='row mx-0 px-0'>
          <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="4" controlId="validationCustom01">
            <FloatingLabel controlId='validationCustom01' label='First Name'>
              <Form.Control required className='bg-light' type="text" placeholder="First name" value={billingData.firstName} onChange={onChangeFirstName} size='sm' />
            </FloatingLabel>
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
          </Form.Group>

          <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="4" controlId="valLastName">
            <FloatingLabel controlId='valLastName' label='Last Name'>
              <Form.Control required className='bg-light' type="text" placeholder="Last name" value={billingData.lastName} onChange={onChangeLastName} size='sm' />
            </FloatingLabel>
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
          </Form.Group>

          <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="4" controlId="validationphone">
            <FloatingLabel controlId='validationphone' label='Phone Number'>
              <Form.Control type="tel" className='bg-light' placeholder="phone number" value={billingData.phone} onChange={onChangePhone} aria-describedby="validationphone" required />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">Phone number is invalid...</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="4" controlId="valEmail">
            <FloatingLabel controlId='valEmail' label='Email'>
            <Form.Control type="email" className='bg-light' required placeholder='email' value={billingData.email} onChange={onChangeEmail}/>
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="4" controlId="valAddress">
            <FloatingLabel controlId='valAddress' label='Address'>
              <Form.Control type="text" className='bg-light' placeholder="address" value={billingData.address} onChange={onChangeAddress} aria-describedby='valAddress' required />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">Please provide address.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="4" controlId="valAddDescription">
            <FloatingLabel controlId='valAddDescription' label='Address Description (Optional)'>
              {/* <Form.Control type="text" className='bg-light' value={billingData.addresscity} onChange={onChangeAddressDescription} placeholder="address description" required /> */}
              <Form.Select style={{backgroundColor: 'white'}} value={billingData.addresscity} onChange={onChangeAddressDescription} className='bg-light select_font'>
                <option />
                {addressDescription.map(description=>
                  <option style={{color:'black'}} key={description} label={description} value={description}/>  
                )}
              </Form.Select>
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">Please provide address description.</Form.Control.Feedback>
          </Form.Group>
          {billingData.country === "Ghana" ?(
            <Form.Group className='mb-3 ps-0' sm="12" md="6" as={Col}>
            <FloatingLabel controlId='valSelect' label='Region'>
              <Form.Select style={{backgroundColor: 'white'}} value={billingData.region} onChange={onChangeRegion} className='bg-light select_font' required>
                <option />
                {addressLocation.map(loc=>
                  <option style={{color:'black'}} key={loc.region} label={loc.region} value={loc.region}/>  
                )}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          ):(
            <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="6" controlId="valAddDescription">
            <FloatingLabel controlId='valSelect' label='Region/State/Province'>
              <Form.Control type="text" className='bg-light' value={billingData.region} onChange={onChangeRegion} placeholder="region" required />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">Please provide Region/State/Province</Form.Control.Feedback>
          </Form.Group>
          )}
          {billingData.country === "Ghana" ?(
            <Form.Group className='mb-3' sm="12" md="6" as={Col}>
            <FloatingLabel controlId='valSelectCity' label='City' >
              <Form.Select style={{backgroundColor: 'white'}} value={billingData.city} onChange={onChangeCity} className='bg-light select_font' required>
                <option></option>
                {locLookups.map(loc=>
                  <option style={{color:'black'}} key={loc} label={loc} value={loc}/>  
                )}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          ):(
            <Form.Group className='mb-3 ps-0' as={Col} sm="12" md="6" controlId="valAddDescription">
            <FloatingLabel controlId='valSelectCity' label='City'>
              <Form.Control type="text" className='bg-light' value={billingData.city} onChange={onChangeCity} placeholder="city" required />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">Please provide city</Form.Control.Feedback>
          </Form.Group>
          )}
          </div>

      </Row>
      <div className="d-flex justify-content-end pe-3">
        {submit&&<Button type="submit" color="secondary" variant="contained" className="h-100 rounded-pill">Done</Button>}
      </div>
    </Form>:
    <div className="border-top">
    <div className="address__tittle ps-0">
      <div className="icon__text">
        {/* <CheckIcon className={checkClassName}/> */}
          <h6 className="m-0 pb-0 fw-bold">{'Bill to'}</h6>
      </div>
      {/* {!isAddressComplete&&<div>
        <button onClick={onChangeBillingData} className="changebtn">Change</button>
      </div>} */}
      </div>
      <div className="cus__name ps-0">
      <p className='m-0'>
        {billingData.country}
        {billingData.firstName} {billingData.lastName} <br />
        {billingData.address} <br />
        {billingData.addresscity!==''&&billingData.addresscity} {billingData.addresscity!==''&&<br />}
        {billingData.region} <br />
        {billingData.city} <br />
        {billingData.phone} <br />
      </p>
      <button onClick={onChangeBillingData} className="border-0 bg-white ps-0" style={{color:'blue'}}>Change</button>
    </div>
   {/* <div className="cus__address">{billingData.address}, {billingData.addresscity} | {billingData.region} - {billingData.city} | {billingData.phone}</div> */}
  </div>}
  </>
  );
}

export default BillingAddress;