import React, { useState, useContext, useEffect } from 'react'
import axios from '../utils/Axios'
import jwt_decode from 'jwt-decode'
// import AuthContext, { useAuthContext } from '../utils/AuthLoggedIn'
import '../css/signup.css'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import Spinner from 'react-bootstrap/Spinner';
// import { GoogleLogin, googleLogout } from '@react-oauth/google'
// import { GoogleAuthContext } from '../utils/GoogleLoggedIn'
import AuthContext, { useAuthContext } from '../utils/AuthLoggedIn'
import EventEmitter from '../utils/eventEmitter'
// import EventEmitter from '../utils/eventEmitter'

function Login() {
  const [telnumber, setTelnumber] = useState('')
  const [password, setPassword] = useState('')
  const location = useLocation()
  const { login, loginDetails } = useAuthContext();
  const [details, setDetails] = useState();
  const navigate = useNavigate()
  const state = location.state;
  const [message, setMessage] = useState('')
  const { currenPath, price } = state || ''
  const [loggingIn, setLoggingIn] = useState(false);
  // const googleContext = useContext(GoogleAuthContext)
  const authCTX = useContext(AuthContext)

  // useEffect(() => {
  //   EventEmitter.subscribe('details', data => setDetails(data));
  // },[])

  async function handleSubmit(e) {
    e.preventDefault()
    // if (telnumber===''&&password===''){
    //   setMessage('All fields are required *');
    // }
    try {
      const loginData = { telnumber, password }

      // const res = await login(loginData);
      login(loginData, setLoggingIn);
      // if (details.role === 'user') {
      //   window.location.href = '/';
      // }
      // const res = await axios.post('/login', loginData)
      // if (
      //   res.data &&
      //   (res.data.varified === false || res.data.varified === null)
      // ) {
      //   // console.log(res.data.userId)
      //   document.cookie = `userId=${res.data.userId}`
      //   return navigate('/ven/add-seller')
      // }
    //   axios
    //     .get('/getToken')
    //     .then((res) => {
    //       if (res.data.token !== undefined) {
    //         const token = res.data.token
    //         const decode = jwt_decode(token)
    //         if (decode) { console.log(decode)
    //           authCTX.setToken(decode)
    //           // localStorage.setItem('token', token)
    //           window.location.href = '/'
    //         } else {
    //           setMessage('unknown user!')
    //         }
    //       }
    //     })
    //     .catch((err) => console.log(err))
    } catch (err) {
      setMessage(err.response.data.errorMessage)
    }
  }

  // const handleSuccess = (credentials) => {
  //   if (credentials) {
  //     googleContext.handleLogin(credentials)
  //     navigate('/')
  //   }
  // }

  // const handleError = (err) => {
  //   console.log(err)
  // }

  // if (!loggedIn) {
  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name='title' content='Login' />
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className='wrapp formlogin h-100'>
        <section className='form signup'>
          <div className='d-flex justify-content-center'>
            <img
              className='logo_'
              src='uploads/caspian_logo.png'
              alt='Caspian'
              height={100}
            />
          </div>
          <form onSubmit={handleSubmit} className='user_auth_form'>
            <div className='signup_heading_container mb-5'>
              <h1 className='signup_heading'>Welcome</h1>
              <p className='signup_text'>Sign In</p>
            </div>
            <div className='field login input'>
              <label>Email or Phone Number</label>
              <input
                name='telnumber'
                className='w-100'
                value={telnumber}
                onChange={(e) => setTelnumber(e.target.value)}
                type='text'
              />
            </div>
            <div className='field login input'>
              <label>Password</label>
              <input
                name='password'
                className='w-100'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type='password'
              />
            </div>
            <div className='field'>
              {message !== '' && (
                <small style={{ color: 'red', textAlign: 'start' }}>
                  {message}
                </small>
              )}
            </div>
            <button className='login-btn' type='submit' disabled={loggingIn ? true : false}>
              {loggingIn ? <Spinner animation="border" variant="warning" size='sm' /> : 'Login'}
            </button>
            <Link
              to={'/register'}
              state={{ currenPath: currenPath, price: price }}
            >
              <div className='btn btn-danger submitbtn'>Sign Up</div>
            </Link>
            {/*
            <div className=' my-2 '>
              <GoogleLogin
                onSuccess={handleSuccess}
                onError={handleError}
                width={370}
              />
            </div>
              */}
          </form>
        </section>
      </div>
    </>
  )
}

export default Login
