import Rating from "@mui/material/Rating";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../utils/useWindowDimension";
import axios from '../utils/Axios';




const ProductReviews = ({ name }) => {
  const { width } = useWindowDimensions();
  const [reviews, setReviews] = useState([]);
  const [totalRating, setTotalRating] = useState(0);

  useEffect(() => {
    axios.get(`product/${name}/review`)
    .then(res => { 
      if (res.data){
        setReviews(res.data);
        setTotalRating(reviews.reduce((prev, current) => (prev + current.rate)/reviews.length, 0))
      }
    })
    .catch(err=> console.log(err))
  },[])

  const onChange = (e) => { };



  return (
    <div className="row border bg-white mb-3">
      <h2 className="mb-3 py-3 border-bottom">Verified Customer Purchase Reviews</h2>
      {width >400 && <div className="col-5 col-sm-3">
        <h3 style={{fontSize:'18px', fontWeight:'bold'}}>Verified Reviews</h3>
        <div>
          <Rating precision={0.5} value={reviews.length > 0 ? Math.floor((reviews.reduce((prev, current) => (prev + current.rate), 0)/reviews.length).toFixed(1)) : 0} onChange={onChange} readOnly/>
          <p className="m-0">{reviews.length > 0 ? Math.floor((reviews.reduce((prev, current) => (prev + current.rate), 0)/reviews.length).toFixed(1)) : 0 } of 5</p>
        </div>
        <span>{reviews.length} verified ratings</span>
      </div>}
      <div className={width > 400 ?'col-7':'col-12'+" col-sm-9"}>
        <h3 style={{fontSize:'18px', fontWeight:'bold'}}>Verified Purchase Reviews</h3>
        {reviews.map((review, idx) => 
          <div key={idx}>
            <Rating precision={0.5} defaultValue={review.rate} readOnly/>
            <h6 className="fw-bold" style={{fontSize:'14px'}}>{review.title}</h6>
            <p className="">{review.comments}</p>
            <div className="d-flex justify-content-between text-secondary" style={{width: '200px'}}>
              <span className="text-secondary">{review.date}</span>
              by
              <span className="text-secondary">{review.name}</span>
            </div>
            <hr />
          </div>
        )}
      </div>
    </div>
  )
};


export default ProductReviews;