import React, { useState, useEffect } from "react";
import axios from "../../utils/Axios";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../utils/theme";
import '../products/productlist.css';




function ContentManagementList () {
    const [allprod, setAllprod] = useState([]);
    const [contents, setContents] = useState({list:[], total:0});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page:0});
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleView = (id) => {
      console.log(id)
    };

    const handleEdit = (id) => navigate('/add_content', {state: id});

    function deleteItem(itemId, swalWithBootstrapButtons) {
      axios.delete(`/delete_prod/${itemId}`)
     .then(response => {
      swalWithBootstrapButtons.fire('Deleted!', response.data.message, 'success');
     })
     .catch(error => {
        console.error('Error:', error);
     });
    }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actionCell = (row) => {
    return (
      <div className="w-100 d-flex justify-content-around">
        <ListItemIcon onClick={()=>handleView(row['_id'])}><VisibilityIcon sx={{marginRight: '5px'}}/>View</ListItemIcon>
        <ListItemIcon onClick={()=>handleEdit(row['_id'])}><EditIcon sx={{marginRight: '5px'}}/>Edit</ListItemIcon>
        {/* <ListItemIcon onClick={()=>deleteAlert(deleteItem, row['_id'])}><DeleteIcon sx={{marginRight: '5px'}}/>Delete</ListItemIcon> */}
      </div>
    )
  }

  const onPaginationModelChange = (model, details) => {
    if (model.page === 0){
      // setPaginationModel({...paginationModel, page:1, pageSize:model.pageSize});
      setPage(1);
      console.log('page is zero: ', model.page, details)
      setPageSize(model.pageSize);
    }else {
      // setPaginationModel({...paginationModel, page:model.page, pageSize:model.pageSize});
      setPage(model.page + 1);
      console.log('page is not zero: ', model, details)
      setPageSize(model.pageSize);
    }
  }

  async function getContents(){
    await axios.get(`/content-management/all?page=${page}&&limit=${pageSize}`)
    .then((response) =>{
      const data = response.data;
        // setAllprod(chainData);
        setContents({...contents, list:data.contents, total:data.total});
        // setTotalPages(chainData.totalPages);
      // console.log(chainData)
    });
  }


  useEffect(() => {
    getContents();
  }, [page, pageSize]);


      const columns = [
        { field: '_id',
          headerName: 'ID',
          disableColumnMenu: true,
          sortable: false,
        },
        {
          field: "seoPageName",
          headerName: "Page Name",
          flex: 1,
          cellClassName: "name-column--cell",
        },
        {
          field: "seoTitle",
          headerName: "Title",
          flex: 1,
        },
        {
          field: "display_order",
          headerName: "Display Order",
          flex: 1,
        },
        {
          field: "seoDescription",
          headerName: "Description",
          flex: 0.7,
        },
        {
          field: "actions",
          headerName: 'Actions',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          // valueGetter: params => ,
          renderCell: (params) => actionCell(params.row)
        }
      ];

    // if (products.length === 0) return <Loader />
  return (
    <Box m="20px" height="82vh">
      <Box
        m="40px 0 0 0"
        height="85vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid         
          rows={contents.list} 
          columns={columns}
          rowCount={contents.total}
          pagination
          initialState={{pagination:{paginationModel:{page:0}}}}
          paginationModel={{page:page-1, pageSize:pageSize}} 
          getRowId={obj=>obj._id} 
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
        />
      </Box>
    </Box>
    // <div>
    //     <Table striped bordered hover>
    //   <thead>
    //     <tr>
    //       <th>#</th>
    //       <th>Product Image</th>
    //       <th>Product Name</th>
    //       <th>Category</th>
    //       <th>Regular Price</th>
    //       <th>Sale Price</th>
    //       <th>Brand Name</th>
    //       <th>Actions</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {products.map((prod, i) =>(
    //         <tr>
    //         <td>{i}</td>
    //         <td><img src={prod.imageUrl} height={70} /></td>
    //         <td>{prod.productName}</td>
    //         <td>{prod.category}</td>
    //         <td>{prod.regularPrice}</td>
    //         <td>{prod.salePrice}</td>
    //         <td>{prod.brandName}</td>
    //         <td>
    //         <DropdownButton id="dropdown-basic-button" title="Actions">
    //         <Dropdown.Item href="#/action-1">View</Dropdown.Item>
    //         <Dropdown.Item href="/form" as={Link} to={{
    //         pathname: "/form",
    //         state: {
    //             id: prod._id,
    //         }
    //     }}>
    //         Update
    //         </Dropdown.Item>
    //         <Dropdown.Item onClick={() => deleteItem(prod._id)}>Delete</Dropdown.Item>
    //         </DropdownButton>
    //         </td>
    //       </tr>
    //     ))}
        
    //     <tr></tr>
    //   </tbody>
    //   </Table>
    //   <div>
    //             {Array.from({ length: totalPages }, (_, index) => (
    //                 <button
    //                     key={index}
    //                     onClick={() => setCurrentPage(index + 1)}
    //                     disabled={currentPage === index + 1}
    //                 >
    //                     {index + 1}
    //                 </button>
    //             ))}
    //         </div>
    // </div>
  )
}

export default ContentManagementList;