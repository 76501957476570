import React, { useContext, useEffect, useState } from 'react'
import { useStateValue } from '../utils/StateProvider'
import CheckoutProduc from '../components/CheckoutProduc'
import Subtotal from '../components/Subtotal'
import '../css/Checkout.css'
import CallIcon from '@mui/icons-material/Call'
import { getBasketTotal } from '../utils/Reducer'
import { Link, useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import useWindowDimensions from '../utils/useWindowDimension'
import AuthContext, { useAuthContext } from '../utils/AuthLoggedIn'
import PopUp from '../unused_components/PopUp'
import CategoriesGroupComponent from '../components/CategoriesGroupComponent'
import CategoriesGroup from '../components/CategoriesGroup'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

function Checkout() {
  const { basket } = useOutletContext();
  const navigate = useNavigate()
  const { height, width } = useWindowDimensions()
  const { details } = useAuthContext()
  const [modalShow, setModalShow] = useState(false)

  async function fillPaymemtDetails() {
    if (basket.length !== 0) {
      shipping()
    } else {
      alert('Please, your basket is empty. shop and add to cart!')
    }
  }

  const onHide = () => setModalShow(false)
  const login = () => navigate('/login');

  const shipping = () => navigate('/checkout');

  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Helmet>
        <title>Cart</title>
        <meta name='title' content='Cart' />
        <meta name='robots' content='noindex, follow' />
      </Helmet>
      <div className='checkout_con container-xl d-flex flex-column'>
        <div className='checkout'>
          <div className='checkout__left'>
            {basket?.length === 0 ? (
              <>
                <div className='d-flex justify-content-center align-items-center flex-column p-3 pb-1 '>
                  <h1>Your basket is empty</h1>
                  <p>
                    {' '}
                    Already have an account? <br /> Sign in to see your items.
                  </p>
                </div>
                <div className='d-flex justify-content-center p-3'>
                  {!details.loggedIn && (
                    <Link
                      to={{ pathname: '/login' }}
                      style={{ backgroundColor: '#24eac3' }}
                      className='p-2 px-3 rounded text-decoration-none cart_login text-dark text-center'
                    >
                      Login
                    </Link>
                  )}
                  <Link
                    to={'/'}
                    className='p-2 px-2 ms-3 rounded text-decoration-none text-dark nocart_contn_shop'
                  >
                    Continue shopping
                  </Link>
                </div>
              </>
            ) : (
              <div className='px-2'>
                <h2 className='checkout__title'>Your shopping basket</h2>
                {basket.map((item) => (
                  <CheckoutProduc
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    image={item.imageUrl}
                    price={item.price}
                    basePrice={item.basePrice}
                    quantity={item.quantity}
                    discount={item.discount}
                  />
                ))}
              </div>
            )}
          </div>
          {basket.length > 0 && (
            <div className='checkout__right'>
              <Subtotal
                show={modalShow}
                onHide={onHide}
                setModalShow={setModalShow}
                login={login}
                shipping={shipping}
              />
            </div>
          )}
        </div>
      </div>
      {basket.length > 0 && (
        <>
          <div className='checkout__actions pb-2'>
            <div className='callBtn'>
              <a href='tel:0593855318' style={{ outline: 'none', padding: 0 }}>
                <CallIcon />
              </a>
            </div>
            <button onClick={fillPaymemtDetails} className='checkoutbtn ms-5'>
              {' '}
              CHECKOUT{' '}
            </button>
          </div>
          {width <= 600 && !window.location.pathname.includes('cart') && (
            <div className='d-flex justify-content-end ch_btn_con px-3 mb-3'>
              <Button
                variant='info'
                onClick={() => navigate('/store')}
                className='p-2'
              >
                Continue shopping
              </Button>
            </div>
          )}
        </>
      )}
      {/* <PopUp 
            show={modalShow}
            onHide={() => setModalShow(false)}
            login={login}
            shipping={shipping}
        /> */}
    </>
  )
}

export default Checkout
