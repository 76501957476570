import React, { useState, useEffect } from 'react'
import '../css/Home.css'
import '../css/Carousel.css'
import Product from '../components/Product'
import CategoryComponent from '../components/CategoryComponent'
import CategoriesGroupComponent from '../components/CategoriesGroupComponent'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import GroupComponent from '../components/GroupComponent'
import CategoryRendering from '../components/CategoryRendering'
import axios from '../utils/Axios'
import MobileCategories from '../components/MobileCategories'
import CategoriesGroup from '../components/CategoriesGroup'
import MobileCategoriesChild from '../components/MobileCategoriesChild'

import CarouselBannerComponent, {
  CarouselBannerChild
} from '../components/CarouselBannerComponent'
import ImageBannerComponent from '../components/ImageBannerComponent'
import CategoryTypeComponent from '../unused_components/CategoryTypeComponent'
import CategoryTypeGridComponent from '../unused_components/CategoryTypeGridComponent'
import CarouselProductCategory, {
  CategoryProducts
} from '../components/CarouselProductCategory'
import CardComponent, { CardChild } from '../components/CardComponent'
import Loader from '../components/Loader'
import useWindowDimensions from '../utils/useWindowDimension'
import { Helmet } from 'react-helmet'
import amazon from '../images/amazon.jpg'
import materials from '../images/materials.png'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 6000, min: 3000 },
    items: 1,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 600, min: 450 },
    items: 1,
    slidesToSlide: 1
  },
  small: {
    breakpoint: { max: 450, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
}

function Home() {
  const [prodGeneric, setProdGeneric] = useState([])
  const [lowestPrices, setLowestPrices] = useState([])
  const [banners, setBanners] = useState([])
  const [adsdata, setAdsData] = useState([])

  // const { width } = useWindowDimensions()

  useEffect(() => {
    async function getGenericProducts() {
      await axios.get('/genericproducts').then((response) => {
        const products = response.data
        setProdGeneric(products)
      })
    }
    getGenericProducts()
  }, [])

  useEffect(() => {
    async function getLowestPriceProducts() {
      await axios.get('/lowestprice').then((response) => {
        const low = response.data
        setLowestPrices(low)
      })
    }
    getLowestPriceProducts()
  }, [])

  useEffect(() => {
    async function getBanners() {
      await axios.get('/getbanners').then((response) => {
        const bannerss = response.data
        setBanners(bannerss)
      })
    }
    getBanners()
  }, [])

  useEffect(() => {
    async function getAds() {
      await axios.get('/getads').then((response) => {
        const ads = response.data
        setAdsData(ads)
      })
    }
    getAds()
  }, [])
  // console.log(lowestPrices)
  // useEffect(() => {
  //   async function getAds() {
  //     await axios.get('/getads').then((response) => {
  //       const ads = response.data
  //       setAdsData(ads)
  //     })
  //   }
  //   getAds()
  // }, [])
  //  console.log(prodGeneric)

  return (
    <>
      <Helmet>
        <title>
          Caspian - Dealers in building materials, hardwares, electronics and
          more...
        </title>
        <meta
          name='title'
          content='Caspian - Dealers in building materials, hardwares, electronics and more...'
        />
        <meta
          name='description'
          content='Caspian is the most affordable online shopping platform in Ghana ➜ Shop Building Materials, Televisions, Home Appliances, Electronics, Books &amp; more online ✔ Best prices in Ghana ✔ Best Building materials for your needs ✔ Order now and enjoy the best delivery services in Ghana!'
        />
        <meta
          property='og:description'
          content='Caspian is the most affordable online shopping platform in Ghana ➜ Shop Building Materials, Televisions, Home Appliances, Electronics, Books &amp; more online ✔ Best prices in Ghana ✔ Best Building materials for your needs ✔ Order now and enjoy the best delivery services in Ghana!'
        />
        <meta
          name='keywords'
          content='best online shopping in ghana, online shopping, affordable ecommerce, caspian, caspian outlets, casspian, electronics, building materials, home appliances, books'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>

      {banners.length > 0 ? (
        <CarouselBannerComponent responsive={responsive}>
          {banners.map((bans, idx) => (
            <CarouselBannerChild
              key={idx}
              image={bans.imageUrl}
              alt={'banner'}
            />
          ))}
        </CarouselBannerComponent>
      ) : (
        <Loader />
      )}

      <CategoryRendering
        catTypeOne={'Electronics'}
        catTypeThree={'Home, Furniture & Appliances'}
        catTypeTwo={'Construction'}
        catTypeFour={'Books'}
      />
      <div className='cat__type_c'>
        <CategoryComponent
          key={1221}
          playSpeed={15000}
          title='Commonly searched items'
          queryone='Air Conditioners'
          querytwo='Carpentry Materials'
          querythree='Refrigerators'
          queryfour='Kitchen Appliances'
          loadFromCookie={true}
          clsname='title_info_red'
          searched
        />
      </div>
      <div className='cat__type_c'>
        <CategoryComponent
          key={12661}
          playSpeed={12000}
          title='Your construction needs'
          queryone='Blocks'
          querytwo='Paints'
          querythree='Equipments'
          queryfour='Bends'
          clsname='title_info_red'
        />
      </div>
      <ImageBannerComponent image={'/uploads/Banners/SUPPORT BANNER.png'} />
      <div className='cat__type_c'>
        <div className='cat__type_c'>
          <CategoryComponent
            key={342}
            playSpeed={9000}
            title='Our top deals'
            queryone={'Refrigerators'}
            querytwo={'Tools'}
            querythree={'Cements'}
            queryfour={'Kitchen Appliances'}
            clsname={'title_info'}
          />
        </div>
        <CategoryComponent
          key={1821}
          playSpeed={14000}
          title='Shop deals'
          queryone='Audio & Music Equipments'
          querytwo='Accessories & Supplies for Electronics'
          querythree='Glue'
          queryfour='Pipes'
          clsname='title_info'
        />
      </div>
      {adsdata.length > 0 && (
        <ImageBannerComponent image={adsdata[0].imageUrl} />
      )}

      <CategoryTypeComponent
        categoryitem={'Electronics'}
        title={'Electronics for your home'}
        fromHome={true}
      />
      <MobileCategories>
        <MobileCategoriesChild
          title={'Rice Cookers'}
          searchParams={'Rice Cooker'}
          image={'/uploads/Banners/rrrrr-removebg-preview1.jpg'}
          alt='hello'
        />
        <MobileCategoriesChild
          title={'Kettles'}
          searchParams={'Kettle'}
          image={'/uploads/Banners/kettle-removebg-preview1.jpg'}
          alt='hello'
        />
        <MobileCategoriesChild
          title={'AC'}
          searchParams={'Air Conditioners'}
          image={'/uploads/Banners/accc-removebg-preview1.jpg'}
          alt='hello'
        />
        <MobileCategoriesChild
          title={'Washing Machine'}
          searchParams={'Washing Machines'}
          image={'/uploads/Banners/wasrrr-removebg-preview1.jpg'}
          alt='hello'
        />
      </MobileCategories>

      {/* <div className={prodGeneric.length<=0?'d-block':"home__row mb-3"}>
            {prodGeneric.length>0?
              <>
                {prodGeneric.map((prodgen) => {
                return(
                <Product className = "product_item"
                  key={prodgen._id}
                  id={prodgen._id}
                  title={prodgen.productName.charAt(0) + prodgen.productName.substring(1).toLowerCase()}
                  price={prodgen.regularPrice}
                  itemweight={prodgen.itemsize}
                  rating={5}
                  brand={prodgen.brandName}
                  fileName={prodgen.fileName}
                  image={`${prodgen.imageUrl}`}
                  discription={prodgen.discription}
                  salePrice={prodgen.salePrice}
                />
              )})}
              </>:
              <Loader />
            }
          </div> */}

      <div className='cat__type_c'>
        <CategoryComponent
          title='Deals for construction'
          key={32}
          playSpeed={13000}
          queryone={'Nails'}
          querytwo={'Iron rods'}
          querythree={'Carpentry Materials'}
          queryfour={'Bends '}
          clsname={'title_info_red'}
        />
      </div>
      {adsdata.length > 0 && (
        <ImageBannerComponent image={adsdata[adsdata.length - 1].imageUrl} />
      )}
      <CardComponent title={'Deals in Home Appliances'}>
        {prodGeneric.length > 0 ? (
          <>
            {prodGeneric.map((product) => (
              <CardChild
                key={product._id}
                id={product._id}
                image={`${product.imageUrl}`}
                fileName={product.fileName}
                title={
                  product.productName.charAt(0) +
                  product.productName.substring(1).toLowerCase()
                }
                price={product.regularPrice}
                weight={product.itemsize}
                brand={product.brandName}
                discription={product.discription}
                salePrice={product.salePrice}
              />
            ))}
          </>
        ) : (
          <Loader />
        )}
      </CardComponent>

      <CategoryTypeComponent
        categoryitem={'Construction'}
        title={'Building Materials for your home'}
      />
      <div className='mt-1 cas_card mb-3'>
        <h2 className='group__title m-0'>{'Popular items'}</h2>
        <div
          className={
            lowestPrices.length <= 0
              ? 'd-block position-relative'
              : 'cas_card_row mt-0 mx-0 mx-md-2 position-relative'
          }
        >
          {lowestPrices.length > 0 ? (
            <>
              {lowestPrices.map((low) => {
                return (
                  <Product
                    className='product_item'
                    key={low._id}
                    title={
                      low.productName.charAt(0) +
                      low.productName.substring(1).toLowerCase()
                    }
                    price={low.regularPrice}
                    image={`${low.imageUrl}`}
                    discount={low.discount}
                    available={low.available}
                  />
                )
              })}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <div className='cat__type_c'>
        <CategoryComponent
          key={1621}
          playSpeed={15000}
          title='Building deals'
          queryone='Roofing Sheets'
          querytwo='Iron rods'
          querythree='Cements'
          queryfour='Nails'
          clsname='title_info'
        />
      </div>
      <GroupComponent
        categoryitem='Home, Furniture & Appliances'
        title='Home Appliances for your home'
      />
    </>
  )
}

export default Home
