import React from 'react'
import '../css/CardComponent.css'
import useWindowDimensions from '../utils/useWindowDimension'
import { Link } from 'react-router-dom'

const CardComponent = ({ title, children }) => {
  const { width } = useWindowDimensions()
  return (
    <>
      {width <= 600 && (
        <div className='cards d-flex flex-column mt-2'>
          <h2 className='info top_sells'>{title}</h2>
          {children}
        </div>
      )}
    </>
  )
}

export default CardComponent

export const CardChild = ({
  id,
  title,
  image,
  price,
  weight,
  discription,
  salePrice,
  fileName
}) => {
  const to = {
    pathname: `/product/${title.replace(/ /g, '-')}`
  }
  return (
    <div className='d-flex border-top'>
      <img
        src={image}
        alt={'icon'}
        height={80}
        width={100}
        style={{ width: '20%' }}
      />
      <Link
        to={to}
        className='d-flex flex-column justify-content-center w-100 ms-3 text-dark'
        style={{ textDecoration: 'none' }}
      >
        <p className='mb-0 fw-bold'>{title}</p>
        <small>
          <span>GH</span>&#8373; {price.$numberDecimal}
        </small>
      </Link>
    </div>
  )
}
