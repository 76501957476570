import Sheet from 'react-modal-sheet';
import SubCategoriesComponents, { Content } from '../unused_components/SubCategoriesComponent';



function CustomBottomSheet({ show, handleClose, children }) {

  return (
    <>
      <Sheet isOpen={show} 
        rootId='app'
        onClose={handleClose} 
        snapPoints={[300, 200, 0]}
        initialSnap={1}
        tweenConfig={{ ease: 'easeOut', duration: 0.2 }}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content className='overflow-x-scroll ps-3 pb-3'>
            {children}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={handleClose}/>
      </Sheet>
    </>
  );
}

export default CustomBottomSheet;