import React, { useState } from 'react';
import './OffCanvasFilter.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SubCategoriesComponents, { Content } from './SubCategoriesComponent';

const OffCavasFilter=({ show, handleClose  })=> {

  return (
    <Offcanvas show={show} onHide={handleClose} placement='bottom'>
      <Offcanvas.Header closeButton className='border-bottom w-100'>
        <Offcanvas.Title className='text-warning'>Refine your search</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className='row'>
          <div className='col-6'>
          <div>
            <SubCategoriesComponents heading={'Sub Categories'}>
            <Content id={'skdl'} name={'Television'}/>
            <Content id={'skdls'} name={'Home Theatre'}/>
            <Content id={'skewdl'} name={'Microwaves'}/>
          </SubCategoriesComponents>
          </div>
          </div>
          <div className='col-6'>
            <div>
              <SubCategoriesComponents heading={'Brands'}>
                <Content id={'sdl'} name={'Hp'}/>
                <Content id={'skds'} name={'Nike'}/>
                <Content id={'skewd'} name={'Adidas'}/>
              </SubCategoriesComponents>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}


export default OffCavasFilter;