import React from 'react'
import {useRef} from 'react';
import "../css/CategoriesGroupComponent.css"
import { Link } from 'react-router-dom';

function CategoriesGroupComponent({ children }) {
    let windowWidth = useRef(window.innerWidth);
  return (
    <div className="row w-100 p-2 pb-0 h-100 justify-content-center">
        { children }
    {/* <div className="categories_group">
        <div>
        <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Electronics",
             }
         }}>
        <div className="categories_item d-block">
            <div className="categories_item_img">
                <img src="https://shop.caspian.com.gh/wp-content/uploads/2023/05/Electronics.png" />
            </div>
            
        </div>
        </Link>
        <div className="cat__title">Electronics</div>
        </div>
        
        <div className = "vertical">|</div>
        <div>
        <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Televisions",
             }
         }}>
        <div className="categories_item d-block">
            <div className="categories_item_img">
                <img src="https://shop.caspian.com.gh/wp-content/uploads/2023/05/televisions.png" />
            </div>
        </div>
        </Link>
        <div className="cat__title">Televisions</div>
        </div>
        
        <div className = "vertical">|</div>
        <div>
        <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Refrigerators",
             }
         }}>
        <div className="categories_item d-block">
            <div className="categories_item_img">
                <img src="https://shop.caspian.com.gh/wp-content/uploads/2023/05/Fridges.png" />
            </div>
        </div>
        </Link>
        <div className="cat__title">Refrigerators</div>
        </div>

        <div className = "vertical">|</div>
        <div>
        <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Air Conditioners",
             }
         }}>
        <div className="categories_item d-block">
            <div className="categories_item_img">
                <img src="https://shop.caspian.com.gh/wp-content/uploads/2023/05/AC.png" />
            </div>
            
        </div>
        </Link>
        <div className="cat__title">ACs</div>
        </div>
        
        <div className = "vertical">|</div>
        <div>
        <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Home, Furniture & Appliances",
             }
         }}>
        <div className="categories_item d-block">
            <div className="categories_item_img">
                <img src="https://shop.caspian.com.gh/wp-content/uploads/2023/05/home-appliances.png" />
            </div>
        </div>
        </Link>
        <div className="cat__title">Appliances</div>
        </div>
        
        <div className = "vertical">|</div>
        <div>
        <Link to={{
             pathname: "/cattype",
             state: {
                searchParam: "Building Materials",
             }
         }}>
        <div className="categories_item d-block">
            <div className="categories_item_img">
                <img src="https://shop.caspian.com.gh/wp-content/uploads/2023/05/hardware.png" />
            </div>
        </div>
        </Link>
        <div className="cat__title">Hardware</div>
        </div>
        
    </div> */}
    </div>
  )
}

export default CategoriesGroupComponent
