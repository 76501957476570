import React, { useState, useEffect, useRef } from 'react'
import { getBasketTotal } from '../utils/Reducer'
import { useStateValue } from '../utils/StateProvider'
import '../css/ShippingDetails.css'
import Address from '../utils/Address'
import PaymentMethod from '../components/PaymentMethod'
import CheckoutProduc from '../components/CheckoutProduc'
import CheckIcon from '@mui/icons-material/Check'
import useWindowDimensions from '../utils/useWindowDimension'
import { PaystackButton } from 'react-paystack'
import axios from '../utils/Axios'
import { useNavigate, useOutletContext } from 'react-router-dom'
import GHsymbol from '../components/GHsymbol'
import { Content } from '../unused_components/SubCategoriesComponent'
import AddQuantityComponent from '../utils/AddQuantityComponent'
import SelectMenu from '../components/SelectMenu'
import BillingAddress from '../components/BillingAddress'
import ShippingAddress from '../components/ShippingAddress'
import RadioGroupComponent from '../unused_components/RadioGroupComponent'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Accordion from 'react-bootstrap/Accordion'
import { Helmet } from 'react-helmet'
import PopUp from '../unused_components/PopUp'
import EventEmitter from '../utils/eventEmitter'

const deliveryMethods = [
  { label: 'Standard', value: 'standard' },
  { label: 'Same day', value: 'sd' }
  // {label:'2-4 days', value:'1-3d'},
]

function ShippingDetails() {
  const navigate = useNavigate()
  const { height, width } = useWindowDimensions()
  const [{ basket }, dispatch] = useStateValue()
  const [address, setAddress] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [city, setCity] = useState('')
  const [addresscity, setAddresscity] = useState('')
  const [region, setRegion] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('none')
  const [isAddressCompleted, setIsAddressCompleted] = useState(false)
  const [isBillingAddressCompleted, setIsBillingAddressComppleted] = useState(false)
  const [checkClassName, setCheckClassName] = useState('check')
  const [billingData, setBillingData] = useState({})
  const publicKey = 'pk_live_23887b445ee2e98941734146f04c0ca0360e383d'
  const [name, setName] = useState('')
  const [isOrderConfirmed, setIsOrderConfirmed] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [paymentRef, setPaymentRef] = useState('')
  const [weightfee, setWeightfee] = useState(0)
  const [paymentConfirmed, setPaymentConfirmed] = useState(false)
  // const [amount, setAmount] =  Math.floor(getBasketTotal(basket)) * 100;
  const [amount, setAmount] = useState(Math.floor(getBasketTotal(basket)) * 100)
  const currency = 'GHS'
  const [email, setEmail] = useState('')
  // const eventhandler = data => setAddressData(data);
  const billingHandler = (data) => setBillingData(data)
  const ref = useRef(null)
  const [productSize, setProductSize] = useState([])
  const [isBillingAddress, setIsBillingAddress] = useState(true)
  const [ispayment, setIsPayment] = useState(false)
  
  const [enablePayment, setEnablePayment] = useState(false)
  const [shippingAddressData, setShippingAddressData] = useState({})
  const [deliveryMethod, setDeliveryMethod] = useState('standard')
  const [isQuantityZero, setIsQuantityZero] = useState(false)
  const [instructions, setInstructions] = useState('')
  const [startDatex, setStartDate] = useState(new Date().toDateString())
  const [endDatex, setEndDate] = useState(new Date().toDateString())
  const [isSamedayDelivery, setIsSamedayDelivery] = useState(false)
  const [samdayFee, setsamdayFee] = useState(0)
  const [isFreeDelivery, setisFreeDelivery] = useState(false)
  const [show, setShow] = useState(false);
  const { details } = useOutletContext();
  const userDetails = details;
  const [processingOrder, setProcessingOrder] = useState(false);

  const onHide = () => setShow(false);
  const onShow = () => setShow(true);

  const today = new Date()
  let startDate = new Date()
  // tomorrow.setDate(today.getDate()+1);
  let endDate = new Date()
  // nextTwoDays.setDate(today.getDate()+3)
  //  function onChangeRegion(e) {

  //   let regionx = e.target.value;
  //    setRegion(e.target.value)

  //  } 
  const paystackAmount = 100 * Number(parseFloat(parseFloat(getBasketTotal(basket) + weightfee).toFixed(2) - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2)).toFixed(2) * 1;

  const freeDeliveryRegions = [
    'Greater Accra',
    'Ashanti',
    'Volta',
    'Eastern',
    'Western',
    'Central',
    'Oti',
    'Bono',
    'Bono East',
    'Upper East',
    'Northern',
    'Upper West',
    'Ahafo',
    'Western North'
  ];

  

  const freeDeliveryCities = [
    'kodjonya', 'odumasi', 'agormanya', 'kpongunor', 'nuaso', 'asitey', 'somanya',
    'akwamuman', 'akrade', 'atimpoku', 'tosca', 'akosombo', 'belekope', 'ankyi',
    'akuse', 'agamakope', 'adelakope', 'vra', 'akuse junction',
    'afienya', 'bawalasie',
  ];



  function isCurrentTimeBefore(stipulatedHour, stipulatedMinute) {
    // Get the current time
    const currentTime = new Date()

    // Create a Date object for the stipulated time on the same day
    const stipulatedTime = new Date(currentTime)
    stipulatedTime.setHours(stipulatedHour, stipulatedMinute, 0, 0) // set to stipulated hour and minute

    // Compare the two times
    return currentTime < stipulatedTime
  }

  useEffect(() => {
    async function getItemsSize() {
      await axios.get('/getproductsizes').then((response) => {
        const prodSizeLookups = response.data
        setProductSize(prodSizeLookups)
      })
    }
    getItemsSize()
    // EventEmitter.subscribe('details', details => setUserDetails(details));
  }, [])

  async function verifyPayment(paymentRef) {
    try {
      const orderdetails = {
        userId: userDetails.user ? userDetails.user : '',
        name: shippingAddressData.firstName + ' ' + shippingAddressData.lastName,
        email: shippingAddressData.email,
        phone: shippingAddressData.phone,
        paymentchanel: paymentMethod,
        id: shippingAddressData.orderId,
        orderitems: basket,
        orderNote: instructions,
        deliverydate: startDatex + ' ' + 'and' + ' ' + endDatex
      }
      const totalPrice_ = parseFloat(parseFloat(parseFloat(getBasketTotal(basket) + weightfee).toFixed(2) - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2)).toFixed(2) * 1;
      const discount = parseFloat(basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2);
      const details = [ orderdetails, shippingAddressData, totalPrice_, weightfee, discount];

      const response = await axios.post(`/verifypayment/${paymentRef}`, details);
      const responseData = await response.data;

      if (responseData.status === 'success') {
        navigate('/payment_success', {state: { data: shippingAddressData, totalPrice: totalPrice_ } });
      }
    }catch (err) {
      console.log(err);
    }
  }

  const orderMade = async () => {
    setProcessingOrder(true);
    const orderdetails = {
      userId: userDetails.user ? userDetails.user : '',
      name: shippingAddressData.firstName + ' ' + shippingAddressData.lastName,
      email: shippingAddressData.email,
      phone: shippingAddressData.phone,
      paymentchanel: paymentMethod,
      id: shippingAddressData.orderId, 
      orderNote: instructions,
      orderitems: basket,
      deliverydate: startDatex + ' ' + 'and' + ' ' + endDatex
    }
    const totalPrice_ = parseFloat(parseFloat(parseFloat(getBasketTotal(basket) + weightfee).toFixed(2) - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2)).toFixed(2) * 1;
    const discount = parseFloat(basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2);
    const details = [orderdetails, shippingAddressData, totalPrice_, weightfee, discount];  
    const response = await axios.post('/orders/new', details);
    const responseData = await response.data;
    if (responseData.status === 'success') {
      setProcessingOrder(false)
      navigate('/payment_success', {  
        state: { data: shippingAddressData, totalPrice: totalPrice_ }
      })
    }
  }

  const OnConfirmedOrder = (e) => {
    setIsOrderConfirmed(true)
    setPaymentConfirmed(false)
  }

  const toggleBillingAddress = (e) => {
    setIsBillingAddress(e.target.checked)
    setIsOrderConfirmed(false)
    setPaymentConfirmed(false)
  }
  // console.log('containing null: ', Object.values(us).includes(''))

  // useEffect(() => {
  //   let isNull = Object.values(shippingAddressData).includes(null)
  //   let isUndefined = Object.values(shippingAddressData).includes(undefined)
  //   let isEmpty = Object.values(shippingAddressData).includes('')
  //   if (!isNull && !isUndefined) {
  //     // setAddress(addressdata.address)
  //     setCity(shippingAddressData.city)
  //     // setFirstName(addressdata.firstName)
  //     // setLastName(addressdata.lastName)
  //     setRegion(shippingAddressData.region)
  //     // setAddresscity(addressdata.addresscity)
  //     setPhone(shippingAddressData.phone)
  //     // setIsAddressCompleted(true)
  //     setName(shippingAddressData.firstName + '' + shippingAddressData.lastName)
  //     setEmail(shippingAddressData.email)
  //     if (basket.length !== 0) {
  //       let fee = 0
  //       for (let i = 0; i < basket.length; i++) {
  //         for (let j = 0; j < productSize.length; j++) {
  //           if (basket[i].weight === productSize[j].itemsize) {
  //             if (
  //               basket[i].weight === 'Large size' &&
  //               basket[i].quantity == 1
  //             ) {
  //               fee += productSize[j].deliveryfee
  //             }
  //             if (
  //               basket[i].weight === 'Large size' &&
  //               basket[i].quantity > 1 &&
  //               basket[i].quantity < 4
  //             ) {
  //               fee += productSize[j].deliveryfee * 1.2
  //             }
  //             if (
  //               basket[i].weight === 'Large size' &&
  //               basket[i].quantity >= 4
  //             ) {
  //               fee += productSize[j].deliveryfee * 1.35
  //             }
  //             if (
  //               basket[i].weight === 'Medium size' &&
  //               basket[i].quantity >= 1 &&
  //               basket[i].quantity < 10
  //             ) {
  //               fee += productSize[j].deliveryfee + 20
  //             }
  //             if (
  //               basket[i].weight === 'Medium size' &&
  //               basket[i].quantity >= 10
  //             ) {
  //               fee += productSize[j].deliveryfee * 1.3
  //             }
  //             if (basket[i].weight === 'Small size') {
  //               fee += productSize[j].deliveryfee
  //             }
  //           }
  //         }
  //         setWeightfee(fee)
  //       }
  //     } else {
  //     }
  //   } else {
  //     // setIsAddressCompleted={true}
  //   }
  // }, [shippingAddressData, productSize, getBasketTotal(basket)])

  useEffect(() => {
    if (region === 'Greater Accra' || region === 'Eastern') {
      //   setStartDate(startDate.setDate(startDate.getDate()+1));
      //   setEndDate(endDate.setDate(endDate.getDate()+3));
      //   setIsSamedayDelivery(false)
      // }else{
      if (isCurrentTimeBefore(13, 10)) {
        // const stdate = new Date();
        startDate.setDate(today.getDate())
        setStartDate(startDate.toDateString())
        endDate.setDate(today.getDate() + 1)
        setEndDate(endDate.toDateString())
        setIsSamedayDelivery(true)
        // console.log(endDate)
      } else {
        // const stdate = new Date();
        startDate.setDate(today.getDate() + 1)
        setStartDate(startDate.toDateString())
        endDate.setDate(today.getDate() + 2)
        setEndDate(endDate.toDateString())
        setIsSamedayDelivery(false)
      }
    } else if (city === 'Ho' || city === 'Cape Coast' || city === 'Kumasi') {
      if (isCurrentTimeBefore(16, 10)) {
        // const stdate = new Date();
        startDate.setDate(today.getDate())
        setStartDate(startDate.toDateString())
        endDate.setDate(today.getDate() + 1)
        setEndDate(endDate.toDateString())
        setIsSamedayDelivery(true)
      } else {
        // const stdate = new Date();
        startDate.setDate(today.getDate() + 1)
        setStartDate(startDate.toDateString())
        endDate.setDate(today.getDate() + 2)
        setEndDate(endDate.toDateString())
        setIsSamedayDelivery(false)
      }
    } else {
      // const stdate = new Date();
      startDate.setDate(today.getDate() + 1)
      setStartDate(startDate.toDateString())
      endDate.setDate(today.getDate() + 2)
      setEndDate(endDate.toDateString())
      setIsSamedayDelivery(false)
    }
  })

  // useEffect(() => {
  //   // if (deliveryMethod === 'sd') {
  //   //   setsamdayFee(51)
  //   // } else {
  //   //   setsamdayFee(0)
  //   // }
  //   // const amount_ = Number(totalPrice - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0))
  //   const isFreeDeliveryZone = freeDeliveryRegions.includes(shippingAddressData.region)

  //   if (isFreeDeliveryZone) {
  //     setTotalPrice(Number(getBasketTotal(basket)).toFixed(2))
  //     setAmount(Math.floor(Number(getBasketTotal(basket))) * 100);
  //     setisFreeDelivery(true)
  //   }else if (isFreeDeliveryZone && Number(getBasketTotal(basket)).toFixed(2) < 700) {
  //     setTotalPrice((Number(getBasketTotal(basket)) + shippingAddressData.addressdelveryfee + weightfee).toFixed(2))
  //     setAmount(Math.floor(Number(getBasketTotal(basket)) + shippingAddressData.addressdelveryfee + weightfee) * 100)
  //   }else if (shippingAddressData.addressdelveryfee && weightfee > 0 && deliveryMethod == 'sd') {
  //     setTotalPrice((Number(getBasketTotal(basket)) + shippingAddressData.addressdelveryfee + weightfee + 51 ).toFixed(2))
  //     setAmount(Math.floor(Number(getBasketTotal(basket)) + shippingAddressData.addressdelveryfee + weightfee + 51) * 100)
  //   }else if (shippingAddressData.addressdelveryfee && weightfee > 0 && deliveryMethod !== 'sd' && !isFreeDeliveryZone) { 
  //     setTotalPrice((Number(getBasketTotal(basket)) + shippingAddressData.addressdelveryfee + weightfee ).toFixed(2))
  //     setAmount(Math.floor(Number(getBasketTotal(basket)) + shippingAddressData.addressdelveryfee + weightfee ) * 100)
  //   }else {
  //     setTotalPrice((Number(getBasketTotal(basket)) + 0).toFixed(2))
  //   }
  // }, [shippingAddressData, weightfee, getBasketTotal(basket), deliveryMethod])


  useEffect(() => {
    let deliveryCharge = 0;
    let cementDeliveryCharge = 0;
    const iron_rods = basket.filter(item => item.subCategory === 'Iron rods').filter(item => !item.title.includes('3mm welded mesh'));
    const cements = basket.filter(item => item.subCategory === 'Cements');
    if (iron_rods) {
      // console.log(iron_rods)
      
      iron_rods.forEach(item => {
        item.deliveryCharges.map(delivery => {
          if (item.quantity >= delivery.quantity[0] && item.quantity <= delivery.quantity[1]){
            deliveryCharge += delivery.charge;
          };
        })
      });
    }
    if (cements) {
      cements.forEach(item => {
        if (item.quantity >= 1 && item.quantity <=9 ) {
          let cementDeliveryCharge_ = item.quantity * 2;
          cementDeliveryCharge += cementDeliveryCharge_;
        }else if (item.quantity >= 10 && item.quantity <= 99) {
          let cementDeliveryCharge_ = item.quantity * 2;
          cementDeliveryCharge += cementDeliveryCharge_;
        };
      }); 
    };
    deliveryCharge += cementDeliveryCharge;
    if (shippingAddressData.locationFee) deliveryCharge += shippingAddressData.locationFee;
    setWeightfee(deliveryCharge);
  },[getBasketTotal(basket), shippingAddressData.locationFee])

 
  const componentProps = {
    email: shippingAddressData.email,
    amount: paystackAmount.toFixed(0),    
    currency,
    metadata: {
      name: `${shippingAddressData.firstName} ${shippingAddressData.lastName}`,
      phone: shippingAddressData.phone
    },
    publicKey,
    text: 'Pay Now',
    onSuccess: ({ reference }) => verifyPayment(reference),
    onClose: () =>
      alert(
        'Items are running out of stock, Hurry and buy yours now!\nThank you...'
      )
  }

  const payMentHandler = (paymentData) => setPaymentMethod(paymentData)
  const onChangeDelivery = (e) => setDeliveryMethod(e.target.value)

  // useEffect(()=>{
  //   if (isBillingAddress){
  //     ref.current?.scrollIntoView({behavior: 'smooth', block:'end'})
  //   }

  // },[isBillingAddress])

  useEffect(() => {
    let isEmpty = basket.filter(
      (product) => product.quantity === '' || product.quantity === 0
    ).length
    if (isEmpty > 0) {
      setIsQuantityZero(true)
    } else {
      setIsQuantityZero(false)
    }
  }, [getBasketTotal(basket)])

  const removeFromBasket = (id) => {
    dispatch({
      type: 'REMOVE_FROM_BASKET',
      id: id
    })
  }

  // useEffect(()=>{
  //   stickyElem = document.querySelector(".order_sum_container");

  //   currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
  //   window.onscroll = function() {

  //       /* Check if the current Y offset
  //       is greater than the position of
  //       the element */
  //       if(window.pageYOffset > currStickyPos) {
  //           stickyElem.style.position = "fixed";
  //           stickyElem.style.top = "0px";
  //       } else {
  //           stickyElem.style.position = "relative";
  //           stickyElem.style.top = "initial";
  //       }
  //   }
  // },[])

  if (basket.length <= 0) {
    return (window.location.href = '/')
  }

  const setQty = () => {};


  return (
    <>
      <Helmet>
        <title>Checkout</title>
        <meta name='title' content='Checkout' />
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className='w-100 shipping'>
        <div className='container d-flex justify-content-center'>
          <div ref={ref} className='row shipping_inner'>
            <div className='col-sm col-lg-7 col-xl-8'>
              {width < 992 && isQuantityZero && (
                <div className='px-1'>
                  <span style={{ color: 'red', fontSize: '13.5px', margin: 0 }}>
                    Select the product quantity or remove from cart...
                  </span>
                </div>
              )}
              <div className='review'>
                <div className='review_title_con'>
                  <h5 className='fw-bold'>Review items and shipping</h5>
                </div>
                <div className='review_items_con'>
                  {basket.map((product, idx) =>
                    <div key={idx} className='d-flex w-100 mb-3 align-items-center border-bottom'>
                      <div className='review_pro_image'>
                        <img src={product.imageUrl} alt='image' />
                      </div>
                      <div className='review_pro_details'>
                        <h6>{product.title}</h6>
                        <strong className={product.discount > 0 ? 'd-block danger' : 'd-block danger mb-2'}>
                          <span className='danger'>
                            <span>GH</span>&#8373; 
                          </span> {product.discount > 0 ? (product.price - ((product.discount/100)*product.price)).toFixed(2)*1 : product.price.toFixed(2)}
                        </strong>
                        {product.discount > 0 && <strong className='old_price d-block text-danger text-decoration-line-through p-0' style={{fontSize: '13px', fontStyle: 'italic', marginTop: 10 }} >
                          <span>
                            <span>GH</span>&#8373; 
                          </span> {parseFloat(product.price).toFixed(2)}
                        </strong>}
                        {/* <AddQuantityComponent id={product.id} price={product.price.$toDecimalNumber} basePrice={product.price.$toDecimalNumber} prod_quantity={product.quantity}/> */}
                        <div className='d-flex'>
                          <SelectMenu
                            id={product.id}
                            price={product.price}
                            basePrice={product.basePrice}
                            prod_quantity={product.quantity}
                            setQty={setQty}
                          />
                          {width <= 420 && basket.length > 1 && (
                            <div className='removeprobtn'>
                              <IconButton
                                size='large'
                                color='error'
                                onClick={() =>
                                  removeFromBasket(product['id'])
                                }
                              >
                                <DeleteIcon fontSize='medium' />
                              </IconButton>
                            </div>
                          )}
                          {width > 420 && basket.length > 1 && (
                            <button
                              className='removeBtn'
                              onClick={() => removeFromBasket(product.id)}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                        {(product.color) && <div>
                          {/* <span style={{fontSize: '14px', color: 'gray'}}>Color: </span> */}
                          <small>{product.color}</small>
                        </div>}
                      </div>
                    </div>
                  )}
                </div>
                <div className='review_delivery_con'>
                  <div className='review_delivery'>
                    {/* <RadioGroupComponent
                      groupLabel={'Delivery methods: '}
                      isSameday={isSamedayDelivery}
                      data={deliveryMethods}
                      value={deliveryMethod}
                      defaultValue={'standard'}
                      onChange={onChangeDelivery}
                    /> */}
                    <h6 className='fw-bold' style={{ fontSize: '14px' }}>
                      Delivery
                    </h6>
                    <p style={{ fontSize: '14px' }}>
                      Est. delivery: {startDatex} – {endDatex} Caspian Standard
                      Shipping <br />
                      {/* <span className="fw-bold"><GHsymbol />89</span> */}
                    </p>
                  </div>
                </div>
              </div>
              <ShippingAddress
                setEnablePayment={setEnablePayment}
                setFormData={setShippingAddressData}
                setDelivery={setWeightfee}
              />
              <Accordion flush>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>
                    Provide Special Instruction{' '}
                    <span style={{ color: 'gray', marginLeft: '12px' }}>
                      (Optional)
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <textarea
                      placeholder='eg. I will be here at this location at 2:00pm'
                      className='w-100'
                      spellCheck
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <PaymentMethod
                freeDeliveryCities={freeDeliveryCities}
                enablePayment={enablePayment}
                formdata={shippingAddressData}
                onChange={payMentHandler}
                isOrderConfirmed={isOrderConfirmed}
                ispayment={ispayment}
                setIsPayment={setIsPayment}
                setIsOrderConfirmed={setIsOrderConfirmed}
                setPaymentConfirmed={setPaymentConfirmed}
              />
            </div>
            <div className='col-lg-5 col-xl-4 mt-2 order_sum_container'>
              <div className='order_summary'>
                <h5 className=''>Order Summary </h5>
                <div className='order_summary_details'>
                  <div className='d-flex mb-1 itemtotal'>
                    <div className='summary_title'>
                      <h5>Items total ({basket.length})</h5>
                    </div>
                    <div>
                      <GHsymbol /> {parseFloat(getBasketTotal(basket)).toFixed(2)}
                    </div>
                  </div>
                  <div className='d-flex mb-1 delivery'>
                    <div className='summary_title'>
                      <h5 style={{ fontSize: '15px' }}>Delivery charges</h5>
                    </div>
                    <small className='d-block'> <GHsymbol /> {(weightfee).toFixed(2)}</small>
                  </div>
                  {/* {shippingAddressData.addressdelveryfee !== undefined && (
                    <div className='d-flex mb-1 delivery'>
                      <div className='summary_title'>
                        <h5 style={{ fontSize: '15px' }}>Delivery charges</h5>
                      </div>
                      <small className='d-block'> <GHsymbol /> {(weightfee).toFixed(2)}</small>
                      {isFreeDelivery ? 
                        <small className='d-block'>Free delivery</small> :
                        <small className='d-block'> <GHsymbol /> {( shippingAddressData.addressdelveryfee + weightfee + samdayFee).toFixed(2)}
                        
                      }
                    </div>
                  )} */}
                  <div className='d-flex justify-content-between'>
                    <h5 style={{ fontSize: '15px' }}>Discount</h5>
                    <span style={{color: 'red'}}><GHsymbol /> {parseFloat(basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2)}</span>
                  </div>
                </div>
                <div className='order_summary_total'>
                  <div className='d-flex justify-content-between'>
                    <h5 style={{ fontSize: '18px', fontWeight: 'bold' }}> Total Order </h5>
                    <strong> <GHsymbol /> {parseFloat(parseFloat(parseFloat(getBasketTotal(basket) + weightfee).toFixed(2) - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2)).toFixed(2)} </strong>
                  </div>
                  {ispayment && (
                    <div className='d-flex justify-content-center align-self-center mt-3'>
                      {paymentMethod !== 'PayStack' ? (
                        <button
                          className='confirmbtn pay w-100'
                          onClick={onShow}
                        >
                          Order Now
                        </button>
                      ) : (
                        <PaystackButton
                          className='confirmbtn w-100'
                          {...componentProps}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {width > 600 && isQuantityZero && (
                <div className='px-1'>
                  <span style={{ color: 'red', fontSize: '13.5px', margin: 0 }}>
                    Select the product quantity or remove from cart...
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <PopUp show={show} onHide={onHide} order={orderMade} processingOrder={processingOrder}/>
    </>
  )
}

export default ShippingDetails
