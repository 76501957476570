import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  OutlinedInput
} from '@mui/material'
import { Formik } from 'formik'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Forms from 'react-bootstrap/Form'
import StepLabel from '@mui/material/StepLabel'
import Step from '@mui/material/Step'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../graphs/Header'
import axios from '../../utils/Axios'
import { useTheme } from '@mui/material/styles'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete'
import { useLocation, useNavigate } from 'react-router-dom'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import './form.css'
import { useRef } from 'react'
import mockLookups from './mockLookups'
import { Editor } from 'react-draft-wysiwyg'
import ReactQuill from 'react-quill'
import SunEditor, { buttonList } from 'suneditor-react'

import suneditor from 'suneditor'
import plugins from 'suneditor/src/plugins'

// suneditor.create('sample', {

// })

const options = {
  plugins: plugins,
  buttonList: buttonList.complex
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(cat, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(cat) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

const steps = ['Product details', 'Prices', 'Specifications']
const label = { inputProps: { 'aria-label': 'publish checkbox' } }

const Form = () => {
  const theme = useTheme()
  const location = useLocation()
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const [fileName, setFilename] = useState([])
  const [CategoryLookups, setCategoryLookups] = useState([])
  const [lookups, setLookups] = useState([])
  const [departmentLookups, setDepartmentLookups] = useState([])
  const [newCategoryLookups, setNewCategoriesLookups] = useState([])
  const [newSubCategoriesLookups, setNewSubCategoriesLookups] = useState([])
  const [department, setDepartment] = useState('')
  const [newCategory, setNewCategory] = useState('')
  const [newSubCategories, setNewSubCategories] = useState('')
  const [subCatgories, setSubCatgories] = useState('')
  const [curCategory, setCurCategoryLookups] = useState([])
  const [Category, setCategory] = useState('')
  const [itemweight, setItemWeight] = useState([])
  const { id } = location.state || ''
  const [availabilty, setAvailability] = useState(true)
  // product update states values
  const [productName, setProductName] = useState('')
  const [brandName, setBrandName] = useState('')
  const [regularPrice, setRegularPrice] = useState('')
  const [salePrice, setSalePrice] = useState('')
  const [discription, setDiscription] = useState('')
  const [itemsize, setItemSize] = useState('')
  const [prodToUpdate, setProdToUpdate] = useState({})
  const [specLists, setSpecLists] = useState([
    { spec: 0, name: '', value: '', remove: false }
  ])
  const [severity, setSeverity] = useState('')
  const [colors, setColors] = useState('')
  const [discount, setDiscount] = useState('0')
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [images, setImages] = useState([])
  const imageRef = useRef()
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const found = lookups.find((data) => data.department === department)
    if (found) {
      setNewCategoriesLookups(found.categories)
      return
    }
  }, [department])

  useEffect(() => {
    const found = newCategoryLookups.find((data) => data.name === newCategory)
    if (found) {
      setNewSubCategoriesLookups(found.subCategories)
      return
    }
  }, [newCategory])

  useEffect(() => {
    async function getNewCategoryLookups() {
      await axios.get('/getcategoriesdata').then((response) => {
        const catLookups = response.data
        let dept_ = []
        catLookups.forEach((dept) => {
          dept_.push(dept.department)
        })
        setDepartmentLookups(dept_)
        setLookups(catLookups)
      })
    }
    getNewCategoryLookups()
  }, [])

  useEffect(() => {
    async function getCategoryLookups() {
      await axios.get('/categoryLookups').then((response) => {
        const categoryLookups = response.data
        setCategoryLookups(categoryLookups)
      })
    }
    getCategoryLookups()
  }, [])

  useEffect(() => {
    async function getItemWeight() {
      await axios.get('/getproductsizes').then((response) => {
        const itemW = response.data
        setItemWeight(itemW)
      })
    }
    getItemWeight()
  }, [])

  useEffect(() => {
    async function getProductById(id) {
      await axios.get(`/admin/products/${id}`).then((response) => {
        const chainData = response.data
        setProdToUpdate(chainData)
        setBrandName(chainData.brandName)
        setProductName(chainData.productName)
        setRegularPrice(chainData.regularPrice.$numberDecimal)
        setSalePrice(chainData.salePrice.$numberDecimal)
        setItemSize(chainData.itemsize)
        setCategory(chainData.category)
        setSubCatgories(chainData.subCategory)
        setDiscription(chainData.discription)
        setImageUrl(chainData.imageUrl)
        setDiscount(chainData.discount)
        // setFilename(chainData.fileName)
        setAvailability(chainData.available)
        setSpecLists(chainData.specLists);
        // console.log(chainData)
        // setColors(chainData.colors.join(','));
      })
    }
    if (id !== undefined) {
      getProductById(id)
    }
  }, [id])
  // console.log(specLists)
  // function to update product
  const updateProduct = async () => {
    try {
      specLists.forEach((_, idx) => {
        delete specLists[idx]['remove'];
        delete specLists[idx]['spec'];
        // if (specLists[idx].name === '' || specLists[idx].value === ''){
        //   specLists.splice(idx, 1);
        // }
      });
      const formData = new FormData()

      formData.append('productName', productName)
      formData.append('regularPrice', regularPrice)
      formData.append('salePrice', salePrice)
      formData.append('itemsize', itemsize)
      formData.append('category', Category)
      formData.append('subCategory', subCatgories)
      formData.append('brandName', brandName)
      formData.append('discription', discription)
      formData.append('department', department)
      formData.append('newCategory', newCategory)
      formData.append('newSubCategory', newSubCategories)
      formData.append('imageUrl', imageUrl)
      formData.append('specLists', JSON.stringify(specLists))
      formData.append('discount', discount)
      formData.append('available', availabilty)
      formData.append('colors', colors)

      for (let i = 0; i < fileName.length; i++) {
        formData.append('profileImg', fileName[i])
      }

      const resp = await axios.put(`/api/update/products/${id}`, formData)
      setMessage(resp.data.message)
      setShowSnackbar(true)

      setTimeout(() => {
        navigate('/productlist')
      }, 4000)
    } catch (err) {
      console.error(err)
    }
  }
  // console.log(colors.split(',').join(','))
  const checkoutSchema = yup.object().shape({
    productName: yup.string().required('required'),
    regularPrice: yup.string().required('required'),
    salePrice: yup.string().required('required'),
    brandName: yup.string().required('required'),
    discription: yup.string().required('required'),
    discount: yup.string().required('required')
  })

  const initialValues = {
    category: '',
    subcategory: '',
    productName: '',
    regularPrice: '',
    salePrice: '',
    itemsize: '',
    brandName: '',
    discription: '',
    fileName: fileName,
    discount: '0'
  }

  const onChangeFile = (e) => {
    let files = []
    const images = e.target.files
    Object.entries(images).forEach(([key, image]) => {
      files.push(image)
      setFilename((prevState) => [...prevState, image])
    })
  }

  const onChagedCategory = (e) => {
    setCategory(e.target.value)
  }

  useEffect(() => {
    async function getCurCategoryLookups() {
      for (let i = 0; i < CategoryLookups.length; i++) {
        if (CategoryLookups[i].name === Category) {
          setCurCategoryLookups(CategoryLookups[i].subCategory)
        }
      }
    }
    getCurCategoryLookups()
  }, [Category])

  const handleSubcatChange = (event) => {
    const {
      target: { value }
    } = event
    setSubCatgories(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  //function to add a new product
  const handleFormSubmit = async (values) => {
    try {
      specLists.forEach((_, idx) => {
        delete specLists[idx]['remove'];
        delete specLists[idx]['spec'];
        // if (specLists[idx].name === '' || specLists[idx].value === ''){
        //   specLists.splice(idx, 1);
        // }
      });
      const formData = new FormData()

      formData.append('productName', productName)
      formData.append('regularPrice', regularPrice)
      formData.append('salePrice', salePrice)
      formData.append('itemsize', itemsize)
      formData.append('category', Category)
      formData.append('subCategory', subCatgories)
      formData.append('brandName', brandName)
      formData.append('discription', discription)
      formData.append('department', department)
      formData.append('newCategory', newCategory)
      formData.append('newSubCategory', newSubCategories)
      formData.append('specLists', JSON.stringify(specLists))
      formData.append('discount', discount)
      formData.append('available', availabilty)
      formData.append('colors', colors)
      for (let i = 0; i < fileName.length; i++) {
        formData.append('profileImg', fileName[i])
      }

      await axios.post('/product/multi/new', formData).then((res) => {
        setMessage(res.data.message);
        setSeverity(res.data.severity);
        setShowSnackbar(true)
      })
      setBrandName('')
      setCategory('')
      setDepartment('')
      setProductName('')
      setDiscription('')
      setItemSize('')
    } catch (err) {
      setMessage(err.response.data.message)
      setSeverity(err.response.data.severity)
      setShowSnackbar(true)
      console.error(err)
      setMessage(err.response.data.message)
      setSeverity(err.response.data.severity)
      setShowSnackbar(true)
    }
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSnackbar(false)
  }

  function previewFile(file, callback) {
    const reader = new FileReader()

    reader.onloadend = () => {
      callback(reader.result)
    }
    reader.readAsDataURL(file)
  }
  // console.log(fileName);
  useEffect(() => {
    const images = [],
      fileReaders = []
    let isCancel = false
    if (fileName.length) {
      fileName.forEach((file) => {
        const fileReader = new FileReader()
        fileReaders.push(fileReader)
        fileReader.onload = (e) => {
          const { result } = e.target
          if (result) {
            images.push(result)
          }
          if (images.length === fileName.length && !isCancel) {
            setImages(images)
          }
        }
        fileReader.readAsDataURL(file)
      })
    }
    return () => {
      isCancel = true
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [fileName])

  const uploadFiles = (images) => {
    // const images = e.target.files;
    const uploader = document.getElementsByClassName('uploader')[0]

    Object.entries(images).map(([key, image_]) => {
      const upload = document.getElementById('upload')
      const fileName_ = document.createElement('p')
      const removeBtn = document.createElement('span')
      const fileListContainer = document.createElement('div')
      const image = document.createElement('img')
      removeBtn.addEventListener('click', () =>
        removeImage(uploader, fileListContainer, image, key)
      )
      fileName_.id = 'filename'
      fileListContainer.className = 'fileList'
      removeBtn.className = 'removeImage'
      fileName_.innerText = image_.name
      previewFile(image_, (value) => {
        image.src = value
        image.alt = upload.value.split('\\').pop().split('.')[0]
      })
      fileListContainer.appendChild(image)
      fileListContainer.appendChild(fileName_)
      fileListContainer.appendChild(removeBtn)
      uploader.appendChild(fileListContainer)
    })
  }

  const removeImage = (idx) => {
    let fileName_ = [...fileName]
    let images_ = [...images]
    fileName_.splice(idx, 1)
    images_.splice(idx, 1)
    setFilename(fileName_)
    setImages(images_)
  }

  const handleSpecNameChange = (e, index) => {
    const specLists_ = [...specLists]
    specLists_[index]['spec'] = index
    specLists_[index]['name'] = e.target.value
    // specLists_[index]['remove'] = true;
    setSpecLists(specLists_)
  }

  const handleSpecValueChange = (e, index) => {
    const specLists_ = [...specLists]
    specLists_[index]['spec'] = index
    specLists_[index]['value'] = e.target.value
    // specLists_[index]['remove'] = true;
    setSpecLists(specLists_)
  }

  const addSpec = (index) => {
    specLists[index]['remove'] = true
    setSpecLists((prevState) => [
      ...prevState,
      {
        spec: prevState.length,
        name: '',
        value: ''
      }
    ])
  }
  const removeSpec = (spec_) => {
    let spec = specLists.filter((specObj) => specObj.spec !== spec_)
    spec.map((specObj, index) => (specObj['spec'] = index))
    setSpecLists(spec)
  }

  const handleBeforeUpload = (files, info, uploadHandler) => {
    console.log(files, info)
    uploadHandler(files)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Box m='20px'>
        <div className='d-flex justify-content-between'>
          {id !== undefined ? (
            <Header title='Update Product' subtitle='update product' />
          ) : (
            <Header title='Add Product' subtitle='Add a new product' />
          )}
          {id !== undefined ? (
            <Box display='flex' justifyContent='end' mt='20px'>
              <Button
                onClick={updateProduct}
                color='secondary'
                variant='contained'
              >
                Update
              </Button>
            </Box>
          ) : (
            <Box display='flex' justifyContent='end' mt='20px'>
              <Button
                color='secondary'
                variant='contained'
                onClick={handleFormSubmit}
              >
                Add product
              </Button>
            </Box>
          )}
        </div>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(2, minmax(0, 1fr))'
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' }
                }}
              >
                <Box
                  display={'grid'}
                  gap={'20px'}
                  gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                  sx={{
                    '& > div': {
                      gridColumn: isNonMobile ? undefined : 'span 4'
                    }
                  }}
                >
                  <Typography>Product details</Typography>
                  <Box
                    display={'grid'}
                    gap={'20px'}
                    gridTemplateColumns='repeat(6, minmax(0, 1fr))'
                    gridAutoRows={'52px'}
                    sx={{
                      '& > div': {
                        gridColumn: isNonMobile ? undefined : 'span 4'
                      }
                    }}
                  >
                    <TextField
                      fullWidth
                      id='outlined-select-currency'
                      select
                      label='Select New Department'
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      sx={{ gridColumn: 'span 2' }}
                    >
                      {departmentLookups.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      id='outlined-select-currency'
                      select
                      label='Select New Category'
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      sx={{ gridColumn: 'span 2' }}
                    >
                      {newCategoryLookups.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      id='outlined-select-New-SubCategory'
                      select
                      label='Select New SubCategory'
                      value={newSubCategories}
                      onChange={(e) => setNewSubCategories(e.target.value)}
                      sx={{ gridColumn: 'span 2' }}
                    >
                      {newSubCategoriesLookups.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      select
                      variant='filled'
                      type='text'
                      label='Category'
                      onChange={(e) => setCategory(e.target.value)}
                      value={Category}
                      name='category'
                      sx={{ gridColumn: 'span 2' }}
                    >
                      {CategoryLookups.map((cat) => (
                        <MenuItem key={cat.name} value={cat.name}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      select
                      variant='filled'
                      type='text'
                      label='Sub-Category'
                      onChange={(e) => setSubCatgories(e.target.value)}
                      value={subCatgories}
                      name='subcategory'
                      sx={{ gridColumn: 'span 2' }}
                    >
                      {curCategory.map((cat) => (
                        <MenuItem key={cat} value={cat}>
                          {cat}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      select
                      variant='filled'
                      type='text'
                      label='Item Size'
                      onChange={(e) => setItemSize(e.target.value)}
                      value={itemsize}
                      name='itemsize'
                      sx={{ gridColumn: 'span 2' }}
                    >
                      {itemweight.map((weight) => (
                        <MenuItem key={weight.itemsize} value={weight.itemsize}>
                          {weight.itemsize}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Product Name'
                      onChange={(e) => setProductName(e.target.value)}
                      value={productName}
                      name='productName'
                      sx={{ gridColumn: 'span 4' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Brand Name'
                      onChange={(e) => setBrandName(e.target.value)}
                      value={brandName}
                      name='brandName'
                      sx={{ gridColumn: 'span 2' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='number'
                      label='Regular Price'
                      onChange={(e) => setRegularPrice(e.target.value)}
                      value={regularPrice}
                      name='regularPrice'
                      sx={{ gridColumn: 'span 2' }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='number'
                      label='Sales Price'
                      onChange={(e) => setSalePrice(e.target.value)}
                      value={salePrice}
                      name='salePrice'
                      sx={{ gridColumn: 'span 2 ' }}
                    />

                    <TextField
                      fullWidth
                      variant='filled'
                      type='number'
                      label='Discount'
                      onChange={(e) => setDiscount(e.target.value)}
                      value={discount}
                      name='discount'
                      sx={{ gridColumn: 'span 2', marginBottom: '12px' }}
                    />
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      sx={{ gridColumn: 'span 2' }}
                    >
                      <Typography fontSize={'medium'}>Availability</Typography>
                      <Checkbox
                        {...label}
                        color={availabilty ? 'success' : 'secondary'}
                        size='medium'
                        value={availabilty}
                        checked={availabilty}
                        onChange={(e) => setAvailability(e.target.checked)}
                      />
                    </Box>
                    <Box sx={{ gridColumn: 'span 4' }}>
                      <TextField
                        fullWidth
                        variant='filled'
                        type='text'
                        label='Colors'
                        onChange={(e) => setColors(e.target.value)}
                        value={colors}
                        name='colors'
                      />
                    </Box>
                  </Box>
                  <Typography className='spe'>Specifications</Typography>
                  <Box
                    display={'grid'}
                    gap={'20px'}
                    width={'100%'}
                    gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                    gridAutoRows={'52px'}
                    sx={{
                      '& > div': {
                        gridColumn: isNonMobile ? undefined : 'span 6'
                      },
                      height: '400px',
                      overflowY: 'scroll'
                    }}
                  >
                    {specLists.map((spec, index) => (
                      <Box
                        key={spec.spec}
                        display={'flex'}
                        flexDirection={'row'}
                        gap={'12px'}
                        width={'100%'}
                        sx={{ gridColumn: 'span 6', height: 'fit-Content' }}
                      >
                        <TextField
                          fullWidth
                          variant='filled'
                          type='text'
                          label='Name'
                          onChange={(e) => handleSpecNameChange(e, index)}
                          value={specLists[index]['name']}
                          name='productName'
                          sx={{ gridColumn: 'span 3' }}
                        />
                        <TextField
                          fullWidth
                          variant='filled'
                          type='text'
                          label='Value'
                          onChange={(e) => handleSpecValueChange(e, index)}
                          value={specLists[index]['value']}
                          name='productName'
                          sx={{ gridColumn: 'span 2' }}
                        />
                        {specLists[index].remove ? (
                          <Button
                            color='inherit'
                            onClick={() => removeSpec(index)}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            color='inherit'
                            onClick={() => addSpec(index)}
                          >
                            Add
                          </Button>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box
                  display={'grid'}
                  gap={'20px'}
                  gridTemplateColumns='repeat(1, minmax(0, 1fr))'
                  sx={{
                    '& > div': {
                      gridColumn: isNonMobile ? undefined : 'span 4'
                    }
                  }}
                >
                  <div>
                    <div className='c-editor-wrapper'>
                      <Typography>Product Description</Typography>
                      <SunEditor
                        setOptions={options}
                        onSave={(contents) => setDiscription(contents)}
                        setContents={discription}
                        onImageUpload={(
                          targetImgElement,
                          index,
                          state,
                          imageInfo,
                          remainingFilesCount
                        ) =>
                          console.log(
                            targetImgElement,
                            index,
                            state,
                            imageInfo,
                            remainingFilesCount
                          )
                        }
                        onImageUploadBefore={handleBeforeUpload}
                      />
                    </div>
                    <div className='uploader'>
                      <div className='caspian_uploader'>
                        <label
                          htmlFor='upload'
                          className='d-flex flex-column align-items-center justify-content-center'
                        >
                          <AddPhotoAlternateIcon fontSize='large' />
                          <span>Upload Image</span>
                        </label>
                        <input
                          className='filefield'
                          type='file'
                          id='upload'
                          multiple
                          filename='profileImg'
                          onChange={onChangeFile}
                        />
                      </div>
                      {imageUrl !== '' && (
                        <div className='previews'>
                          <div className='fileList'>
                            <img
                              src={imageUrl}
                              alt={prodToUpdate['fileName']}
                              id='image'
                            />
                            <p className='filename'>{prodToUpdate['fileName']}</p>
                            <span className='removeImage' onClick={() => setImageUrl('')}></span>
                          </div>
                        </div>
                      )}
                      {images.length > 0 && (
                        <div className='preview'>
                          {images.map((image, idx) => (
                            <div key={idx} className='fileList'>
                              <img
                                src={image}
                                alt={fileName[idx].name}
                                id='image'
                              />
                              <p className='filename'>{fileName[idx].name}</p>
                              <span
                                className='removeImage'
                                onClick={() => removeImage(idx)}
                              ></span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </Box>
            </form>
          )}
        </Formik>

        <div>
          {/* {activeStep === steps.length ?
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
          </>:
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} > Back </Button>
            <Button color="inherit" onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'} </Button>
          </Box>
        } */}
        </div>
      </Box>
    </>
  )
}

export default Form
