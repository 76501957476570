import React, { useEffect, useState } from 'react'
import '../css/CheckoutProduc.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
// import Button from '@mui/material/Button';
import { useStateValue } from '../utils/StateProvider'
import AddQuantityComponent from '../utils/AddQuantityComponent'
import useWindowDimensions from '../utils/useWindowDimension'
import GHsymbol from './GHsymbol'
import axios from '../utils/Axios'
import SelectMenu from './SelectMenu'

function CheckoutProduc({
  id,
  title,
  image,
  price,
  basePrice,
  quantity,
  rating,
  discount
}) {
  const [{ basket }, dispatch] = useStateValue()
  const [addressLocation, setAddressLocation] = useState([])
  const [lookupsObj, setLookupsObj] = useState({})
  const [locLookups, setLocLookups] = useState([])
  const [shippingData, setshippingData] = useState({
    region: '',
    city: '',
    addressdelveryfee: 0
  })
  const { width } = useWindowDimensions()
  const removeFromBasket = () => {
    dispatch({
      type: 'REMOVE_FROM_BASKET',
      id: id
    })
  }
  // console.log('checkoutprod: ', quantity)

  useEffect(() => {
    async function getLocationLookups() {
      await axios.get('/getlocationlookups').then((response) => {
        const locationLookups = response.data
        setAddressLocation(locationLookups)
      })
    }
    getLocationLookups()
  }, [])

  useEffect(() => {
    async function getTown() {
      let lookups = []
      for (let i = 0; i < addressLocation.length; i++) {
        if (addressLocation[i].region === shippingData.region) {
          for (var lockey in addressLocation[i].townLookUps) {
            lookups.push(lockey)
          }
          setLocLookups(lookups)
          setLookupsObj(addressLocation[i].townLookUps)
        }
      }
    }
    getTown()
  }, [shippingData.region])

  useEffect(() => {
    let fee = lookupsObj[shippingData.city]
    setshippingData({ ...shippingData, addressdelveryfee: fee })
  }, [lookupsObj, shippingData.city])

  const onChangeRegion = (e) =>
    setshippingData({ ...shippingData, region: e.target.value })
  const onChangeCity = (e) =>
    setshippingData({ ...shippingData, city: e.target.value })
  // let price_ = parseFloat((price).replace(/,/g, ''));

  const setQty = () => {}
  return (
    <div className='container-fluid px-2 bg-white border my-1'>
      <div className='row'>
        <div className=' col-12'>
          <div className='row'>
            <div className='col-4 col-sm-3 col-lg-3 col-xl-2 pt-lg-2'>
              <img
                className='checkoutProduct__image'
                src={image}
                alt='product_image'
              />
            </div>
            <div className='col-8 col-sm-8 col-lg-9 col-xl-10 pt-lg-2'>
              <div className='row align-items-center pro_chek_body'>
                <div className='checkoutProduct__info col-xl-4 col-sm-4 p-sm-0'>
                  <h6 className='checkoutProduct__tile fw-bold'>
                    {title.charAt(0) + title.substring(1).toLowerCase()}
                  </h6>
                  <strong className='danger in_stock pt-1'>Available</strong>
                </div>
                <div className='d-flex flex-column align-items-sm-center justify-content-center col-xl-4 col-sm-8 col-lg-7'>
                  <div className='d-flex'>
                    <p className='qty_cart d-none d-sm-block mb-0 me-2 align-self-center'>Qty</p>
                    {/* <AddQuantityComponent 
                                        id={id}
                                        price={price}
                                        basePrice={basePrice}
                                        prod_quantity={quantity}
                                        /> */}
                    <SelectMenu
                      id={id}
                      price={price}
                      basePrice={basePrice}
                      prod_quantity={quantity}
                      setQty={setQty}
                    />
                  </div>
                  <small className='text-muted'>
                    Shipping from any of our outlets.
                  </small>
                  {/* <button className="removeBtn" onClick={removeFromBasket} style={{}}>Remove</button> */}
                  <div></div>
                </div>
                <div className='col-xl-4 mt-1 p-sm-0 mt-xl-0'>
                  <div className='checkoutProduct__price'>
                    <strong className='fs-6' style={{ color: 'green' }}>
                      <GHsymbol /> {discount > 0 ? (price - (discount/100)*price).toFixed(2) : price.toFixed(2)}
                    </strong>
                    {discount > 0 && <strong
                      className={'d-block text-danger text-decoration-line-through'}
                      style={{ fontSize: '13px', fontStyle: 'italic' }}>
                      <span><GHsymbol /> {parseFloat(price).toFixed(2)}</span>
                    </strong>}

                    {/* <strong className='fs-6'><GHsymbol />{(price*1 + (price*1 * 0.10)).toFixed(2)}</strong> */}
                    {/* <Form className='w-100'>
                                        <Form.Group className='mb-3' sm="12" md="12" as={Col}>
                                            <FloatingLabel controlId='valSelect' label='Region'>
                                            <Form.Select value={shippingData.region} onChange={onChangeRegion} className='bg-light select_font' required>
                                                <option />
                                                {addressLocation.map(loc=>
                                                <option key={loc.region} label={loc.region} value={loc.region}/>  
                                                )}
                                            </Form.Select>
                                            </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group className='mb-3' sm="12" md="12" as={Col}>
                                            <FloatingLabel controlId='valSelectCity' label='City' >
                                            <Form.Select value={shippingData.city} onChange={onChangeCity} className='bg-light select_font' required>
                                                <option></option>
                                                {locLookups.map(loc=>
                                                <option key={loc} label={loc} value={loc}/>  
                                                )}
                                            </Form.Select>
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Form> */}
                  </div>

                  <p
                    style={{
                      fontSize: '14px',
                      color: 'gray',
                      marginBottom: '-2px'
                    }}
                  >
                    Caspian Standard Shipping
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        <button className='removeBtn' onClick={removeFromBasket}>
          Remove
        </button>
      </div>
      {/* <div className="d-flex align-items-center">
                <button className="removeBtn" onClick={removeFromBasket}>Remove</button>
                <AddQuantityComponent 
                    id={id}
                    price={price}
                    basePrice={basePrice}
                    prod_quantity={quantity}
                    />
                <div>
                    
                </div>
            </div> */}
    </div>
  )
}

export default CheckoutProduc
