import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom'
import UserLayout from '../layouts/UserLayout'
import Home from '../pages/Home'
import ShippingDetails from '../pages/ShippingDetails'
import Login from '../pages/Login'
import Signupform from '../pages/Signupform'
import Checkout from '../pages/Checkout'
import AllCategories from '../pages/AllCategoriesComponent'
import CategoryTypeProducts from '../components/CategoryTypeProducts'
import CategoryProductsComponent from '../pages/CategoryProductsComponent'
import SearchProducts from '../pages/SearchProducts'
import PaymentSuccessComponent from '../pages/PaymentSuccessComponent'
import VendorsPage from '../pages/VendorsPage'
import Product_details from '../pages/Product_details'
import { FeedbackUpdated } from '../components/Feedback'
import VendorProfilePage from '../pages/VendorProfilePage'
import About from '../pages/About'
import { loadContents } from '../APIs/contentAPI'
import ErrorPage from '../Errors/ErrorPage'
import { loadCategoryType } from '../APIs/cateTypeAPI'
import AddSeller from '../scenes/AddSeller'
// import AdminLogin from '../pages/AdminLogin'

import { loadOrders, loadProduct, loadSearchProduct } from '../APIs/ProductsAPI';
import Orders from '../pages/Orders'
import CustomerLayout from '../layouts/CustomerLayout'
import RecentlyViewProducts from '../pages/RecentlyViewProducts'
import Accounts from '../pages/Accounts'
import RateProduct from '../pages/RateProduct'
import ReturnRequest from '../pages/ReturnRequest'
import MainError from '../Errors/MainError'
import OrderDetails from '../pages/OrderDetails'
import { loadOrderDetails } from '../APIs/OrdersAPI'



const myRouter = (loggedIn) => {
  const userRouter = createBrowserRouter(
    createRoutesFromElements(
      <>
        {loggedIn.role !== 'user' && (
          <>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Signupform />} />
            {/* <Route path='/admin/login' element={<AdminLogin />} /> */}
            
          </>
        )}

        <Route path='/payment_success' element={<PaymentSuccessComponent />} />
        
        <Route path='/' element={<UserLayout />} errorElement={<MainError />} >
          <Route index element={<> <FeedbackUpdated /> <Home /></>}  />
          <Route path='product/:productSeo' element={<Product_details />} loader={params => loadProduct(params.params.productSeo)} errorElement={<ErrorPage />} />
          <Route path='checkout' element={<ShippingDetails />} />
          <Route path='cart' element={<Checkout />} />
          <Route path='category/:catename' element={<CategoryProductsComponent />} />
          <Route path='store' element={<AllCategories />} />
          <Route path='category/:catetype/:type' element={<CategoryTypeProducts />} loader={(params) => loadCategoryType(params.params.type)} />
          <Route path='searchedproduct' element={<SearchProducts />} />
          <Route path='vendor' element={<VendorsPage />} />
          <Route path='vendor/:name' element={<VendorProfilePage />} />
          <Route path='/:content' element={<About />} loader={(params) => loadContents(params.params.content)} errorElement={<ErrorPage />}/>
          {loggedIn.role === 'user' && 
            <Route path='/customer/' element={<CustomerLayout />}>
              <Route index element={<></>} />
              <Route path='orders/:id' element={<Orders />} />
              <Route path='orders/details/:id' element={<OrderDetails />} loader={params => loadOrderDetails(params.params.id)}/>
              <Route path='recently-viewed' element={<RecentlyViewProducts />}/>
              <Route path='account' element={<Accounts details={loggedIn}/>} />
              <Route path='review-product/:name' element={<RateProduct />} />
              <Route path='return-request/:name' element={<ReturnRequest />} />

            </Route>
          }
        </Route>
        
      </>
    )
  )
  return userRouter
}

export default myRouter
