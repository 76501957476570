import React from 'react'
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom'
// import { TiInfoLargeOutline } from 'react-icons/ti';
import InfoIcon from '@mui/icons-material/Info';
import Button from 'react-bootstrap/Button';
import not_found from '../images/not_found_page.svg';

const MainError = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const refreshPage=()=>navigate('/');
  if (isRouteErrorResponse(error)) {
    return (
      <>
        <div className='d-flex flex-column py-3' style={{alignItems:'center', justifyContent:'center', height:'100%'}}>
          <img src={not_found} height={250}/>
          <h1 className=' text-danger text-uppercase my-1'><i>{'Opps'}!</i></h1>
          <p className='fs-2 text-danger text-capitalize mb-3'><i>{'This Page could not be found!'}</i></p>
          <Button onClick={refreshPage} size='md' variant='outline-danger'>Refresh</Button>
        </div>
      </>
    );
  }
}

export default MainError;
