import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";





const RecentlyViewProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const data = localStorage.getItem('tui');
    if (data !== null) setProducts(JSON.parse(data));
  },[])

  return (
    <>
    <Helmet>
      <title>Recently Viewed Products</title>
        <meta name='Recently Viewed Products'/>
        <meta name='robots' content='noindex, nofollow' />
    </Helmet>
    <div className="h-100">
      <div className="">
        <h3>Your Browsing History</h3>
        <p>You viewed this products recently. We use them to personalize recommendations.</p>
      </div>
      <div className='row category_products m-0'>
        {products.length > 0 ? 
          <>
            {products.map((product) =>
              <div key={product._id} className='col-sm-6 col-md-4 p-1 px-0 px-md-1 category_card_container'>
              <Link to={`/product/${product.productName.replace(/ /g, '-')}`} className='cat_card d-sm-flex d-md-block h-100 bg-white p-2'>
                <div className='cat_card_img_con'>
                  <img src={product.imageUrl} alt={product.productName} className='text-lowercase' style={{ fontSize: '14px' }}/>
                </div>
                <div className='cat_card_body w-100 '>
                  <div className='d-sm-flex d-md-block justify-content-start'>
                    <h6 className='fw-bold m-0 text-md-center mt-md-2'>
                      {product.productName.charAt(0) + product.productName.substring(1).toLowerCase()}
                    </h6>
                  </div>
                  <div className='w-100 d-flex flex-md-column align-items-md-center align-items-sm-start justify-content-md-end'>
                    <div className='cat_price_con'>
                      <strong className='d-block danger'>
                        <span className='danger'>
                          <span>GH</span>&#8373;{' '}
                        </span>
                        {product.discount && product.discount > 0
                          ? (((100 - parseInt(product.discount)) / 100) * product.regularPrice.$numberDecimal).toFixed(
                              2
                            )
                          : product.regularPrice.$numberDecimal}
                      </strong>
                      <strong
                        className='d-block text-danger text-decoration-line-through'
                        style={{ fontSize: '13px', fontStyle: 'italic' }}
                      >
                        <span>
                          {product.discount && parseInt(product.discount) > 0 ? (
                            <span>GH&#8373;</span>
                          ) : null}
                        </span>
                        {product.discount &&
                          product.discount > 0 &&
                          (product.regularPrice.$numberDecimal * 1 + product.regularPrice.$numberDecimal * 1 * 0.1).toFixed(2)}
                      </strong>
                    </div>
                    <div className='d-flex justify-content-center add__cat_con w-md-100 w-sm-50'>
                      <button className='mt-sm-2 mt-md-0 cat_view__cat' >Remove from view</button>
                    </div>
                  </div>
                </div>
              </Link>
            </div>)}
          </>:
          <div className="d-flex justify-content-center align-items-center h-100"><p>Browsing history empty!</p></div>
        }
      </div>
    </div>
    </>
  )
};


export default RecentlyViewProducts;