import React, { useEffect, useRef, useState } from 'react'
import '../css/CategoryProductsComponent.css'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useOutletContext
} from 'react-router-dom'
import axios from '../utils/Axios'
// import { useStateValue } from '../utils/StateProvider'
import GHsymbol from '../components/GHsymbol'
import Loader from '../components/Loader'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import OffCavasFilter from '../unused_components/OffCanvasFilter'
// import CategoriesGroup from "../components/CategoriesGroup";
// import CategoriesGroupComponent from "../CategoriesGroupComponent";
import { getBasketTotal } from '../utils/Reducer'
import EventEmitter from '../utils/eventEmitter'
import { Helmet } from 'react-helmet'
import CustomBottomSheet from '../components/CustomBottomSheet'
import Availability from '../components/Availability'

var product_id = ''
EventEmitter.subscribe('remove_product', (id) => (product_id = id))

const CategoryProductsComponent = () => {
  const location = useLocation()
  // const search = location.search;
  // const searchGate = location.state.category;
  const [products, setProducts] = useState({
    catitem: [],
    totalPages: 0,
    currentPage: 1,
    total: 0
  })
  const [filter, setFilter] = useState(false)
  const [subCategory, setSubCategory] = useState([])
  const [brands, setBrands] = useState([])
  const { basket, dispatch } = useOutletContext()
  const [subCategoryProducts, setSubCategoryProducts] = useState([])
  const [subCategoryCheckBox, setSubCategoryCheckBox] = useState()
  const [brandsCheckbox, setBrandsCheckbox] = useState()
  const [productBrands, setProductBrands] = useState([])
  const [filterProducts, setFilterProducts] = useState({})
  const [page, setPage] = useState(1)
  const params = useParams()
  // const categoryVal = new URLSearchParams(search).get("categoryvalue");

  useEffect(() => {
    let isMounted = true
    axios
      .get(`api/${params.catename.replace(/-/g, ' ')}?page=${page}&limit=12`)
      .then((res) => {
        if (isMounted) setProducts(res.data)
      })
    return () => {
      isMounted = false
    }
  }, [page, params.catename])

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    let subCategory = []
    let productBrands = []
    products.catitem.forEach((product) => {
      subCategory.push(product['subCategory'])
      productBrands.push(product['brandName'].trim())
    })

    const subCategory_ = subCategory.filter(
      (category, index) => subCategory.indexOf(category) === index
    )
    const productBrands_ = productBrands.filter(
      (brand, index) => productBrands.indexOf(brand) === index
    )
    setSubCategory(subCategory_)
    setBrands(productBrands_)

    setSubCategoryCheckBox(Array(subCategory_.length).fill(false))
    setBrandsCheckbox(Array(productBrands_.length).fill(false))
  }, [products])

  if (products.catitem.length <= 0) {
    return <Loader />
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const toggleFiltter = () => setFilter(true)
  const handleClose = () => setFilter(false)

  const handleCheckBox = (e, index) => {
    const newValues = [...subCategoryCheckBox] //copies sub category boxes array of false;
    newValues[index] = !newValues[index] //toggle the specific sub category checkbox

    let storedCateProd = []

    setSubCategoryCheckBox(newValues)
    products.catitem.forEach((product) => {
      if (
        newValues[index] === true &&
        product['subCategory'] === e.target.value
      ) {
        storedCateProd.push(product)
        setSubCategoryProducts([...subCategoryProducts, storedCateProd])
      } else if (
        newValues[index] === false &&
        product['subCategory'] === e.target.value
      ) {
        let subCategoryProducts_ = [...subCategoryProducts]
        subCategoryProducts_.forEach((product) => {
          // storedCateProd=[];
          let filter = product.filter(
            (item) => item['subCategory'] !== e.target.value
          )
          if (filter.length > 0) {
            storedCateProd = []
            storedCateProd.push(filter)
            setSubCategoryProducts(storedCateProd)
          } else {
            setSubCategoryProducts(storedCateProd)
          }
        })
        // if (subCa)
        // subCategoryProducts_.forEach(subCategory=>{
        //   if (Array.isArray(subCategory)===true){
        //     setSubCategoryProducts(storedCateProd)
        //   }else {
        //     let filter = subCategoryProducts.filter(item=>item['subCatgory'][0]!==e.target.value);
        //     setSubCategoryProducts(filter)

        //   }
        // })
      }
    })
  }
  const handleBrandsCheckBox = (brand, brandHeading, index) => {
    const newBrandValues = [...brandsCheckbox] //copies sub category boxes array of false;
    newBrandValues[index] = !newBrandValues[index] //toggle the specific sub category checkbox
    if (brandHeading.toLowerCase() === 'brands') {
      // console.log('on brands: ', brand, brandHeading)
      // let storedBrandProd = [];
      setBrandsCheckbox(newBrandValues)
      products.forEach((product) => {
        if (product['brandName'] === brand) {
          setProductBrands([...productBrands, brand])
        }
      })
    }
  }

  // if (searchGate===undefined)return window.location.href = '/';
  // 'We offer various product as well as accessories and a lot more. Low price when you shop on casspian.com. Buy or Order now. '
  return (
    <>
      <Helmet>
        <title>
          {params.catename === 'Construction'
            ? 'Building Materials | Shop Building Materials in Ghana - Caspian Outlets Limited'
            : params.catename === 'Electronics'
            ? 'Electronics | Buy Video and Audio Appliances Online in Ghana - Caspian Outlets Limited'
            : params.catename === 'Books'
            ? 'Shop books online in Ghana - Caspian Outlets Limited'
            : params.catename.replace(/-/g, ' ') ===
                'Home, Furniture & Appliances' &&
              'Home, Furniture & Appliances | Shop Home Appliance online in Ghana - Caspian Outlets Limited'}
        </title>
        <meta
          name='title'
          content={
            params.catename === 'Construction'
              ? 'Building Materials | Shop Building Materials in Ghana - Caspian Outlets Limited'
              : params.catename === 'Electronics'
              ? 'Electronics | Buy Video and Audio Appliances Online in Ghana - Caspian Outlets Limited'
              : params.catename === 'Books'
              ? 'Shop books online in Ghana - Caspian Outlets Limited'
              : params.catename.replace(/-/g, ' ') ===
                  'Home, Furniture & Appliances' &&
                'Home, Furniture & Appliances | Shop Home Appliance online in Ghana - Caspian Outlets Limited'
          }
        />
        <meta
          name='description'
          content={
            params.catename === 'Construction'
              ? 'Ultimate class aluminium windows and doors products. Buy building materials online from Caspian Outlets Limited. Choose from our collection of building materials and get them at low prices.'
              : params.catename === 'Electronics'
              ? 'Discover a diverse range of genuine gadgets online in Ghana. Purchase televisions, accessories, audio/video devices, and much more.'
              : params.catename === 'Books'
              ? 'Explore a Wide Range of Books in Ghana. Caspian Outlets Limited, the top online bookstore for book enthusiasts, offers a diverse range of books from all categories and authors.'
              : params.catename.replace(/-/g, ' ') ===
                  'Home, Furniture & Appliances' &&
                'Enjoy Amazing Offers on Home Appliances Online @ Caspian Outlets Limited - Shop Home Appliances at Caspian Outlet Limited Ghana @ Affordable Prices - Fast Delivery.'
          }
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div className='cat_con'>
        <div className='row m-0'>
          <div className='col-sm-12 mx-0 px-0 mb-2 mb-md-3 w-100 cas_banner'>
            {products.catitem.length > 0 ? (
              <>
                {products.catitem[0].category.includes('Books') ? (
                  <video width='100%' height='100%' autoPlay={true} loop>
                    <source
                      src='/uploads/ELECTRONICS BANNER.mp4'
                      type='video/mp4'
                    />
                  </video>
                ) : products.catitem[0].category.includes('Electronics') ? (
                  <img
                    src='/uploads/electronics.png'
                    alt='electronics banner'
                  />
                ) : products.catitem[0].category.includes('Construction') ? (
                  <img src='/uploads/building.png' alt='construction banner' />
                ) : (
                  products.catitem[0].category.includes('Home') && (
                    <img src='/uploads/home.png' alt='home appliances banner' />
                  )
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className='row m-0'>
          <div className=' col-md-2 pe-0 pe-sm-1 ps-0'>
            <div className='category_sidebar'>
              <div className='d-flex justify-content-between align-items-center bg-white w-100 mb-2 py-md-2 '>
                <p className='m-0 ps-2 fw-bold'>Refine your search</p>
                <button className='border-0 d-md-none' onClick={toggleFiltter}>
                  <FilterAltOutlinedIcon
                    style={{ height: '30px', width: '30px' }}
                  />
                </button>
              </div>
              <CustomBottomSheet
                show={filter}
                handleClose={handleClose}
                data={subCategory}
              >
                <div className='sub_category_heading_con ps-md-2'>
                  <h5 className='fw-bold'>{'Sub Category'}</h5>
                </div>
                <form className='sub_cat_form'>
                  {subCategory.map((cate, idx) => (
                    <div key={idx} className='content_ d-flex'>
                      <input
                        type={'checkbox'}
                        id={idx}
                        onChange={(e) => handleCheckBox(e, idx)}
                        checked={subCategoryCheckBox[idx]}
                        value={cate}
                      />
                      <label htmlFor={idx}>{cate}</label>
                    </div>
                  ))}
                </form>
              </CustomBottomSheet>
              <div className='d-none d-md-block'>
                <div className=''>
                  <div className='sub_category_heading_con ps-md-2'>
                    <h5 className='fw-bold'>{'Sub Category'}</h5>
                  </div>
                  <form className='sub_cat_form'>
                    {subCategory.map((cate, idx) => (
                      <div key={idx} className='content_ d-flex'>
                        <input
                          type={'checkbox'}
                          id={idx}
                          onChange={(e) => handleCheckBox(e, idx)}
                          checked={subCategoryCheckBox[idx]}
                          value={cate}
                        />
                        <label htmlFor={idx}>{cate}</label>
                      </div>
                    ))}
                  </form>
                </div>
                {/* // <SubCategoriesComponents key={products['filter']} heading={products['filter']}>
                //   {products['data'].map((item, index)=><Content id={index} name={item} check={products['filter'].toLowerCase()==='brands'?brandsCheckbox[index]:subCategoryCheckBox[index]}  handleCheckBox={e=>handleCheckBox(item, products['filter'], index)}/>)}
                // </SubCategoriesComponents>   */}

                {/* <SubCategoriesComponents heading={'Sub Categories'}> */}
                {/* {filterProducts['subCategory'].map((category, index)=><Content id={index} name={category} check={subCategoryCheckBox[index]}  handleCheckBox={e=>handleCheckBox(category, 'Sub Categories', index)}/>)} */}
                {/* </SubCategoriesComponents> */}
                {/* <SubCategoriesComponents heading={'Brands'}> */}
                {/* {filterProducts['brands'].map((brand, idx)=><Content id={idx} name={brand} check={brandsCheckbox}  />)} */}
                {/* <Content id={'sk'} name={'Lenovo'}/>
                <Content id={'kjk'} name={'Adidas'}/>
                <Content id={'sskjk'} name={'Nike'}/>
                <Content id={'s7kjk'} name={'Nasco'}/> */}
                {/* </SubCategoriesComponents> */}
              </div>
            </div>
          </div>
          <div className='col-sm col-md-10 px-0 ps-md-3 m-0'>
            <div className='category_products_container'>
              <div className='d-flex justify-content-between align-items-center bg-white product_infos'>
                <h3 className='m-0'>{params.catename.replace(/-/g, ' ')}</h3>
                {/* <p className='m-0'>{products.total} products found</p> */}
              </div>
              {subCategoryProducts.length > 0 ? (
                <div className='row m-0 category_products'>
                  {subCategoryProducts.map((product) =>
                    product.map((item) => {
                      return (
                        <CategoryProductCard
                          key={item._id}
                          product={item}
                          dispatch={dispatch}
                          basket={basket}
                        />
                      )
                    })
                  )}
                  <div>
                    <Stack spacing={2}>
                      <Pagination count={10} shape='rounded' />
                    </Stack>
                  </div>
                </div>
              ) : (
                <div className='row m-0 category_products'>
                  {products.catitem.map((product) => (
                    <CategoryProductCard
                      key={product._id}
                      product={product}
                      dispatch={dispatch}
                      basket={basket}
                    />
                  ))}
                  <div className='d-flex justify-content-center my-3'>
                    <Stack spacing={2}>
                      <Pagination
                        count={products.totalPages}
                        page={products.currentPage}
                        onChange={handleChange}
                        shape='rounded'
                      />
                    </Stack>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoryProductsComponent

export const CategoryProductCard = ({ product, basket, dispatch }) => {
  const [productAdded, setProductAdded] = useState(false);
  const [color, setColor] = useState('');
  const [colorModal, setColorModal] = useState(false);
  const navigate = useNavigate();
  let { _id, productName, imageUrl, regularPrice, available, itemsize, discount, colors, subCategory, deliveryCharges } = product;
  const discountPrice = (regularPrice.$numberDecimal - ((discount*1/100)*regularPrice.$numberDecimal)).toFixed(2)*1;
  // console.log(colors)
  const addBasket = (e) => {
    e.preventDefault()
    if (colors.length > 0) {
      if (color !== '') {
        dispatch({
          type: 'ADD_TO_BASKET',
          item: {
            id: _id,
            title: productName,
            imageUrl: imageUrl,
            discount: discount * 1,
            quantity: 1,
            weight: itemsize,
            basePrice: regularPrice.$numberDecimal * 1,
            price: regularPrice.$numberDecimal * 1,
            color: color,
            subCategory: subCategory,
            deliveryCharges
          }
        })
      } else {
        setColorModal(true)
      }
    } else {
      dispatch({
        type: 'ADD_TO_BASKET',
        item: {
          id: _id,
          title: productName,
          imageUrl: imageUrl,
          discount: discount * 1,
          quantity: 1,
          weight: itemsize,
          basePrice: regularPrice.$numberDecimal * 1,
          price: regularPrice.$numberDecimal * 1,
          subCategory: subCategory,
          deliveryCharges
        }
      })
    }
  }

  let to = {
    pathname: `/product/${productName.replace(/ /g, '-')}`
  }

  useEffect(() => {
    let foundItem = basket.findIndex((item) => item.id === _id)
    // console.log(foundItem, basket )
    if (foundItem !== -1) {
      setProductAdded(true)
    } else {
      setProductAdded(false)
    }
  }, [basket, product_id])

  const viewCart = (e) => {
    e.preventDefault()
    navigate('/cart', { state: { currenPath: '/categories' } })
  }

  const handleColorChange = (e) => setColor(e.target.value)
  const onHide = (e) => setColorModal(false)
  const handleOk = () => {
    if (color !== '') {
      dispatch({
        type: 'ADD_TO_BASKET',
        item: {
          id: _id,
          title: productName,
          imageUrl: imageUrl,
          discount: discount * 1,
          quantity: 1,
          weight: itemsize,
          basePrice: regularPrice.$numberDecimal * 1,
          price: regularPrice.$numberDecimal * 1,
          color: color
        }
      })
    }
    setColorModal(false)
  }

  return (
    <div className='col-sm-6 col-md-4 col-lg-3 p-1 px-0 px-md-1 category_card_container'>
      <Link
        to={to}
        className='cat_card d-sm-flex d-md-block h-100 bg-white my-1 p-2'
      >
        <div className='cat_card_img_con position-relative'>
          <img
            src={imageUrl}
            alt={productName}
            className='text-lowercase'
            style={{ fontSize: '14px' }}
          />
          {/* {!available && <Availability />} */}
        </div>
        <div className='cat_card_body w-100 '>
          <div className='d-sm-flex d-md-block justify-content-start'>
            <h6 className='fw-bold m-0 text-md-center mt-md-2'>
              {productName.charAt(0) + productName.substring(1).toLowerCase()}
            </h6>
          </div>
          <div className='w-100 d-flex flex-md-column align-items-md-center align-items-sm-start justify-content-md-end'>
            <div className='cat_price_con'>
              {discount > 0 && (
                <strong className='d-block danger'>
                  <span>
                    <span>GH</span>&#8373;
                  </span>
                  {discountPrice}
                </strong>
              )}

              {/* <strong
                  className='d-block text-secondary text-decoration-line-through'
                  style={{ fontSize: '13px', fontStyle: 'italic' }}
                >
                  <span>
                    <span>GH</span>&#8373; {regularPrice.$numberDecimal}
                  </span>
                </strong> */}
              <strong
                className={`d-block text-secondary ${
                  discount > 0 && 'text-decoration-line-through'
                }`}
                style={{
                  fontSize: '13px',
                  fontStyle: discount > 0 ? 'italic' : 'normal'
                }}
              >
                <span
                  className={discount > 0 ? 'text-danger' : 'text-success fs-6'}
                >
                  <span>GH</span>&#8373; {regularPrice.$numberDecimal}{' '}
                </span>
              </strong>
            </div>
            <div className='d-flex justify-content-center add__cat_con w-md-100 w-sm-50'>
              {available ? (
                <>
                  {productAdded !== true ? (
                    <button
                      className='mt-sm-2 mt-md-0 cat_add__cat'
                      onClick={addBasket}
                    >
                      <ShoppingBasketIcon
                        className='d-none d-md-block'
                        style={{
                          marginBottom: '5px',
                          height: '20px',
                          width: '20px',
                          marginRight: '5px'
                        }}
                      />
                      Add to cart
                    </button>
                  ) : (
                    <button
                      className='mt-sm-2 mt-md-0 cat_view__cat'
                      onClick={viewCart}
                    >
                      View Cart
                    </button>
                  )}
                </>
              ) : (
                <p className='text-danger m-0 text-center fs-5 fw-bold'>
                  Out of stock
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
      <Modal show={colorModal} centered onHide={onHide}>
        <Modal.Body>
          <h3 className='fw-bold'>Choose Color size</h3>
          <FormControl
            fullWidth
            size='small'
            sx={{ minWidth: 120, marginTop: 2 }}
          >
            <InputLabel id='demo-select-small-label'>Colors</InputLabel>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              label='Colors'
              value={color}
              onChange={handleColorChange}
            >
              {colors.map((color, idx) => (
                <MenuItem key={idx} value={color}>
                  {color}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Modal.Body>
        <Button size='sm' variant='info' onClick={handleOk}>
          Ok
        </Button>
      </Modal>
    </div>
  )
}
