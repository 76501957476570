import React from 'react'
import '../css/TopBar.css'
import { GiTakeMyMoney } from 'react-icons/gi'
import { FaFacebookF, FaYoutube, FaTiktok, FaPhoneAlt } from 'react-icons/fa'
import { BsTwitterX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import useWindowDimensions from '../utils/useWindowDimension'

function TopBar() {
  const { width } = useWindowDimensions();
  return (
    <div className='top-bar'>
      <div className='top-bar-inner'>
        <Link to={'/ven/seller_center'}>
          <p className='sell-inner'>
            <GiTakeMyMoney size={25} />
            <span>Sell on Caspian</span>
          </p>
        </Link>
        <div className={'d-flex justify-content-between'}>
          <div className='top-bar-left me-2 me-sm-4'>
            <div className=''>
              <FaPhoneAlt size={20} />
              <Link className='m-0' to={'tel:0593855420'}>0593855420</Link>
              <span className='px-1'>|</span>
              <Link className='m-0' to={'tel:0593855318'}>0593855318</Link>
            </div>
            
          </div>
          {width > 486 &&<div>
              <Link
                to={'https://web.facebook.com/CaspianOutletsLtd/?_rdc=1&_rdr'}
                target='_blank'
              >
                <FaFacebookF size={25} className='top-left-icons' />
              </Link>
              <Link to={'https://www.x.com/CaspianLimited?t=Z6KraTpsQDpdUAetAHVAsQ&s=01'}>
                <BsTwitterX size={25} className='top-left-icons' />
              </Link>
              <Link
                to={'https://www.youtube.com/@caspianoutlets4881'}
                target='_blank'
              >
                <FaYoutube size={30} className='top-left-icons' />
              </Link>
              <Link to={'https://www.tiktok.com/@caspian.outlets'} target='_blank'>
                <FaTiktok size={25} className='top-left-icons' />
              </Link>
            </div>}
        </div>
      </div>
      
    </div>
  )
}

export default TopBar
