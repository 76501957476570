import React, { useState, useEffect } from 'react';
import { useLocation, Link, useLoaderData, useParams } from "react-router-dom";
import axios from '../utils/Axios';
import "../css/CategoryTypeProducts.css"
import MobileCategories from './MobileCategories';
import GroupComponent from './GroupComponent';
import { CategoryProductCard } from '../pages/CategoryProductsComponent';
import { useStateValue } from '../utils/StateProvider';
import Loader from './Loader';
import CategoriesGroup from './CategoriesGroup';
import CategoriesGroupComponent from './CategoriesGroupComponent';
import { Helmet } from 'react-helmet';


function CategoryTypeProducts() {
  // const {dataObjs} = location.state;
  const [{ basket }, dispatch] = useStateValue();
  const params = useParams();
  const cateTypeProducts = useLoaderData();
  // useEffect(()=>{
  //   let isMounted = true;
  //   axios.get(`api/categories/${searchParam}`)
  //   .then(res=>{
  //     if (isMounted)setCategoryTypeProducts(res.data);
  //   })
  //   return()=>{ isMounted = false}
  // },[])

  
  return (
    <>
      <Helmet>
        <title>{`Buy ${params.type} Here - Shop ${params.catetype}`}</title>
        <meta name='title' content={`Buy ${params.type} Here - Shop ${params.catetype}`} />
        <meta
          name='description'
          content = {cateTypeProducts[0]['discription'] !=='' ? cateTypeProducts[0]['discription'] : 
          `We offer different types of ${params.type} available for your needs. Shop now at affordable price.` }
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div className='container-xxl cattypeorod_con'>
        <div className=" row cattypeorod">
          <div className="col-sm-12 px-0 mx-0 cas_banner">
            {cateTypeProducts.length > 0 ?
              <>
                {cateTypeProducts[0].category.includes('Books') ?
                  <video width="100%" height="100%" autoPlay={true} loop>
                  <source src="/uploads/ELECTRONICS BANNER.mp4" type='video/mp4'/>
                </video>:
                cateTypeProducts[0].category.includes('Electronics') ?
                  <img src='/uploads/electronics.png' alt="electronics banner" /> :
                  cateTypeProducts[0].category.includes('Construction') ?
                  <img src='/uploads/building.png' alt="construction banner"/> :
                  cateTypeProducts[0].category.includes('Home') &&
                  <img src='/uploads/home.png' alt="home appliances banner"/> 
                }
              </>:
              <Loader />
            }
          </div>
          <div className='col-sm-12'>
            <div className="row">
              {cateTypeProducts.length>0?
                <>
                  {cateTypeProducts.map(product=>
                    <CategoryProductCard
                      key={product._id}
                      product={product}
                      dispatch={dispatch}
                      basket={basket}
                    />
                  )}
                </>:
                <Loader />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoryTypeProducts;