import React from "react";
import Spinner from 'react-bootstrap/Spinner';



const Loader=()=>{
    return (
        <div className='w-100 my-3 d-flex justify-content-center align-items-center' style={{height:'250px'}}>
            <Spinner animation="grow" variant="info" />
        </div>
    )
};


export default Loader;