import React from 'react'
import "../css/AidsComponent.css"

 function AidsComponent() {
  return (
    <div className="aids_componenent">
        <img className="aids_img" src="https://th.bing.com/th/id/OIP.d4qrclCjoqAgZcXGawEMhgHaHa?w=166&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"/>
    </div>
  )
}
export default AidsComponent