import React from 'react'
import '../css/Product.css'
import { Link } from 'react-router-dom'
import useWindowDimensions from '../utils/useWindowDimension'
import Availability from './Availability'

function Product({ title, image, price, discount, available }) {
  const { width } = useWindowDimensions()

  const discountPrice = (price.$numberDecimal - ((discount*1/100)*price.$numberDecimal)).toFixed(2)*1;

  return (
    <div className='product h-100 '>
      <div className='product__item'>
        <div>
          <Link
            to={`/product/${title.replace(/ /g, '-')}`}
            className='text-center position-relative'
          >
            <img
              src={image}
              alt={title}
              className='produc_img text-lowercase'
              style={{ fontSize: '14px' }}
            />
            {!available && <Availability />}
          </Link>
        </div>
        <div className='product__price__items'>
          <div className='product__price'>
            <p className='new_price' style={{ fontWeight: 'bold' }}>
              {discount*1 > 0 && (
                <div>
                  <span>&#8373;</span>
                  <span>{discountPrice}</span>
                </div>
              )}
            </p>
            <strong className={discount*1 > 0 ? 'old_price' : 'new_price'}>&#8373;{typeof(price)==='string'?price : price.$numberDecimal}</strong>
            {/* {discountPrice > 0 ?
              <strong className='old_price'>&#8373;{typeof(price)==='string'?price : price.$numberDecimal}</strong>:
              <strong className='new_price'>&#8373;{typeof(price)==='string'?price : price.$numberDecimal}</strong>
            } */}
          </div>
        </div>
        <div className='product__info'>
          <p>{width <= 600 ? title.slice(0, 20) + '...' : title}</p>
        </div>
      </div>
    </div>
  )
}

export default Product
