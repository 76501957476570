import React, { useState, useEffect } from "react";
import axios from "../../utils/Axios";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../utils/theme";
import './productlist.css';
import Loader from "../../components/Loader";
import { deleteAlert } from "../alerts";




function Productlist() {
    const [allprod, setAllprod] = useState([]);
    const [products, setProducts] = useState({list:[], total:0});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page:0});
    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleView = (id) => {
      console.log(id)
    };

    const handleEdit = (id) => navigate('/form', {state: {id:id}});

    function deleteItem(itemId, swalWithBootstrapButtons) {
      axios.delete(`/items/${itemId}`)
     .then(response => {
      swalWithBootstrapButtons.fire('Deleted!', response.data.message, 'success');
     })
     .catch(error => {
        console.error('Error:', error);
     });
    }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actionCell = (row) => {
    return (
      <div className="w-100 d-flex justify-content-around">
        <ListItemIcon onClick={()=>handleView(row['_id'])}><VisibilityIcon sx={{marginRight: '5px'}}/>View</ListItemIcon>
        <ListItemIcon onClick={()=>handleEdit(row['_id'])}><EditIcon sx={{marginRight: '5px'}}/>Edit</ListItemIcon>
        <ListItemIcon onClick={()=>deleteAlert(deleteItem, row['_id'])}><DeleteIcon sx={{marginRight: '5px'}}/>Delete</ListItemIcon>
      </div>
    )
  }

  const onPaginationModelChange = (model, details) => {
    if (model.page === 0){
      // setPaginationModel({...paginationModel, page:1, pageSize:model.pageSize});
      setPage(1);
      console.log('page is zero: ', model.page, details)
      setPageSize(model.pageSize);
    }else {
      // setPaginationModel({...paginationModel, page:model.page, pageSize:model.pageSize});
      setPage(model.page + 1);
      console.log('page is not zero: ', model, details)
      setPageSize(model.pageSize);
    }
  }

  async function getChainProducts(){
    await axios.get(`/productslist?page=${page}&&limit=${pageSize}`)
    .then((response) =>{
      const chainData = response.data;
        // setAllprod(chainData);
        setProducts({...products, list:chainData.products, total:chainData.total});
        // setTotalPages(chainData.totalPages);
      // console.log(chainData)
    });
  }


  useEffect(() => {
    getChainProducts();
  }, [page, pageSize]);


      const columns = [
        { field: '_id',
          headerName: 'ID',
          disableColumnMenu: true,
          sortable: false,
        },
        { field: "imageUrl", 
          headerName: "Image",
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => <img {...params} src={params.value} height={70} width={70}/>,
        },
        {
          field: "productName",
          headerName: "Name",
          flex: 1,
          cellClassName: "name-column--cell",
        },
        {
          field: "category",
          headerName: "Category",
          flex: 1,
        },
        {
          field: "regularPrice",
          headerName: "Regular Price (GHc)",
          flex: 0.7,
          renderCell: (params) => <p className="m-0">{params.value.$numberDecimal}</p>,
        },
        {
          field: "salePrice",
          headerName: "Sale Price (GHc)",
          flex: 0.7,
          renderCell: (params) => <p className="m-0">{params.value.$numberDecimal}</p>,
        },
        {
          field: "brandName",
          headerName: "Brand Name",
        },
        {
          field: "available",
          headerName: "Availability",
          renderCell: (params) => <>{params.value===true ?<FaCheck color="green" size='1.5rem'/> : <IoClose color="red" size={'1.5rem'} /> }</>,
        },
        {
          field: "actions",
          headerName: 'Actions',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          // valueGetter: params => ,
          renderCell: (params) => actionCell(params.row)
        }
      ];

    // if (products.length === 0) return <Loader />
  return (
    <Box m="20px" height="82vh">
      <Box
        m="40px 0 0 0"
        height="85vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid         
          rows={products.list} 
          columns={columns}
          rowCount={products.total}
          pagination
          initialState={{pagination:{paginationModel:{page:0}}}}
          paginationModel={{page:page-1, pageSize:pageSize}} 
          getRowId={obj=>obj._id} 
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
        />
      </Box>
    </Box>
    // <div>
    //     <Table striped bordered hover>
    //   <thead>
    //     <tr>
    //       <th>#</th>
    //       <th>Product Image</th>
    //       <th>Product Name</th>
    //       <th>Category</th>
    //       <th>Regular Price</th>
    //       <th>Sale Price</th>
    //       <th>Brand Name</th>
    //       <th>Actions</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {products.map((prod, i) =>(
    //         <tr>
    //         <td>{i}</td>
    //         <td><img src={prod.imageUrl} height={70} /></td>
    //         <td>{prod.productName}</td>
    //         <td>{prod.category}</td>
    //         <td>{prod.regularPrice}</td>
    //         <td>{prod.salePrice}</td>
    //         <td>{prod.brandName}</td>
    //         <td>
    //         <DropdownButton id="dropdown-basic-button" title="Actions">
    //         <Dropdown.Item href="#/action-1">View</Dropdown.Item>
    //         <Dropdown.Item href="/form" as={Link} to={{
    //         pathname: "/form",
    //         state: {
    //             id: prod._id,
    //         }
    //     }}>
    //         Update
    //         </Dropdown.Item>
    //         <Dropdown.Item onClick={() => deleteItem(prod._id)}>Delete</Dropdown.Item>
    //         </DropdownButton>
    //         </td>
    //       </tr>
    //     ))}
        
    //     <tr></tr>
    //   </tbody>
    //   </Table>
    //   <div>
    //             {Array.from({ length: totalPages }, (_, index) => (
    //                 <button
    //                     key={index}
    //                     onClick={() => setCurrentPage(index + 1)}
    //                     disabled={currentPage === index + 1}
    //                 >
    //                     {index + 1}
    //                 </button>
    //             ))}
    //         </div>
    // </div>
  )
}

export default Productlist