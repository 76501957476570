import { useState } from "react";
import { Routes, BrowserRouter as Router, Route, BrowserRouter, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Dashboard from "../scenes/dashboard";
import Team from "../scenes/team";
import Invoices from "../scenes/invoices";
import Form from "../scenes/form";
import "../css/AdminRoute.css";
import ProductList from "../scenes/products/productlist";
import Orders from "../scenes/Orders";
import AddImage from "../scenes/form/addproduct";
import AdminLayout from "../layouts/AdminLayout";
import AddSeller from '../scenes/AddSeller';
import Contacts from "../scenes/contacts";
import Sellers from "../scenes/Sellers";
import SellerDetails from "../scenes/SellerDetails";
import AddContent from "../scenes/contentManagement/AddContent";
import ContentManagementList from "../scenes/contentManagement/ContentManagementList";




const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <>      
      <Route path="/" element={<AdminLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="orders" element={<Orders />} />
        <Route path="team" element={<Team />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="productlist" element={<ProductList />} /> 
        <Route path="invoices" element={<Invoices />} />
        <Route path="form" element={<Form />} /> 
        <Route path="addimage" element={<AddImage />} /> 
        <Route path="addseller" element={<AddSeller />} />
        <Route path="sellers" element={<Sellers />} />
        <Route path="seller/:id" element={<SellerDetails />} />
        <Route path="add_content" element={<AddContent />} />
        <Route path="content-management-list" element={<ContentManagementList />} />
      </Route>
    </>
  )
);


export default adminRouter;