import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";


const AlertMessage = ({ message, showSnackbar, setShowSnackbar, severity }) => {
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSnackbar(false)
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={showSnackbar}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
};


export default AlertMessage;