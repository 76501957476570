import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from 'react-bootstrap/Button';

const SellerDetails = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <div className="container border p-3" style={{maxWidth: '900px'}}>
      <Box display={'grid'} gap={'20px'} gridTemplateColumns="repeat(2, minmax(0, 1fr))" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" }}}>
        <Box>
          <h3 >Seller Information</h3>
          <div className="d-flex " >
            <span className="w-25">Name: </span>
            <p className="w-50">John Doe</p>
          </div>
          <div className="d-flex">
            <span className='w-25'>Location: </span>
            <p className="w-50">Akapta</p>
          </div>
        </Box>
        <Box>
          <h3>Customer Care</h3>
          <div className="d-flex">
            <span className='w-25'>Name: </span>
            <p className="w-50">John Doe</p>
          </div>
          <div className="d-flex">
            <span className='w-25'>Phone no: </span>
            <p className="w-50">+233 242092387</p>
          </div>
          <div className="d-flex">
            <span className='w-25'>Email: </span>
            <p className="w-50">+233 242092387</p>
          </div>
          <div className="d-flex">
            <span className='w-25'>Address: </span>
            <p className="w-50">line1 city region country</p>
          </div>
        </Box>
        <Box>
          <h3>Business Information</h3>
          <div className="d-flex">
            <span className='w-25'>Name: </span>
            <p className="w-50">Lectro</p>
          </div>
          <div className="d-flex">
            <span className='w-25'>ID Type: </span>
            <p className="w-50">Voter</p>
          </div>
          <div className="d-flex">
            <span className='w-25'>ID Number: </span>
            <p className="w-50">329890</p>
          </div>
        </Box>
        <Box>
          <h3>ID Images</h3>
          <div className="d-flex">
            <div className="me-3 border"><img src="" alt="id frontside" height={100}/></div>
            <div className="border"><img src="" alt="id backside" height={100}/></div>
          </div>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} sx={{marginRight: '12px'}}>
        <Button className="me-3" variant="danger">Deny</Button>
        <Button variant="success">Authorize</Button>
      </Box>
      {/* <Box>
        <Typography>Seller Products</Typography>
      </Box> */}
    </div>
  )
}


export default SellerDetails;