import React, { useContext, useEffect, useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
// import { FeedbackUpdated } from '../components/Feedback'
import TestHeader from '../components/TestHeader'
// import { GiTakeMyMoney } from 'react-icons/gi'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import AuthContext, { useAuthContext } from '../utils/AuthLoggedIn'
import useAPIs from '../utils/useAPIs'
import EventEmitter from '../utils/eventEmitter'
import { useStateValue } from '../utils/StateProvider'
// import { FaFacebookF, FaYoutube, FaTiktok, FaPhoneAlt } from 'react-icons/fa'
// import { BsTwitterX } from 'react-icons/bs'

const UserLayout = () => {
  const [virtual, setVirtual] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [{ basket }, dispatch] = useStateValue();
  const { loginDetails } = useAuthContext();
  const [details, setDetails] = useState({});
  const onClick = () => {
    setVirtual(false)
    setSuggestions([])
  }

  useEffect(() => {
    loginDetails();
    localStorage.removeItem('basket');
    // const value = localStorage.getItem('basket')
    // const basketItems = JSON.parse(value)
    // if (value) {
    //   dispatch({ type: 'LOAD_BASKET', items: basketItems })
    // }
    EventEmitter.subscribe('details', data => setDetails(data));
  }, [])
  // console.log(details)
  return (
    <div className='container-xxl p-0 '>
      {/* {!(window.location.pathname.includes('orders') || window.location.pathname.includes('customer')) && <TopBar /> }  */}
      <TestHeader
        details={details}
        setVirtual={setVirtual}
        suggestions={suggestions}
        setSuggestions={setSuggestions}
        basket = {basket}
      />
      {/* {virtual&&<VirtualListFocus onClick={onClick} />} */}
      {virtual && <div className='v-focus' onClick={onClick}></div>}
      <Outlet context={{basket:basket, dispatch:dispatch, details: details}} />
      <Footer />
    </div>
  )
}

export default UserLayout
