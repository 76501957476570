import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import PersonIcon from '@mui/icons-material/Person'
import { FaBox, FaUser } from 'react-icons/fa';
import { MdOutlineHistory } from 'react-icons/md';
import Divider from '@mui/material/Divider';
import { useOutletContext } from 'react-router-dom';


const AccountMenu = ({ show, handleClose, details, logout }) => {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{width: '230px'}}>
        <Offcanvas.Header closeButton className='border-bottom'>
          <Offcanvas.Title>Hello, {details.role}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="bg-white">
            <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Item href="/home" className="p-2 my-1">
                <FaBox size={'1.3rem'}className="me-2"/>
                <Nav.Link className="d-inline-block p-0 text-black" href={'/customer/orders/'+details.user} eventKey={'link-1'}>Orders</Nav.Link>
              </Nav.Item>
              <Nav.Item href="/home" className="p-2 my-1">
                <MdOutlineHistory size={'1.3rem'}className="me-2"/>
                <Nav.Link className="d-inline-block p-0 text-black" eventKey={'link-2'}>Recently viewed</Nav.Link>
              </Nav.Item>
              <Nav.Item href="/home" className="p-2 my-1">
                <FaUser size={'1.3rem'} className="me-2"/>
                <Nav.Link className="d-inline-block p-0 text-black" eventKey={'link-3'} href='/customer/account'>Account</Nav.Link>
              </Nav.Item>
              <Divider />
              <Nav.Item href="/home" className="text-center py-2" onClick={logout}>Logout</Nav.Item>
            </Nav>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AccountMenu;