import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://casspian.up.railway.app/'
  // baseURL: 'https://caspianserver-8c35585730be.herokuapp.com/'
  // baseURL: 'https://us-central1-caspian-server.cloudfunctions.net/server/'
  baseURL: 'https://caspianserver1-8f44b202bc09.herokuapp.com/'
  // baseURL: 'http://localhost:9001/'
  // baseURL: 'http://192.168.0.179:9001'
})

export default instance
