import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import '../css/TestSearchBar.css'
// import '../css/Header.css';
import SearchIcon from '@mui/icons-material/Search'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import PersonIcon from '@mui/icons-material/Person'
import { Link, useNavigate } from 'react-router-dom'
// import { googleLogout } from '@react-oauth/google'
// import AuthContext, { useAuthContext } from '../utils/AuthLoggedIn'
// import { GoogleAuthContext } from '../utils/GoogleLoggedIn'
import useWindowDimensions from '../utils/useWindowDimension'
import axios from '../utils/Axios'
// import { VirtualListFocus } from './VirtualList';
// import UserLayout from '../layouts/UserLayout'
import { useStateValue } from '../utils/StateProvider'
import CartPopUp from './CartPopUp'
import TestHeaderNavLinks from './TestHeaderNavLinks'
import AccountMenu from './AccountMenu'
import AccountPopUp from './AccountPopUp'
// import AuthContext from '../utils/AuthLoggedIn'
export const SearchBar = ({ setVirtual, setSuggestions, suggestions }) => {
  const [query, setQuery] = useState('')
  const navigate = useNavigate()

  async function getSearchProducts() {
    const res = await axios.get(`search-suggestions?term=${query}`)
    if (query != '') {
      setVirtual(false)
      setSuggestions([])
      navigate(
        {
          pathname: '/searchedproduct',
          search: `searchvalue=${query}` // query string
        },
        { state: { dataObjs: res.data } }
      )
    }
  }

  const handleInputChange = async (e) => {
    const searchValue = e.target.value
    setQuery(searchValue)
    const res = await axios.get(`search-suggestions?term=${searchValue}`)
    let actualres = res.data
    if (searchValue.length > 0) {
      setVirtual(true)
      let filtereddata = []
      for (let i = 0; i < actualres.length; i++) {
        //let filtered = actualres[i]['subCategory'].toLowerCase().includes(searchValue.toLowerCase());

        let catg = [actualres[i]]
        let filteredName = catg.filter((item) =>
          item.productName.toLowerCase().includes(searchValue.toLowerCase())
        )

        let name = filteredName.length > 0 ? filteredName[0].productName : ''

        let filteredca = catg.filter((item) =>
          item.category.toLowerCase().includes(searchValue.toLowerCase())
        )
        let cat = filteredca.length > 0 ? filteredca[0].category : ''

        let filtered = catg.filter((item) =>
          item.subCategory.toLowerCase().includes(searchValue.toLowerCase())
        )
        let subcat = filtered.length > 0 ? filtered[0].subCategory : ''
        if (subcat.length > 2) {
          filtereddata.push(subcat)
        }
        if (name.length > 2) {
          filtereddata.push(name)
        }
        if (cat.length > 2) {
          filtereddata.push(cat)
        }
        //  if (filtered.length > 0) {
        //    filtereddata.push(filtered[0])
        //  }
      }
      const uniqueData = [...new Set(filtereddata)]
      setSuggestions(uniqueData)
    } else {
      setSuggestions([])
      setVirtual(false)
    }
  }

  const virtualListChange = async (e) => {
    const searchValue = e.target.innerText
    setQuery(searchValue)
    setVirtual(false)
    setSuggestions([])
    const res = await axios.get(`search-suggestions?term=${searchValue}`)
    const dataObjs = res.data
    navigate(
      {
        pathname: '/searchedproduct',
        search: `searchvalue=${searchValue}` // query string
      },
      { state: { dataObjs } }
    )
  }

  // input.addEventListener("keypress", function(event) {
  //   // If the user presses the "Enter" key on the keyboard
  //   if (event.key === "Enter") {
  //     // Cancel the default action, if needed
  //     event.preventDefault();
  //     // Trigger the button element with a click
  //     document.getElementById("myBtn").click();
  //   }
  // });

  return (
    <>
      <div className='search-bar'>
        <div className='d-flex'>
          <input
            type='text'
            value={query}
            onChange={handleInputChange}
            placeholder='Search...'
            onKeyDown={(e) => e.key === 'Enter' && getSearchProducts()}
          />
          <span>
            <SearchIcon
              onClick={getSearchProducts}
              className='header__searchIcon h-100 '
            />
          </span>
        </div>

        {suggestions.length > 0 && (
          <ul className='suggestions'>
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={virtualListChange}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

const TestHeader = ({
  children,
  details,
  setVirtual,
  suggestions,
  setSuggestions,
  basket
  
}) => {
  const { width } = useWindowDimensions()
  
  // const authCTX = useContext(AuthContext)
  // const { getLoggedIn } = useContext(AuthContext)
  // const googleCTX = useContext(GoogleAuthContext)
  const [show, setShow] = useState(false)
  const [accountShow, setAccountShow] = useState(false);
  // const [{ basket }] = useStateValue();
  const handleClose = () => setAccountShow(false);
  const handleShow = () => setAccountShow(true);

  // console.log(loggedIn, getLoggedIn())

  async function Logout() {
    await axios.get('/logout')
    window.location.href = '/';
  }

  // useLayoutEffect(() => {
  //   const token = getCookie('token')
  //   if (token) {
  //     const decode = jwt_decode(token)
  //     setDetails(decode);
  //   }
  // }, [])
  const toggleCartPopUp = () => setShow(!show)

  const onMouseOver = () => {
    if (!window.location.pathname.includes('checkout')) {
      const cartPopUp = document.querySelector('.cart__pop')
      cartPopUp.style.visibility = 'visible'
    }
  }

  const onAccountMouseOver = () => {
    const accountPopUp = document.querySelector('.account__pop')
    accountPopUp.style.visibility = 'visible'
  }


  return (
    <>
      <div className='header_container'>
        <nav className=''>
          <div className='headers'>
            {/* <UserLayout /> */}
            <div className='logo'>
              <div>
                <Link to='/' className='w-100'>
                  <img
                    className='logo_img'
                    src={'/uploads/caspian_logo.png'}
                    alt='Caspian logo'
                  />
                </Link>
              </div>
              {width <= 768 && (
                <div className='header__nav'>
                  {!(details.role === 'user') && (
                    <Link
                      to={'/login'}
                      state={{ currenPath: '/', price: 0 }}
                      className='header__link'
                    >
                      <div className='header__option'>
                        <p className='m-0 me-1 mt-2 d-none d-sm-inline'>
                          Account
                        </p>
                        <PersonIcon style={{ height: '33px', width: '33px' }} />
                      </div>
                    </Link>
                  )}
                  {(details.role === 'user') && (
                    <div className='header__option flex-sm-row text-white' onClick={handleShow}>
                      <div className='header__option'>
                        <p className='m-0 me-1 mt-2 d-none d-sm-inline'>Hi, {details.role}</p>
                        <PersonIcon style={{ height: '33px', width: '33px' }} />
                      </div>
                    </div>
                  )}
                  <Link
                    to={'/cart'}
                    state={{ currenPath: '/' }}
                    className='header__link'
                  >
                    <div className='header__optionBasket'>
                      <p className='m-0 me-1 mt-2 d-none d-sm-inline '>Cart</p>
                      <ShoppingBasketIcon
                        style={{ height: '33px', width: '33px' }}
                      />
                      <span className='header__optionLineTwo header__basketCount count_number'>
                        {basket.length}
                      </span>
                    </div>
                  </Link>
                </div>
              )}
            </div>

            <SearchBar
              setVirtual={setVirtual}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
            />

            {width > 768 && (
              <div className='header__nav'>
                {!(details.role === 'user') && (
                  <Link
                    to={'/login'}
                    state={{ urrenPath: '/', price: 0 }}
                    className='header__link cartBtn'
                  >
                    <div className='header__option'>
                      <p className='m-0 me-1 mt-2 d-none d-md-inline'>
                        Account
                      </p>
                      <PersonIcon style={{ height: '33px', width: '33px' }} />
                    </div>
                  </Link>
                )}
                {(details.role === 'user') && (
                  <div className='header__option account-drop' onMouseOver={onAccountMouseOver}>
                    <div className='header__option'>
                      <p className='m-0 me-1 mt-2 d-none d-sm-inline'>Hi, {details.role}</p>
                      <PersonIcon style={{ height: '33px', width: '33px' }} />
                    </div>
                  </div>
                )}
                <Link
                  to={'/cart'}
                  state={{ currenPath: '/' }}
                  onMouseOver={onMouseOver}
                  className='header__link'
                >
                  <div className='header__optionBasket'>
                    <p className='m-0 me-1 mt-2 d-none d-md-inline'>Cart</p>
                    <ShoppingBasketIcon
                      style={{ height: '33px', width: '33px' }}
                    />
                    <span className='header__optionLineTwo header__basketCount'>
                      {basket.length}
                    </span>
                  </div>
                </Link>
              </div>
            )}
            <CartPopUp setCartPopUp={setShow} />
            {width <= 768 ?
              <AccountMenu show={accountShow} handleClose={handleClose} details={details} logout={Logout}/>:
              <AccountPopUp logout={Logout} details={details}/>
            }
          </div>
          {!(window.location.pathname.includes('checkout') || window.location.pathname.includes('customer'))&& (
            <TestHeaderNavLinks />
          )}
        </nav>
      </div>
    </>
  )
}

export default TestHeader
