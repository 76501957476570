import React, { useEffect, useRef, useState } from "react";
import './SubCategoriesComponents.css';


const SubCategoriesComponents=({heading, children })=>{
  return (
    <div className="">
      <div className="sub_category_heading_con ps-md-2">
        <h5 className="fw-bold">{heading}</h5>
      </div>
      <form className="sub_cat_form">
        { children }
      </form>
    </div>
  )
};


export default SubCategoriesComponents;



export const Content=({id, ref, name, isOrderConfirmed, handleCheckBox, disabled, check})=>{

  return(
    <div className="content_ d-flex" style={{color:isOrderConfirmed===true&&'lightgray'}}>
      <input type={'checkbox'} disabled={disabled} ref={ref} id={id} onChange={handleCheckBox} checked={check} value={name}/>
        <label htmlFor={id} style={{color:disabled?'lightgray':'black'}}>{name}</label>
    </div>
  )
}