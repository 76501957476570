import React, { useState, useEffect } from "react";
import axios from "../../utils/Axios";
import { Box, Typography, useTheme } from "@mui/material";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/Loader";
import { tokens } from "../../utils/theme";


const Orders = () => {
    const [allprod, setAllOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    useEffect(() => {
      async function getChainProducts(){
        await axios.get(`/orders?page=${currentPage}`).then((response) =>{
          const chainData = response.data;
            // setAllOrders(chainData);
            setOrders(chainData);
            // setTotalPages(chainData.totalPages);
          // console.log(chainData)
        });
      }
      getChainProducts();
    }, [currentPage]);

    
    const columns = [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "phone",
        headerName: "Phone Number",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "paymentchannel",
        headerName: "Payment Channel",
        flex: 1,
      },
      {
        field: "date",
        headerName: "Date",
        flex: 1,
      },
      {
        field: "Actions",
        renderCell: (params) => 
        <div {...params} className="w-100">
          <span className="me-3">View</span> 
          <span>Edit</span>
        </div>,
        headerName: 'Actions',
        flex: 1,

      }
    ];

    if (orders.length === 0) return <Loader />
    return (
      <Box m="20px" height="82vh">
        <Box
          m="40px 0 0 0"
          height="85vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid rows={orders} columns={columns} rowSelection={false}/>
        </Box>
      </Box>
    );
}

export default Orders;