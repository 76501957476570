import React, { useContext, useEffect, useState } from 'react'
import '../css/CartPopUp.css'
import { useStateValue } from '../utils/StateProvider'
import CloseIcon from '@mui/icons-material/Close'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import GHsymbol from './GHsymbol'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import PopUp from '../unused_components/PopUp'
import { getBasketTotal } from '../utils/Reducer'
import AuthContext from '../utils/AuthLoggedIn'
import EventEmitter from '../utils/eventEmitter'

const CartPopUp = ({ setCartPopUp }) => {
  const [popUp, setPopUp] = useState(false)
  const navigate = useNavigate()
  const [{ basket }, dispatch] = useStateValue()
  // const { details } = useOutletContext();
  const removeFromBasket = (id) => {
    dispatch({
      type: 'REMOVE_FROM_BASKET',
      id: id
    })
  }


  const handleCartPopIsLogin = () => {
    // if (loggedIn){
    shipping()
    // }else {
    //   setPopUp(true);
    //   // setCartPopUp(false)
    // }
  }

  // useEffect(()=>{
  //   if (popUp){
  //     setCartPopUp(false)
  //   }
  // },[PopUp])
  // console.log(popUp)

  const login = () => {
    navigate('/login');
    setCartPopUp()
  }

  const shipping = () => {
    navigate('/checkout')
    setCartPopUp(false)
  }

  const onHide = () => setCartPopUp(false)

  // const viewProductDetails = () => {
  //   navigate.push({ pathname: "/product",
  //   state: {
  //       id: id,
  //       name: title,
  //       pic: image,
  //       weight:itemweight,
  //       price: price,
  //       priceStrike: (price_*1 + (price_*1 * 0.10)).toFixed(2),
  //       discription: discription,
  //       brand:brand
  //   }})
  // }

  const onMouseOut = () => {
    const cartPopUp = document.querySelector('.cart__pop')
    cartPopUp.style.visibility = 'hidden'
  }

  const onMouseOver = () => {
    const cartPopUp = document.querySelector('.cart__pop')
    cartPopUp.style.visibility = 'visible'
  }
  return (
    <>
      <div
        className='cart__pop__container'
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
      >
        <div className='cart__pop'>
          {basket.length > 0 ? (
            <>
              <div className='cart__pop__body'>
                {basket.map((item) => (
                  <div
                    key={item['id']}
                    className='cart__pop__product__container'
                  >
                    <div className='cart__pop_img_container'>
                      <div onClick={() => removeFromBasket(item['id'])}>
                        <CancelPresentationIcon fontSize='small' />
                      </div>
                      <img
                        src={item['imageUrl']}
                        alt='product image'
                        height={50}
                        width={50}
                      />
                    </div>
                    <div className='cart_pop_details'>
                      <div className='popup_product_name'>
                        <h3>{item['title']}</h3>
                      </div>
                      <div className='price_quantity'>
                        <p className='m-0 me-3 text-info'>
                          {item['quantity']}
                        </p>
                        <p className="m-0 text-success fw-bold me-2"><GHsymbol /> {item.discount > 0 ? parseFloat(item.price - ((item.discount/100)*item.price)).toFixed(2) : item.price.toFixed(2)}</p>
                        {item.discount > 0 &&<p className={'fw-bold text-secondary text-decoration-line-through'}><small><GHsymbol /> {(parseFloat(item.price)).toFixed(2)}</small></p>}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className='d-flex justify-content-between border-top px-2 pt-1'>
                <h6 className='m-0' style={{ color: 'gray' }}> Discounts </h6>
                <p className='m-0 fw-bold' style={{color: 'red'}}>
                  <GHsymbol /> {basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0).toFixed(2)}
                </p>
              </div> */}
              <div className='d-flex border-top px-2 pt-1'>
                <h6 className='m-0' style={{ color: 'gray', }}> Subtotal: </h6>
                <p className='m-0 fw-bold ms-2'>
                  <GHsymbol /> {parseFloat(getBasketTotal(basket) - basket.reduce((prev, current) => (((current.discount/100)*current.price) + prev) , 0)).toFixed(2)}
                </p>
              </div>
              <div className='cart__pop__footer'>
                <Link
                  to={'/cart'}
                  state={{ currenPath: '/' }}
                  onClick={() => setCartPopUp(false)}
                  className='cart_pop_view_cart text-white'
                >
                  View Cart
                </Link>
                <button
                  onClick={handleCartPopIsLogin}
                  className='cart_pop_checkout fw-bold'
                >
                  Checkout
                </button>
              </div>
            </>
          ) : (
            <div className='d-flex align-items-center justify-content-center'>
              <p className='m-0'>Basket Empty</p>
            </div>
          )}
        </div>
      </div>
      <PopUp show={popUp} onHide={onHide} login={login} shipping={shipping} />
    </>
  )
}

export default CartPopUp
