import { json } from 'react-router-dom';
import axios from '../utils/Axios';


export const loadContents = async(seo) => {
    const response = await axios.get('/content-management/one/'+seo)
    .catch(err=>{
        throw new Error('Could not fetch data!\nPlease check your network connectivity!')})
    if (response.data === '')throw json({message: 'This page could not be found!', status:'Oops!'})
    return await response.data;
}