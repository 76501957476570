import { React, useState, useEffect, useLayoutEffect, useContext } from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import adminRouter from './routes/adminRouter'
import myRouter from './routes/userRouter'
import vendorsRouter from './routes/vendorsRouter'
import { StateProvider, useStateValue } from './utils/StateProvider'
import jwt_decode from 'jwt-decode'
import AuthContext, { useAuthContext } from './utils/AuthLoggedIn'
import EventEmitter from './utils/eventEmitter'
import reducer, { initialState } from './utils/Reducer'
// import { getCookie } from './utils/readCookie'

function App() {
  const { loginDetails, loggedIn, details } = useAuthContext();
  // const [details, setDetails] = useState({});

  const router = myRouter(details)
  const venRouter = vendorsRouter(details);
  // const basketState = useStateValue()
  // const dispatch = basketState[1]
  // const authCTX = useContext(AuthContext)
  // useLayoutEffect(() => {
  //   const token = localStorage.getItem('token')
  //   if (token) {
  //     const decode = jwt_decode(token)
  //     setDetails(decode)
  //   }
  // }, [])

  // useEffect(() => {
  //   EventEmitter.subscribe('details', data => setDetails(data))
  // }, [])
  // console.log(details)
  return (
    <>
      {details.role === 'vendor' && <RouterProvider router={venRouter} />}
      {details.role === 'admin' && <RouterProvider router={adminRouter} />}
      {(details.role === 'user' || details.role === undefined) && (
        <div className='app'>
          <StateProvider reducer={reducer} initialState={initialState}>
            <RouterProvider router={router} />
          </StateProvider>
        </div>
      )}
    </>
  )
}

export default App
