import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import { tokens } from "../../../utils/theme";
import { useTheme } from '@mui/material';
import EAdmin from "./nana.jpg";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import Logout from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';



export default function MyDrawer () {
  const [state, setState] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(!state);
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px" >
        <Typography variant="h4" color={colors.grey[100]}> Caspian Vendor</Typography>
        <IconButton onClick={toggleDrawer}> <HighlightOffIcon /></IconButton>
      </Box>
      <Box mb="25px">
        <Box display="flex" justifyContent="center" alignItems="center">
          <img alt="profile-user" width="100px" height="100px" src={EAdmin} style={{ cursor: "pointer", borderRadius: "50%" }} />
        </Box>
        <Box textAlign="center">
          <Typography variant="h3" color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }} > Emmanuel A </Typography>
          <Typography variant="h5" color={colors.greenAccent[500]}> Caspian Web Admin </Typography>
        </Box>
      </Box>
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={'/'}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={'/productlist'}>
            <ListItemIcon><PropaneTankIcon /></ListItemIcon>
            <ListItemText primary={'Products'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={'/orders'}>
            <ListItemIcon><ShoppingBagIcon /></ListItemIcon>
            <ListItemText primary={'Orders'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={'/form'}>
            <ListItemIcon><AddIcon /></ListItemIcon>
            <ListItemText primary={'Add Product'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider /> */}
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon><Logout /></ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer} className='ms-2'><MenuIcon /></IconButton>
      <Fragment>
        <Drawer
          anchor={'left'}
          open={state}
          onClose={toggleDrawer}
        >
          {list('left')}
        </Drawer>
      </Fragment>
    </div>
  );
}