import React, { useEffect, useState } from "react";
import CommentIcon from '@mui/icons-material/Comment';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../css/Feedback.css';
import axios from '../utils/Axios';

const Feedback=()=>{
  const [show, setShow] = useState(false);
  const [feedback, setFeedback] = useState({name:'', comment:''});
  const toggleShow=()=>setShow(!show);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [text, setText] = useState(true);

  const onSubmitFeedback=async(e)=>{
    e.preventDefault();
    
    try {
      await axios.post('/feedbacks', feedback).then(res=>{
        setMessage(res.data.message);
        setShowSnackbar(true);
        setShow(false);
        setText(false);
      })
    }catch(err){
      console.log(err);
    }
  }

  const onChangeName=(e)=>setFeedback({...feedback, name:e.target.value});
  const onChangeComment=(e)=>setFeedback({...feedback, comment:e.target.value});



  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const onClose=()=>setShow(false);

  return (
    <>
      <Snackbar
        anchorOrigin={{vertical:'top', horizontal:'right'}}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
      <div className="feedback">
        <div onClick={toggleShow} className="feedback_icon">
          <CommentIcon />
        </div>
        {text==true&&
          <div className="comment">
            <div onClick={()=>setText(false)}><CloseIcon fontSize="small" className="comment_close_btn"/></div>
            <p>Unsatisfied? send us a comment to improve.</p>
          </div>
        }
        {show&& 
          <div className="feedback_form">
            <DisabledByDefaultIcon className="form_close_btn text-info" onClick={onClose}/>
            <form className="d-flex flex-column h-100" onSubmit={onSubmitFeedback}>
              <div className="form_group feedback_name">
                <label htmlFor="name">Name <span className="text-info">*</span></label>
                <input id="name" type="text" placeholder="Name" value={feedback.name} onChange={onChangeName} />
              </div>
              <div className="form_group">
                <label htmlFor="feedback">Comment <span className="text-info">*</span></label>
                <textarea placeholder="Write your comment here" value={feedback.comment} onChange={onChangeComment} className="d-block w-100"/>
              </div>
              <button type="submit" className="feedback_submit_btn">Submit</button>
            </form>
          </div>
        }
      </div>
    </>
  )
}


export default Feedback;



export const FeedbackUpdated=()=>{
  // const feedbac = localStorage.getItem('feedback');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState(false);


  const onThumbUp=async(e)=>{
    e.preventDefault();
    
    try {
      await axios.post('/feedbacks', {likes:1}).then(res=>{
        setMessage(res.data.message);
        setShowSnackbar(true);
        setFeedback(true);
        localStorage.setItem('feedback', true);
      })
    }catch(err){
      console.log(err);
    }
  }
  // localStorage.removeItem('feedback')
  const onThumbDown=async(e)=>{
    e.preventDefault();
    
    try {
      await axios.post('/feedbacks', {likes:0}).then(res=>{
        console.log(res.data.message)
        setMessage(res.data.message);
        
        setShowSnackbar(true);
        setFeedback(true);
        localStorage.setItem('feedback', true);
        
      })
    }catch(err){
      console.log(err);
    }
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

    useEffect(()=>{
      const feed = localStorage.getItem('feedback');
      setFeedback(feed);
    },[message])

    // console.log(typeof(feedback))
    // localStorage.removeItem('feedback')
  return (
    <>
      <Snackbar
        anchorOrigin={{vertical:'top', horizontal:'right'}}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
      {(feedback === 'false' || feedback === null)&&
      <>
        <div className="feedback_p"><p className="m-0 fw-bold">Like this new design?</p></div>
        <div className="feedback_upd">
          <div className="tup" onClick={onThumbUp}><ThumbUpIcon /></div>
          <div className="tdown" onClick={onThumbDown}><ThumbDownIcon /></div>
        </div>
      </>
      }
    </>
  )}
