import React, { useEffect, useState } from "react";
import GHsymbol from "../components/GHsymbol";
import Button from 'react-bootstrap/Button'
import { FaBox } from "react-icons/fa6";
import { MdOutlineHistory } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import axios from '../utils/Axios';
import ProductSuggestions from "../components/ProductSuggestions";
import EventEmitter from "../utils/eventEmitter";
import useWindowDimensions from "../utils/useWindowDimension";
import { Helmet } from "react-helmet";
import Accordion from 'react-bootstrap/Accordion';
import RelatedProducts from "../components/RelatedProducts";
import '../css/orders.css';
import LemMenu from "../components/LemMenu";

// const orders = [
//   // {id: 0, name: 'Lg Soundbar', quantity: 10, price: 22, discount: 20, order_id: 'CAGE23SPOA'}
// ]


const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [viewProducts, setViewProducts] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    axios.get(`${params.id}/orders`)
    .then(res => {
      restructuredOrders(res.data);
    })
    .catch(err => console.error('something happened')) 
  },[])

  useEffect(() => {
    const data_ = localStorage.getItem('tui');
    const data = JSON.parse(data_);
    if (data !== null){ 
      setViewProducts(data[Math.floor(Math.random() * data.length)])
    }
  },[])

  // console.log(orders)

  useEffect(() => {
    if (viewProducts.subCategory !== undefined) {
      EventEmitter.dispatch('findSimilarProducts', viewProducts.subCategory);
      EventEmitter.dispatch('findRelatedProducts', viewProducts.category);
    }
  },[viewProducts])
  // const orders = useLoaderData();

  const handleClick = (idx) => {
    navigate('/customer/review-product/'+orders[idx].id, 
    {state: {name: orders[idx].name, img: orders[idx].image}}
    )
  }

  const createReturnRequest = (idx) => {
    navigate('/customer/return-request/'+orders[idx].name.replace(/ /g, '-'), 
      {state: {name: orders[idx].name, img: orders[idx].image, price: orders[idx].price, quantity: orders[idx].quantity}}
    )
  }

  const restructuredOrders = (orders) => {
    let orderLists = [];
    orders.forEach(order => {
      let item = [order];
      const found = orderLists.findIndex(list=> list['orders'].find(order_ => order_.id === order.id));
      if (found === -1){
        orderLists.push({orders: item, id: order.id});
      }else {
        orderLists[found]['orders'].push(order);
      }
    })
    setOrders(orderLists);
  }
  
  const buyAgain = (idx) => navigate('/product'+orders[idx]['name'])

  const handleViewDetails = (e, id) =>  {
    e.preventDefault();
    navigate('/customer/orders/details/'+id);
  }

  // console.log(orders)

  return (
    <>
    <Helmet>
      <title>Orders</title>
        <meta name='Orders'/>
        <meta name='robots' content='noindex, nofollow' />
    </Helmet>
      <div className="mb-3 w-100">
        <div className="">
          <h3>Your Orders</h3>
          <p>List of all orders you've made.</p>
        </div>
        <div className="d-flex justify-content-center mb-3">
          {orders.length > 0 ? 
            <div className="m-0 p-0 orders-list w-100">
              <Accordion defaultActiveKey={0} flush className="m-0 border-0">
                {orders.map((list, idx) =>
                  <Accordion.Item key={idx} eventKey={idx} className="mb-3 border">
                    <Accordion.Header className="acc-header">
                      <span>Order No: {list['id']}</span>
                      <Button size="sm" variant={'outline-info'} onClick={e=>handleViewDetails(e, list['id'])}>View Details</Button>
                    </Accordion.Header>
                    {list['orders'].map((order, id) =>
                    <Accordion.Body key={id} className="mt-2">
                      {/* <div className="status-wrapper">
                        {order.status==='cancelled' &&<span className="cancelled">order cancelled</span>}
                        {order.status==='recieved'&&<span className="received">confirmed</span>}
                        {order.status ==='order_placed'&&<span className="delivered">delivered</span>}
                      </div> */}
                      <div key={order.id} className="border-bottom row m-0 p-0 pb-1" >
                        <div className="col-4 col-sm-2 col-md-3 col-lg-2 p-1 order-img"><img src={order.image} className="order-img" alt="product" /></div>
                        <div className="col-7 col-sm-10 col-md-6 col-lg-7 p-1">
                          <h2 className="m-0 order-item-name">{order.name}</h2>
                          <p className="m-0 text-secondary"><small>Order No: {order.id}</small></p>
                          <p className="m-0">Qty: {order.quantity}</p>
                          <span className="me-2"><GHsymbol /> {parseFloat(order.price - (order.price*(order.discount/100))).toFixed(2)}</span>
                          {/* <span className="text-decoration-line-through text-secondary text-danger" style={{fontSize:'12px'}}><GHsymbol /> {order.discount}</span> */}
                        </div>
                        {width >= 768 && 
                          <div className="col-md-3 d-flex flex-column align-items-center">
                            <Button size="sm" variant={'info'} onClick={e => handleViewDetails(e, list['id'])}>View Details</Button>
                          </div>
                        }
                      </div>
                    </Accordion.Body>
                  )}
                  </Accordion.Item>
                )}
              </Accordion>
            </div>:
            <div className="d-flex justify-content-center align-items-center"><p>Looks like you haven't placed an order in the past 3 months.</p></div>
          }
        </div>
        <ProductSuggestions title={'Product recommendation based on your browsing history'}/>
        <RelatedProducts title={'Related Products'} />
      </div>
    </>
  )
};


{/* <MenuItem style={{height: '20px'}}>Buy Again</MenuItem>
                      <MenuItem style={{height: '20px'}}>Request Return</MenuItem>
                      <Divider />
                      <MenuItem style={{height: '20px'}}>Cancel Order</MenuItem> */}
export default Orders;