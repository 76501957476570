import React from "react";





const GHsymbol=()=>{
    return (
        <>
            <span>GH</span>&#8373;
        </>
    )
}



export default GHsymbol;