import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import ProductReviews from "../components/ProductReviews";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';



const VendorProfilePage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <div className="container-xl">
      <div className="row m-0">
        <div className="d-flex"><IconButton onClick={goBack}><ArrowBackIcon /></IconButton></div>
        <div className="col-12 mb-2">
          <div className="border">
            <div className="d-flex justify-content-between bg-white p-2">
              <h4 className="fw-bold m-0" style={{fontSize: '19px'}}>Vendor Name</h4>
              {/* <p className="m-0" style={{width: '100px'}}>See Profile</p> */}
            </div>
            <div className="d-flex justify-content-between bg-white p-2">
              <div style={{width:'300px'}}>
                <p className="m-0 w-100"><span className="fw-bold me-2">90% </span>Vendor Score</p>
                <p className="m-0"><span className="fw-bold me-2">400</span> Followers</p>
              </div>
              <button className="border-0" style={{width:'100px', color:'', backgroundColor:'#05bcc4'}}>Follow</button>
            </div>
          </div>
        </div>
        {/* <div className="col-12 mb-3 p-0 px-sm-3">
          <div className="d-flex justify-content-between bg-white p-2">
            <div style={{width:'300px'}}>
              <p className="m-0 w-100"><span className="fw-bold me-2">90% </span>Vendor Score</p>
              <p className="m-0"><span className="fw-bold me-2">400</span> Followers</p>
            </div>
            <button className="border-0" style={{width:'100px', color:'', backgroundColor:'#05bcc4'}}>Follow</button>
          </div>
        </div> */}
        <div className="col-6">
          <div className="border p-2">
            <div><h3 style={{fontSize:'18px', fontWeight:'bold'}} className='border-bottom py-2 m-0'>Seller's Information</h3></div>
            <div className='py-2'>
              <Link style={{fontSize:'15px'}}>Seller store name (no. products)</Link>
              <span className='d-block' style={{fontSize:'14px'}}>90% positive feedback</span>
            </div>
          </div>
        </div>
        <div className='col-6 pb-2'>
          <div className="border p-2">
            <div><h3 style={{fontSize:'18px', fontWeight:'bold'}} className='border-bottom py-2 m-0'>Seller's Performance</h3></div>
            <div className='d-flex'><p className='m-0 me-2' style={{fontSize:'14px', color:'#000000c3'}}>Order Fullfilment Rate: </p> <span className='fw-bold' style={{fontSize:'13px'}}>Excellent</span></div>
            <div className='d-flex'><p className='m-0 me-2' style={{fontSize:'14px', color:'#000000c3'}}>Quality Score: </p> <span className='fw-bold' style={{fontSize:'13px'}}>Excellent</span></div>
            <div className='d-flex'><p className='m-0 me-2' style={{fontSize:'14px', color:'#000000c3'}}>Customer Rating: </p> <span className='fw-bold' style={{fontSize:'13px'}}>Good</span></div>
          </div>
        </div>
        <div className="col-12">
          <ProductReviews />
        </div>
      </div>
      
    </div>
  )
};


export default VendorProfilePage;