import React, { useState } from "react";
import Rating from '@mui/material/Rating';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from '../utils/Axios';
import { Helmet } from "react-helmet";


const RateProduct = () => {
  const location = useLocation();
  const product = location.state;
  const params = useParams();
  const [rateProducts, setRateProduct] = useState({rate: 0, title: '', details: '', orderId:product.id});
  const navigate = useNavigate();


  const rateProduct = () => {
    axios.post(`/product/${product.name.replace(/-/g, ' ')}/review`, rateProducts)
    .then(res => {
      alert(res.data);
      navigate(-1);
    })
    .catch(err => console.log(err))
  }
  return (
    <>
      <Helmet>
        <title>Rate Product - {product.name}</title>
          <meta name={`Rate Product - ${product.name}`}/>
          <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className="mb-3 my-md-3">
        <div className="bg-white ps-2">
          <h2 className="pt-1">Rate Product</h2> 
          <p className="border-bottom border-top py-3">Select the stars to rate the product</p>
        </div>
        <div className="d-flex align-items-center mb-3 bg-white">
          <img src={product.img} height={100} alt="product"/>
          <div className="ms-4">
            <h6 className="fw-bold">{product.name}</h6>
            <Rating name="size-large" value={rateProducts.rate} onChange={e => setRateProduct({...rateProducts, rate: e.target.value*1})} size="large" />
          </div>
        </div>
        <div className="mt-4"> 
          <h5 className="border-bottom pb-1 fs-6 fw-bold">LEAVE A REVIEW</h5>
          <div>
            <FloatingLabel controlId="floatingInput" label="Review title" className="mb-3" >
              <Form.Control type="text" placeholder="eg. I love it." value={rateProducts.title} onChange={e => setRateProduct({...rateProducts, title: e.target.value})}/>
            </FloatingLabel>
            <FloatingLabel controlId="floatingTextarea" label="Detailed Review" className="mb-3" style={{height: '250px'}}>
              <Form.Control as="textarea" placeholder="Tell us more about your rating!" maxLength={100} value={rateProducts.details} onChange={e => setRateProduct({...rateProducts, details: e.target.value})} style={{height: '250px'}}/>
            </FloatingLabel>
          </div>
        </div>
        <div className="d-flex"><Button className="w-100" variant="info" onClick={rateProduct}>Submit your review</Button></div>
      </div>
    </>
  )
};



export default RateProduct;