import React from "react";







const Availability = () => {
    return (
        <div className='product-avail'><span>Out of stock</span></div>
    )
};


export default Availability;